export const pathConstants = {
  HOME_PAGE: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  EXISTING_ACCOUNT: '/signup/login-existing-account',
  UPDATE_NUMBER: '/account/update-number',
  FIND_ID: '/find-id',
  FIND_ID_PHONE: '/find-id/by-phone',
  FIND_PASSWORD: '/find-password',
  CHANGE_PASSWORD: '/change-password',
  ALARM_LIST: '/alarm-list',
  COURSE: '/courses',
  ABOUT_COURSE: '/courses/about-course',
  FAVORITE_COURSE: '/courses/favorite-course',
  MY_ROUND_COURSE: '/courses/my-round-course',
  COURSE_DETAIL: (id = ':id') => '/courses/' + id,
  STORE_PAGE: '/stores',
  STORES_MAP: '/stores/stores-map',
  STORE_DETAIL: (id = ':id') => '/stores/' + id,
  STORE_FEED: (id = ':id') => '/stores/' + id + '/feed',
  STORE_FEED_DETAIL: (storeId = ':storeId', id = ':id') => '/stores/' + storeId + '/feed/' + id,
  FEED_PAGE: '/feed',
  FEED_FAVORITE: '/feed/favorite-store',
  FEED_SCORECARD: '/feed/score-card',
  FEED_GS_SWING: '/feed/gs/swing',
  FEED_GDR_SWING: '/feed/gdr/swing',
  FEED_TM_SWING: '/feed/my-training/swing',
  FEED_DETAIL: (id = ':id') => '/feed/' + id,
  FEED_DETAIL_EDIT: (id = ':id') => '/feed/' + id + '/edit',
  FEED_CREATE_ITEM: (id = ':id', typeFeed = ':typeFeed') => '/feed/create/' + id + '/' + typeFeed,
  FEED_TRAINING_MODE_CREATE: '/feed/training-mode/create',
  FAVOURITE_STORES: '/stores/favourite-stores',
  RECORDS: '/records',
  RECORDS_GS_ROUND: '/records/gs',
  RECORDS_GS_SWING: '/records/gs/swing',
  RECORDS_GDR: '/records/gdr',
  RECORDS_GDR_PRACTICE: '/records/gdr/practice',
  RECORDS_GDR_SWING: '/records/gdr/swing',
  RECORDS_TRAINING_MODE: '/records/my-training',
  RECORDS_TRAINING_MODE_APPROACH: '/records/my-training/approach',
  RECORDS_TRAINING_MODE_PUTTING: '/records/my-training/putting',
  RECORDS_TRAINING_MODE_SWING: '/records/my-training/swing-video',
  RECORD_DETAIL_GS: (id = ':id') => '/records/gs/' + id,
  RECORD_DETAIL_GDR_DRIVING: (id = ':id') => '/records/gdr/driving/' + id,
  RECORD_DETAIL_GDR_PRACTICE: (id = ':id') => '/records/gdr/practice/' + id,
  RECORD_DETAIL_TM_DRIVING_RANGE: (id = ':id') => '/records/my-training/' + id + '/driving-range',
  RECORD_DETAIL_TM_APPROACH: (id = ':id') => '/records/my-training/' + id + '/approach',
  RECORD_DETAIL_TM_PUTTING: (id = ':id') => '/records/my-training/' + id + '/putting',
  RECORD_DETAIL_TM_SWING_VIDEOS: (id = ':id') => '/records/my-training/' + id + '/swing-video',
  RECORD_SCORE_CARD_GS: (id = ':id') => '/records/score-card/' + id,
  LEAGUE_PAGE: '/leagues',
  TOURNAMENT_PAGE: '/tournaments',
  ALL_PARTICIPATED_TOURNAMENTS: '/tournaments/participated',
  ALL_PARTICIPATED_TOURNAMENTS_ENDED: '/tournaments/participated/ended',
  ALL_PARTICIPATED_LEAGUES: '/leagues/participated',
  ALL_PARTICIPATED_LEAGUES_ENDED: '/leagues/participated/ended',
  TOURNAMENT_DETAIL: (id = ':id') => '/tournaments/' + id,
  NEWS: '/news',
  NEWS_DETAIL: (id = ':id', categoryId = ':codeCategory') => '/news/' + id + '/category/' + categoryId,
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  MARKETING_POLICY: '/marketing-policy',
  PRIVACY_POLICY_FIRST: '/privacy-policy/2023-01-04',
  SETTING: '/setting',
  SETTING_EDIT_PROFILE: '/setting/edit-profile',
  SETTING_CHANGE_PASSWORD: '/setting/change-password',
  SETTING_CHANGE_EMAIL: '/setting/change-email',
  SETTING_CHANGE_COUNTRY: '/setting/change-country',
  SETTING_DISTANCE: '/setting/distance',
  SETTING_SCREEN_SETTINGS: '/setting/screen-settings',
  SETTING_ALARM_LIST: '/setting/notify-alarm',
  SETTING_FAQ: '/setting/faq',
  GUIDELINE_CONFIG: '/guide-config',
  SETTING_WITH_DRAW: '/setting/withdraw',
  ABOUT_PROFILE: '/profile',
  PROFILE: (id = ':id') => '/profile/' + id,
  PROFILE_LEVEL_TAGS: (id = ':id') => '/profile/' + id + '/level-tags',
  PROFILE_FEED: (id = ':id') => '/profile/' + id + '/feed',
  PROFILE_FEED_DETAIL: (userId = ':userId', id = ':id') => '/profile/' + userId + '/feed/' + id,
  PROFILE_PARTNER_ROUND_COMPARISON: (id = ':id') => '/profile/' + id + '/round-comparison',
  PROFILE_RANKING_STORE_DETAIL: (id = ':id') => '/profile/' + id + '/ranking/store',
  PROFILE_RANKING_CITY_DETAIL: (id = ':id') => '/profile/' + id + '/ranking/city',
  PROFILE_RANKING_LEVEL_DETAIL: (id = ':id') => '/profile/' + id + '/ranking/level',
  PROFILE_RANKING_GLOBAL_DETAIL: (id = ':id') => '/profile/' + id + '/ranking/global',
  PROFILE_RANKING_COUNTRY_DETAIL: (id = ':id') => '/profile/' + id + '/ranking/country',
  PAGE_NOT_FOUND: '/not-found',
  LINK_TO_APP: '/linkToApp',
  LEADER_BOARD: '/gwc',
  ROULETTE: '/roulette',
  EMBEDDED_STORES_MAP: '/embed/stores-map',
  EMBEDDED_STORE_DETAIL: (id = ':id') => '/embed/store/' + id,
  PLUS_ITEMS: (id = ':id') => '/plus-items/' + id,
};
