import axios from 'src/requests';
import {
  ILocationParams,
  ISearchStoreNewsRequest,
  ISearchStoreGetAutoFillParams,
  ISearchStoreSuggestionHistory,
} from 'src/requests/api/store-news/prop-state.type';

const getTotalNearListStoreNews = async (params: ILocationParams) => {
  const response = await axios.get('/site/getTotalList', { params });
  return response.data.data;
};

const getRecentlyViewedStores = async () => {
  const response = await axios.get('/site/getClickList');
  return response.data.data;
};

const getSearchStoreList = async (params: ISearchStoreNewsRequest) => {
  const response = await axios.get('/site/getSearchList', { params });
  return response.data.data;
};

const getSuggestionHistory = async () => {
  const response = await axios.get('/site/getSuggestionHistory');
  return response.data.data;
};

const getAutoFill = async (params: ISearchStoreGetAutoFillParams) => {
  const response = await axios.get('/site/getAutoFill', { params });
  return response.data.data;
};

const saveSuggestionHistory = async (params: ISearchStoreSuggestionHistory) => {
  const response = await axios.post('/site/saveSuggestionHistory', params);
  return response.data.data;
};

export const findStoreRequests = {
  getTotalNearListStoreNews,
  getRecentlyViewedStores,
  getSearchStoreList,
  getSuggestionHistory,
  getAutoFill,
  saveSuggestionHistory,
};
