import { MAX_STANDARD_HOLE_COUNT, scorecardConstants, scoreCardWidths } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import { customHeader, generateTableHeaders } from 'src/services/scorecard-modes';
import { numberWithSign } from 'src/services/utils';
import { twMerge } from 'tailwind-merge';

export const myLasVegasHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [
      customHeader.HOLE(normalColor),
      customHeader.BASE_SCORE(customColor, totalHoleCnt, scoreCardWidths.DEFAULT_WIDTH),
      {
        title: scorecardConstants.LAS_VEGAS_HEADER,
        key: scorecardConstants.LAS_VEGAS_KEY,
        className: twMerge(`w-[${scoreCardWidths.DEFAULT_WIDTH}%] text-center text-[8px] font-bold`, customColor),
        width: scoreCardWidths.DEFAULT_WIDTH,
        rowSpan: 2,
      },
    ],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    color: normalColor,
  });
};

export const otherLasVegasHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [
      {
        title: '',
        key: scorecardConstants.TEAM_KEY,
        className: twMerge(`w-[${scoreCardWidths.EMPTY_TEAM_WIDTH}%] pl-[8px]`, normalColor),
        width: scoreCardWidths.EMPTY_TEAM_WIDTH,
        fixed: true,
      },
      {
        title: scorecardConstants.HOLE_HEADER,
        key: scorecardConstants.HOLE_KEY,
        className: twMerge(`w-[${scoreCardWidths.HOLE_WITH_EMPTY_TEAM_WIDTH}%] pl-[8px]`, normalColor),
        width: scoreCardWidths.HOLE_WITH_EMPTY_TEAM_WIDTH,
        fixed: true,
      },
      {
        title: scorecardConstants.BASE_SCORE_HEADER,
        key: `${scorecardConstants.HOLE_NO_KEY}19`,
        className: twMerge(`text-center w-[${scoreCardWidths.DEFAULT_WIDTH}%]`, customColor),
        width: scoreCardWidths.DEFAULT_WIDTH,
      },
      {
        title: scorecardConstants.LAS_VEGAS_HEADER,
        key: `${scorecardConstants.HOLE_NO_KEY}20`,
        className: twMerge(`w-[${scoreCardWidths.DEFAULT_WIDTH}%] text-center text-[8px] font-bold`, customColor),
        width: scoreCardWidths.DEFAULT_WIDTH,
      },
    ],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    color: normalColor,
  });
};

export const lasVegasData = (gsScoreCard: any[], lasVegasEntity: any) => {
  const parScore = gsScoreCard.find((scoreCard) => scoreCard.usrId === scorecardConstants.PAR_ID_VALUE);
  const currentUserScore = gsScoreCard.find((scoreCard) => scoreCard.oderm === 1);
  const teamA = {
    key: 'A',
    [scorecardConstants.TEAM_KEY]: {
      value: '',
      className: 'bg-gz-danger',
    },
    [scorecardConstants.HOLE_KEY]: {
      value: 'TEAM A',
      className: 'pl-[8px]',
    },
  } as any;
  const teamB = {
    key: 'B',
    [scorecardConstants.TEAM_KEY]: {
      value: '',
      className: 'bg-primary-75',
    },
    [scorecardConstants.HOLE_KEY]: {
      value: 'TEAM B',
      className: 'pl-[8px]',
    },
  } as any;

  const parData = {
    [scorecardConstants.HOLE_KEY]: {
      value: scorecardConstants.PAR_NAME_VALUE,
      className: 'px-[8px] !border-l-0 bg-bg text-sub-body',
    },
    [scorecardConstants.BASE_SCORE_KEY]: {
      value: parScore[scorecardConstants.BASE_SCORE_KEY],
      className: 'text-center bg-bg',
    },
  } as any;

  const strokeData = {
    [scorecardConstants.HOLE_KEY]: {
      value: scorecardConstants.STROKE_NAME,
      className: 'px-[8px] !border-l-0',
    },
    [scorecardConstants.LAS_VEGAS_KEY]: {
      value: lasVegasEntity.lasvegasPoint,
      className: 'text-center gz-text-tiny font-bold',
      rowSpan: 2,
    },
  } as any;
  const pointsData = {
    [scorecardConstants.HOLE_KEY]: {
      value: scorecardConstants.POINTS_NAME,
      className: 'px-[8px] !border-l-0',
    },
    [scorecardConstants.BASE_SCORE_KEY]: {
      value: currentUserScore[scorecardConstants.BASE_SCORE_KEY],
      className: 'text-center gz-text-tiny font-bold',
    },
  } as any;

  let strokeBaseScore = 0;
  for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
    const isHiddenScore =
      currentUserScore[`${scorecardConstants.PR_STATUS_KEY}${i}`] === scorecardConstants.ALTERNATIVE_HIDDEN_SCORE ||
      Number(currentUserScore[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === 0;
    const bgColor = isHiddenScore
      ? 'bg-bg'
      : currentUserScore[`${scorecardConstants.PR_STATUS_KEY}${i}`] === teamA.key
      ? 'bg-gz-danger'
      : 'bg-primary-75';
    parData[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
      value: parScore[`${scorecardConstants.HOLE_NO_KEY}${i}`],
      className: 'text-center bg-bg text-sub-body',
    };

    const strokeDataValue =
      Number(currentUserScore[`${scorecardConstants.HOLE_NO_KEY}${i}`]) -
      Number(parScore[`${scorecardConstants.HOLE_NO_KEY}${i}`]);
    strokeData[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
      value: isHiddenScore ? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE : strokeDataValue,
      className: twMerge('text-center', bgColor, !isHiddenScore && 'text-gz-white'),
    };
    strokeBaseScore += isHiddenScore ? 0 : strokeDataValue;

    pointsData[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
      value: isHiddenScore
        ? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE
        : Number(currentUserScore[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
      className: twMerge('text-center', bgColor, !isHiddenScore && 'text-gz-white'),
    };
  }
  strokeData[scorecardConstants.BASE_SCORE_KEY] = {
    value: numberWithSign(strokeBaseScore),
    className: 'text-center gz-text-tiny font-bold',
  };

  lasVegasEntity.teamA.forEach((data: number, index: number) => {
    teamA[`${scorecardConstants.HOLE_NO_KEY}${index + 1}`] = {
      value:
        currentUserScore[`${scorecardConstants.PR_STATUS_KEY}${index + 1}`] ===
          scorecardConstants.ALTERNATIVE_HIDDEN_SCORE ||
        Number(currentUserScore[`${scorecardConstants.HOLE_NO_KEY}${index + 1}`]) === 0
          ? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE
          : data,
      className: twMerge('text-center', index + 1 >= lasVegasEntity.teamA.length - 1 ? 'gz-text-tiny font-bold' : ''),
    };
  });
  lasVegasEntity.teamB.forEach((data: number, index: number) => {
    teamB[`${scorecardConstants.HOLE_NO_KEY}${index + 1}`] = {
      value:
        currentUserScore[`${scorecardConstants.PR_STATUS_KEY}${index + 1}`] ===
          scorecardConstants.ALTERNATIVE_HIDDEN_SCORE ||
        Number(currentUserScore[`${scorecardConstants.HOLE_NO_KEY}${index + 1}`]) === 0
          ? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE
          : index < lasVegasEntity.teamA.length - 1
          ? data
          : numberWithSign(data),
      className: twMerge('text-center', index + 1 >= lasVegasEntity.teamA.length - 1 ? 'gz-text-tiny font-bold' : ''),
    };
  });

  const currentUserData = [parData, strokeData, pointsData];
  const otherUsersData = [teamA, teamB];
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList: [],
  };
};
