import { useMemo } from 'react';
import { logoHeader } from 'src/assets/images';
import { IcFacebook, IcInstagram, IcYouTube, IconChevronDown } from 'src/assets/svg';
import { countryCode } from 'src/constants/const';
import DropdownMenu from 'src/view/commons/elements/DropdownMenu';
import { TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';

interface IProps {
  lang?: string;
}

const FooterRegion = (props: IProps) => {
  const { lang } = props;
  const listMenu = [
    {
      name: lang === countryCode.JAPAN ? TextJapan.about_us : TextGlobal.about_us,
      href: 'http://company.golfzon.com/GFZ/Default.aspx',
    },
    {
      name: lang === countryCode.JAPAN ? TextJapan.terms_of_use : TextGlobal.terms_of_use,
      href: 'https://www.global.golfzon.com/terms-of-use',
    },
    {
      name: lang === countryCode.JAPAN ? TextJapan.privacy_policy : TextGlobal.privacy_policy,
      href: 'https://www.global.golfzon.com/privacy-policy',
    },
  ];

  const listLinkOther = useMemo(() => {
    return [
      {
        title: 'Global',
        value: `https://www.golfzongolf.com/global`,
      },
      {
        title: 'North America',
        value: `https://www.golfzongolf.com`,
      },
      {
        title: 'Japan',
        value: `https://company.golfzon.jp/`,
      },
      {
        title: 'China',
        value: `https://www.golfzon.com.cn`,
      },
      {
        title: 'VietNam',
        value: `http://www.golfzonvn.com`,
      },
      {
        title: 'Korea',
        value: `https://www.golfzon.com`,
      },
    ];
  }, []);

  const onOpenLink = (value: string) => {
    if (value === '#') {
      return;
    }
    window.open(value, '_blank');
  };

  return (
    <div className="bg-cl-white-smoke-9 py-[24px] md:bg-white lg:px-0">
      <div className="mx-auto max-w-[768px]">
        <div className="md:flex md:justify-between">
          <div className="flex justify-center">
            <img src={logoHeader} className="object-stretch h-[32px] w-[98px]" alt="logo" />
          </div>
          <div className="mt-4 mb-2 flex items-center justify-center divide-x text-sm font-semibold lg:mb-3">
            {listMenu.map((item) => (
              <span
                key={item.name}
                className="text-cl-blackcurrant cursor-pointer px-4 first:pl-0 last:pr-0"
                onClick={() => onOpenLink(item.href)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div className="md:mt-[0.4rem] md:flex md:flex-row-reverse md:items-center md:justify-between">
          <div className="md:flex md:flex-row-reverse">
            <div className="flex w-full items-center justify-center lg:w-[196px]">
              <DropdownMenu
                items={listLinkOther}
                defaultValue={listLinkOther[0].value}
                classContainer="w-full mx-4 lg:mx-0"
                onChange={onOpenLink}
                classBtnCustom="w-full"
                classMenuItems="absolute bottom-[44px]"
              >
                <div className="flex w-full flex-row items-center justify-between rounded-[8px] border-[1px] py-2 px-3">
                  <span className="gz-text-xs ext-truncate-inner mr-1">Family Sites</span>
                  <IconChevronDown className="fill-title" />
                </div>
              </DropdownMenu>
            </div>
            <div className="mt-1 mb-3 flex items-center justify-center lg:mr-4 lg:mt-0 lg:mb-0">
              <div>
                <a
                  className="flex h-[38px] w-[38px] items-center justify-center"
                  href="https://www.facebook.com/Golfzon.Global/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IcFacebook />
                </a>
              </div>
              <div>
                <a
                  className="flex h-[38px] w-[38px] items-center justify-center"
                  href="https://www.instagram.com/golfzon_global/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IcInstagram />
                </a>
              </div>
              <div>
                <a
                  className="flex h-[38px] w-[38px] items-center justify-center"
                  href="https://www.youtube.com/@GOLFZONGOLF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IcYouTube />
                </a>
              </div>
            </div>
          </div>
          <div className="text-cl-raven gz-text-tiny mt-[12px] text-center lg:mt-[16px]">
            Copyright © 2023 GOLFZON Co., LTD rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterRegion;
