import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { banerLeaderBoardEN, banerLeaderBoardJP, iconCup, top1World } from 'src/assets/images';
import { countryCode, pathConstants } from 'src/constants/const';
import axios from 'src/requests';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import { LeaderBoardContext } from 'src/view/pages/leader-board';
import FooterRegion from 'src/view/pages/leader-board/component/Footer';
import HeaderLoadBoard from 'src/view/pages/leader-board/component/HeaderLeaderBoard';
import ItemTable from 'src/view/pages/leader-board/component/ItemTable';
import RankTop from 'src/view/pages/leader-board/component/RankTop';
import TermsAndConditions from 'src/view/pages/leader-board/component/TermsAndConditions';
import { EnumZone, TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';
import { twMerge } from 'tailwind-merge';

const TourNament = () => {
  const context = useContext(LeaderBoardContext);
  const navigate = useNavigate();
  const [dataZone, setDataZone] = useState<any>([]);
  const [zoneActive, setZoneActive] = useState(EnumZone.zonea);
  const [infoTop1, setInfoTop1] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    context?.select.setIsChecked(!context.select.isChecked);
  };

  const handleGetListZone = async (val?: string) => {
    setLoading(true);
    await axios
      .get('tournament/leaderboard/top-by-zone', {
        params: {
          zone: val,
        },
      })
      .then((res) => {
        setDataZone(res.data?.data?.entities);
      })
      .catch((err) => {
        setDataZone([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetTop1 = async (val?: any) => {
    await axios
      .get('tournament/leaderboard/top-1')
      .then((res) => {
        setInfoTop1(res.data?.data?.entities);
      })
      .catch((err) => {
        setInfoTop1({});
      });
  };

  useEffect(() => {
    handleGetTop1();
  }, []);

  useEffect(() => {
    handleGetListZone(zoneActive);
  }, [zoneActive]);

  useEffect(() => {
    if (context?.lang.currentLang === countryCode.JAPAN) {
      setZoneActive(EnumZone.zoneb);
    }
  }, [context?.lang.currentLang]);

  const checkSameRank = (i: any) => {
    let a = false;
    const arr = dataZone?.filter((el: any) => el?.rankOfUser === i?.rankOfUser);
    if (arr.length > 1) {
      a = true;
    }
    return a;
  };

  const ZoneButton = ({ active = false, onClick = () => {}, text = '' }) => (
    <button
      className={twMerge(
        `rounded-[60px] px-[20px] py-[11px] lg:rounded-[80px] lg:px-[36px] lg:py-[15px] ${text === 'Zone B' && 'mx-3'}`,
      )}
      onClick={onClick}
      style={{
        backgroundColor: active ? '#086EB8' : '#EBF5FE',
      }}
    >
      <p
        className={twMerge(`mb-0 text-[18px] text-white lg:text-[24px] ${active ? 'font-bold' : 'font-normal'}`)}
        style={{
          color: active ? '#fff' : '#086EB8',
        }}
      >
        {text}
      </p>
    </button>
  );

  return (
    <div className="flex flex-col ">
      <div className="max-w-[780px] self-center">
        <HeaderLoadBoard />
        <div className="container mx-auto ">
          <img
            src={context?.lang.currentLang === countryCode.JAPAN ? banerLeaderBoardJP : banerLeaderBoardEN}
            className="object-stretch w-full"
            alt="banner"
          />
        </div>
        <div className="py-[16px] text-center">
          <p className="mb-3 text-[13px] font-bold text-gray-900 lg:text-2xl">
            {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.tournament : TextGlobal.tournament}
          </p>
          <div className="mx-auto">
            <div className="inline-block rounded-full bg-gradient-to-r from-blue-90 via-blue-100 to-blue-90 px-4 py-2 hover:opacity-80">
              <p
                className="mb-0 px-4 text-lg font-bold text-white hover:cursor-pointer lg:px-[24px] lg:text-[32px]  "
                onClick={() => {
                  navigate(
                    pathConstants.TOURNAMENT_DETAIL(context?.lang.currentLang === countryCode.JAPAN ? '9333' : '9332'),
                  );
                }}
              >{`${context?.lang.currentLang === countryCode.JAPAN ? '# 大会番号:' + 9084 : 9083}`}</p>
            </div>
          </div>
        </div>
        <div className="mx-auto  bg-[url('src/assets/images/baner-cup.png')] bg-cover">
          <div className=" relative flex flex-col items-center">
            <img
              src={top1World}
              className=" absolute mx-auto mt-[40px] max-h-[86px] object-center lg:mt-[60px] lg:max-h-[111px]"
              alt=""
            />
            <img src={iconCup} className="mx-auto object-center " alt="" />
            {!_.isEmpty(infoTop1?.userId) && <RankTop infoTop1={infoTop1} />}
          </div>
          <div className="mt-[40px] rounded-[8px] ">
            {/*  */}
            <div className=" rounded-t-[16px] bg-white px-0 py-[24px] lg:rounded-t-[32px] lg:px-[24px]">
              <div className="mb-4 flex flex-row justify-center">
                <ZoneButton
                  active={zoneActive === EnumZone.zonea}
                  onClick={() => setZoneActive(EnumZone.zonea)}
                  text="Zone A"
                />
                <ZoneButton
                  active={zoneActive === EnumZone.zoneb}
                  onClick={() => setZoneActive(EnumZone.zoneb)}
                  text="Zone B"
                />
                <ZoneButton
                  active={zoneActive === EnumZone.zonec}
                  onClick={() => setZoneActive(EnumZone.zonec)}
                  text="Zone C"
                />
              </div>
              {/*  */}
              <div className="mb-2 flex w-full justify-end px-[16px] lg:px-[0px]">
                <label className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-full bg-gray-10 p-1 ">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={context?.select.isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className={`gz-text-sm rounded-full px-3 py-[6px] ${
                      !context?.select.isChecked ? ' bg-white font-medium text-black' : 'text-black'
                    }`}
                  >
                    Yards
                  </span>
                  <span
                    className={`gz-text-sm rounded-full px-3 py-[6px] ${
                      context?.select.isChecked ? ' bg-white font-medium text-black' : 'text-black'
                    }`}
                  >
                    Meters
                  </span>
                </label>
              </div>
              {/*  */}
              <table className="gz-word-wrap w-full table-fixed">
                <thead>
                  <tr className="bg-primary-10 py-3 ">
                    <th className="w-20 py-3 text-center">
                      {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.RANK : TextGlobal.RANK}
                    </th>
                    <th className="w-auto py-3 text-left">
                      {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.PLAYER : TextGlobal.PLAYER}
                    </th>
                    <th className="w-20 py-3 text-center">
                      {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.SCORE : TextGlobal.SCORE}
                    </th>
                  </tr>
                </thead>
                {loading || dataZone.length < 1 ? (
                  <tbody className="">
                    <td colSpan={3}>
                      <NotFoundItem className="" />
                    </td>
                  </tbody>
                ) : (
                  dataZone.length > 0 &&
                  dataZone.map((i: any, index: number) => {
                    return (
                      <ItemTable
                        key={index}
                        item={i}
                        sameRank={checkSameRank(i)}
                        lang={context?.lang.currentLang}
                        isCheck={context?.select.isChecked}
                      />
                    );
                  })
                )}
              </table>
            </div>
          </div>
        </div>
        <TermsAndConditions />
        <FooterRegion lang={context?.lang.currentLang || ''} />
      </div>
    </div>
  );
};

export default TourNament;
