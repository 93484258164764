import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { picFeedVideoThumbnail11 } from 'src/assets/images';
import ImageCustom from 'src/view/commons/customs/Image';
import { IconVideoPlayOutline } from 'src/assets/svg';
import { fixDecimal } from 'src/services/utils';
import ScrollView from 'src/view/commons/customs/ScrollView';

export enum ITypeLayout {
  GRID,
  LIST,
}
export interface INasmoItem {
  nasmoId: number;
  clubName: string;
  clubCode: string;
  nasmoImgFile: string;
  distance: string;
  distUnit: string;
  holeNo?: string;
}
interface INasmoListProps {
  classWrapper?: string;
  classClubName?: string;
  classHole?: string;
  classActiveClubName?: string;
  classActiveHole?: string;
  listNasmo?: INasmoItem[];
  distanceDecimal?: number;
  typeLayout: ITypeLayout;
  chooseVideo?: (clubCode: number) => void;
  defaultActive?: number;
  hideClubName?: boolean;
  hideHole?: boolean;
  hidePlayBtn?: boolean;
  absoluteClubName?: boolean;
  absoluteHole?: boolean;
  titleCenter?: boolean;
}

const NasmoList: React.FC<INasmoListProps> = React.memo(
  ({
    classWrapper,
    classClubName,
    classHole,
    classActiveClubName,
    classActiveHole,
    listNasmo,
    distanceDecimal,
    typeLayout,
    chooseVideo,
    defaultActive,
    hideClubName = false,
    hideHole = false,
    hidePlayBtn = false,
    absoluteClubName = true,
    absoluteHole = false,
    titleCenter = true,
  }) => {
    const translate = useTranslation().t;

    const handleClickVideo = (id: number) => {
      chooseVideo && chooseVideo(id);
    };

    const isActiveVideo = (id: number) => {
      return defaultActive === id;
    };

    const clubNameAndDistance = (item: INasmoItem) => {
      return (
        <div
          className={twMerge('text-gz-white', classClubName, isActiveVideo(item.nasmoId) ? classActiveClubName : '')}
        >
          <div className="gz-text-md font-bold">{item.clubName}</div>
          <div className="gz-text-sm">{`${fixDecimal(item.distance, false, distanceDecimal)} ${item.distUnit}`}</div>
        </div>
      );
    };

    const hole = (item: INasmoItem) => (
      <div className={twMerge('gz-text-xsm', classHole, isActiveVideo(item.nasmoId) ? classActiveHole : '')}>
        {translate('nasmo.HOLE', { number: item.holeNo })}
      </div>
    );

    const listView = (
      <>
        {listNasmo &&
          listNasmo.length > 0 &&
          listNasmo.map((item: INasmoItem, index: number) => (
            <div
              className={twMerge(typeLayout === ITypeLayout.LIST && '[&:not(:last-child)]:mr-[16px]')}
              key={`item-nasmo-${index}-${item?.clubCode}`}
              onClick={() => handleClickVideo(item?.nasmoId)}
            >
              <div
                className={twMerge(
                  'relative aspect-square overflow-hidden rounded-[6px] bg-black-60',
                  typeLayout === ITypeLayout.GRID ? '' : 'w-[88px]',
                )}
              >
                <div
                  className={twMerge(
                    'h-full w-full',
                    'before:absolute before:top-0 before:left-0 before:h-full before:w-full before:rounded-[6px]',
                    isActiveVideo(item?.nasmoId) ? 'before:shadow-inner-pic' : '',
                  )}
                >
                  <ImageCustom
                    className="image-cover"
                    imgPath={item?.nasmoImgFile}
                    imgNotFound={picFeedVideoThumbnail11}
                    imgAlt={item?.clubName}
                  />

                  <div className="absolute bottom-[9px] left-[9px]">
                    <>
                      {absoluteClubName && !hideClubName && clubNameAndDistance(item)}
                      {absoluteHole && !hideHole && hole(item)}
                    </>
                  </div>
                </div>
              </div>

              <div
                className={twMerge('mt-[8px] flex items-center', titleCenter ? 'justify-center' : 'justify-between')}
              >
                <>
                  {!absoluteClubName && !hideClubName && (
                    <div className={twMerge(titleCenter ? '[&:not(:first-child)]:ml-[8px]' : '')}>
                      {clubNameAndDistance(item)}
                    </div>
                  )}
                  {!absoluteHole && !hideHole && (
                    <div className={twMerge(titleCenter ? '[&:not(:first-child)]:ml-[8px]' : '')}>{hole(item)}</div>
                  )}
                  {!hidePlayBtn && (
                    <IconVideoPlayOutline
                      className={twMerge(
                        'cursor-pointer fill-disable stroke-disable only:mx-auto',
                        titleCenter ? '[&:not(:first-child)]:ml-[8px]' : '',
                        isActiveVideo(item.nasmoId) ? 'fill-gz-primary stroke-gz-primary' : '',
                      )}
                      onClick={() => handleClickVideo(item?.nasmoId)}
                    />
                  )}
                </>
              </div>
            </div>
          ))}
      </>
    );

    return (
      <>
        {listNasmo && listNasmo.length > 0 && (
          <>
            {typeLayout === ITypeLayout.GRID ? (
              <div className={twMerge('grid grid-cols-3 gap-[16px] md:grid-cols-6', classWrapper)}>{listView}</div>
            ) : (
              <ScrollView className={twMerge('scroll-hidden flex flex-nowrap pb-[16px]', classWrapper)}>
                {listView}
              </ScrollView>
            )}
          </>
        )}
      </>
    );
  },
);

export default NasmoList;
