import i18n from 'src/locale';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { EnumRequestCode } from 'src/constants/enum';
import { setting } from 'src/requests/api/setting';

export const MY_SETTING_INFO = 'MY_SETTING_INFO';
export const UPDATE_WIFI_INFO = 'UPDATE_WIFI_INFO';

export const settingDispatch = {
  info: (dataSetting: any) => ({
    type: MY_SETTING_INFO,
    payload: {
      settingInfo: dataSetting,
    },
  }),
  updateWifiInfo: (wifiEnable: boolean) => ({
    type: UPDATE_WIFI_INFO,
    payload: {
      wifiEnable,
    },
  }),
};

const settingInformation = () => async (dispatch: any) => {
  try {
    const response = await setting.getMySettingInfo();
    if (response.code === EnumRequestCode.SUCCESS) {
      dispatch(settingDispatch.info(response.entities));
    } else {
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  }
};
export const settingAction = {
  settingInformation,
};
