import { twMerge } from 'tailwind-merge';
import { IconChevronLeft, IconClose } from 'src/assets/svg';
import { isDesktop, isIOS, isMobile } from 'react-device-detect';
import { history } from 'src/services/history';
import { useStickyHeaderOnSafari } from 'src/hooks/sticky-header-on-Safari';
import { useResizeWindow } from 'src/hooks/use-resize-window';
import { useNoHeaderSearchParam } from 'src/hooks/use-no-header-search-param';
import { EnumNoHeaderSearchParams } from 'src/constants/enum';

interface IHeaderProps {
  className?: string;
  heightHeader?: string;
  classHeaderContainer?: string;
  classTitle?: string;
  classBackIcon?: string;
  classBackIconContainer?: string;
  classPrefix?: string;
  classAffix?: string;
  prefix?: JSX.Element;
  affix?: JSX.Element;
  canBack?: boolean;
  typeClose?: boolean;
  title?: string | JSX.Element;
  hiddenTitle?: boolean;
  onBack?: () => void;
}

function Header({
  className,
  heightHeader,
  classHeaderContainer,
  classTitle,
  classBackIcon,
  classBackIconContainer,
  classPrefix,
  classAffix,
  prefix,
  affix,
  canBack = true,
  typeClose,
  title = '',
  hiddenTitle,
  onBack,
}: IHeaderProps): JSX.Element {
  useStickyHeaderOnSafari();
  const { mainWidth } = useResizeWindow();
  const noHeader = useNoHeaderSearchParam();

  const back = () => {
    onBack?.call(null);
    history.pop();
  };

  const headerElement = () => {
    const backable =
      canBack && noHeader !== EnumNoHeaderSearchParams.BACK_KEEP && noHeader !== EnumNoHeaderSearchParams.BACK_ONCE;
    return (
      <div className="flex justify-start">
        <div
          className={twMerge(
            'flex w-full items-center justify-between px-[16px]',
            isDesktop ? 'h-[56px]' : 'h-[44px]',
            heightHeader,
            classHeaderContainer,
          )}
        >
          <div className="flex min-w-0 flex-1 items-center">
            {backable && (
              <div
                className={twMerge(
                  '-ml-[8px] flex flex-shrink-0 cursor-pointer items-center justify-center p-[8px]',
                  !typeClose && 'rounded-[50%] active:bg-light-gray dark:active:bg-dark-33',
                  classBackIconContainer,
                )}
                onClick={back}
              >
                {!typeClose && (
                  <IconChevronLeft
                    className={twMerge('flex-shrink-0 stroke-text dark:stroke-gz-white', classBackIcon)}
                  />
                )}
                {typeClose && (
                  <IconClose className={twMerge('flex-shrink-0 stroke-text dark:stroke-gz-white', classBackIcon)} />
                )}
              </div>
            )}
            {prefix && <div className={twMerge('flex-shrink-0', classPrefix)}>{prefix}</div>}
            {!hiddenTitle && (
              <div
                className={twMerge(
                  'gz-text-md ml-[8px] flex-1 overflow-hidden text-ellipsis whitespace-nowrap font-medium',
                  'text-title dark:text-gz-white',
                  classTitle,
                )}
              >
                {title}
              </div>
            )}
          </div>
          {affix && <div className={twMerge('flex-shrink-0 pl-[16px]', classAffix)}>{affix}</div>}
        </div>
      </div>
    );
  };

  if (noHeader === EnumNoHeaderSearchParams.KEEP || noHeader === EnumNoHeaderSearchParams.ONCE) {
    return <></>;
  }

  if (isMobile && isIOS) {
    const height = heightHeader ?? 'h-[44px]';
    const allClasses = twMerge('fixed top-0 z-10', height, className);
    const hasHiddenClass = allClasses.split(' ').includes('hidden');
    const hasFixedClass = allClasses.split(' ').includes('fixed');
    return (
      <>
        <header id="ios-toolbar-wrap" className={allClasses} style={{ width: mainWidth }}>
          <div
            id="ios-toolbar"
            className={twMerge('absolute left-0 right-0 bg-gz-white shadow-bottom dark:bg-black', className, 'top-0')}
          >
            {headerElement()}
          </div>
        </header>
        {hasFixedClass && <div className={twMerge(height, hasHiddenClass && 'hidden')} />}
      </>
    );
  }

  return (
    <>
      <header
        className={twMerge('sticky top-0 z-10 w-full bg-gz-white shadow-bottom dark:bg-black', heightHeader, className)}
      >
        {headerElement()}
      </header>
    </>
  );
}

export default Header;
