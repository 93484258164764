import { MAX_STANDARD_HOLE_COUNT, scorecardConstants } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import { customHeader, generateTableHeaders, getStablefordBgColor } from 'src/services/scorecard-modes';
import { twMerge } from 'tailwind-merge';

export const stablefordHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [customHeader.HOLE(normalColor), customHeader.BASE_SCORE(customColor, totalHoleCnt)],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    color: normalColor,
  });
};

export const stablefordData = (gsScoreCard: any[]) => {
  // Move par and current user score to top
  const scorecardData = [...gsScoreCard];
  const currentUserIndex = scorecardData.findIndex((data) => data.ordernum === 2);
  scorecardData.unshift(scorecardData.splice(currentUserIndex, 1)[0]);
  const parIndex = scorecardData.findIndex((data) => data.usrId === scorecardConstants.PAR_ID_VALUE);
  scorecardData.unshift(scorecardData.splice(parIndex, 1)[0]);
  const parData = scorecardData[0];
  const dataList = scorecardData.map((scoreCard) => {
    const data = {} as any;
    data.isPar = scoreCard.usrId === scorecardConstants.PAR_ID_VALUE;
    data.isCurrentUser = scoreCard.ordernum === 2;

    data[scorecardConstants.HOLE_KEY] = {
      value: data.isPar ? scorecardConstants.PAR_NAME_VALUE : scoreCard[scorecardConstants.USER_NICKNAME_KEY],
      className: twMerge('px-[8px] !border-l-0', data.isPar ? 'bg-bg text-sub-body' : 'bg-gz-white'),
    };

    data[scorecardConstants.BASE_SCORE_KEY] = {
      value: data.isPar
        ? scoreCard[scorecardConstants.BASE_SCORE_KEY]
        : scoreCard[scorecardConstants.STABLE_TOTAL_SCORE_KEY],
      className: twMerge('text-center', !data.isPar ? 'gz-text-sm font-bold' : 'bg-bg'),
    };

    for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
      const isEmptyHole = Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === 0;
      if (isEmptyHole && !data.isPar) {
        data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
          value: scorecardConstants.ALTERNATIVE_HIDDEN_SCORE,
          className: 'text-center',
        };
        continue;
      }
      data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
        value: data.isPar
          ? scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]
          : Number(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === scorecardConstants.HIDDEN_SCORE
          ? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE
          : scoreCard[`${scorecardConstants.STABLE_NO_KEY}${i}`],
        className: twMerge(
          'text-center',
          !data.isPar && parData
            ? getStablefordBgColor(Number(scoreCard[`${scorecardConstants.STABLE_NO_KEY}${i}`]))
            : '',
          data.isPar ? 'bg-bg text-sub-body' : '',
        ),
      };
    }
    return data;
  });
  const currentUserData = dataList
    .filter((data: any) => data.isPar || data.isCurrentUser)
    .map((data: any) => {
      return {
        ...data,
        [scorecardConstants.HOLE_KEY]: data.isCurrentUser
          ? {
              ...data[scorecardConstants.HOLE_KEY],
              value: scorecardConstants.POINTS_NAME,
            }
          : data[scorecardConstants.HOLE_KEY],
      };
    });
  const otherUsersData = dataList;
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList: [],
  };
};
