import { isDesktop, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { scoreTypeColor } from 'src/constants/const';
import { twMerge } from 'tailwind-merge';

interface IScoreTableProps {
  className?: string;
  stableford?: boolean;
}

function ScoreNotes({ className, stableford }: IScoreTableProps): JSX.Element {
  const translate = useTranslation().t;

  const normalNoteList = {
    EAGLE_OR_LESS: scoreTypeColor.EAGLE,
    BIRDIE: scoreTypeColor.BIRDIE,
    BOGEY: scoreTypeColor.BOGEY,
    DBOGEY_OR_MORE: scoreTypeColor.DOUBLE_BOGEY,
  };

  const stablebordNoteList = {
    STABLEFORD_ALBATROSS: scoreTypeColor.ALBATROSS,
    STABLEFORD_EAGLE: scoreTypeColor.EAGLE,
    STABLEFORD_BIRDIE: scoreTypeColor.BIRDIE,
    STABLEFORD_PAR: scoreTypeColor.PAR,
    STABLEFORD_BOGEY: scoreTypeColor.BOGEY,
    STABLEFORD_DBOGEY_AND_ABOVE: scoreTypeColor.DOUBLE_BOGEY,
  };

  const noteList = !stableford ? normalNoteList : stablebordNoteList;

  const noteItem = (key: string, bgColor: string) => {
    return (
      <div className="flex items-center">
        <div className={twMerge('h-[12px] w-[12px] flex-shrink-0 rounded-[4px]', bgColor)} />
        <div
          className="ml-[4px] break-normal text-[9px] uppercase leading-[16px]"
          dangerouslySetInnerHTML={{
            __html: translate(`pages.record.score_card.NOTE_${key}`),
          }}
        />
      </div>
    );
  };
  const numberPerRows = stableford ? 3 : 4;
  const noteElementArr = [];
  const noteKeyArr = Object.keys(noteList);
  for (let index = 0; index < numberPerRows; index++) {
    const group: string[] = [];
    noteKeyArr.forEach((noteKey: string, noteIndex: number) => {
      if (noteIndex % numberPerRows === index) group.push(noteKey);
    });
    noteElementArr.push(group);
  }

  return (
    <div
      className={twMerge(
        'score-notes flex w-full',
        !isTablet && !isDesktop && stableford && 'justify-between',
        className,
      )}
    >
      {noteElementArr.map((group: string[], index: number) => (
        <div
          className={twMerge(
            'score-note-group',
            !stableford && 'flex items-center',
            !(!isTablet && !isDesktop && stableford) && '[&:not(:last-child)]:mr-[24px]',
            !stableford && '[&:not(:last-child)]:mr-[14px]',
          )}
          key={index}
        >
          {group.map((key: string, groupIndex) => (
            <div className={twMerge(groupIndex > 0 && 'mt-[10px]')} key={key}>
              {noteItem(key, noteList[key])}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ScoreNotes;
