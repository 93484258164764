import {
  IGDRDrivingClubResponse,
  IGDRMiniRoundResponse,
  IGsNasmoClubResponse,
  ITmApproachResponse,
  ITmDrivingRangeResponse,
  ITmPuttingResponse,
  ITmSwingVideoInfoResponse,
} from 'src/requests/api/record/prop-state.type';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import {
  GS_ROUND,
  GDR_DRIVING,
  GDR_PRACTICE,
  RESET_RECORD,
  RECORS_OFFSET,
  GS_SWING_VIDEO,
  TM_DRIVING_RANGE,
  TM_APPROACH,
  TM_PUTTING,
  TM_SWING_VIDEO,
} from 'src/store/records/action';
import { IRecordScore } from 'src/models';
import { IVideoDetail } from 'src/view/pages/record/commons/VideoRegion';
import dayjs from 'dayjs';

export interface IOffsetListRecord {
  driving: number;
  practice: number;
  round: number;
  gsSwingVideo: number;
  tmDrivingRange: number;
  tmApproach: number;
  tmPutting: number;
  tmSwingVideo: number;
}

export interface IGsSwingVideoValues {
  data: IGsNasmoClubResponse[];
  videoDetail?: IVideoDetail | null;
}

export interface ITmValues<T> {
  data: T;
  months: dayjs.Dayjs[];
  date?: dayjs.Dayjs;
  loading: boolean;
  loaded: boolean;
}

export interface ITmSwingVideosValues {
  data: ITmSwingVideosData[];
  videoDetail?: ITmSwingVideoInfoResponse;
  loaded: number;
  lastPage: boolean;
  loading: boolean;
}

export interface ITmSwingVideosData {
  tsCode: number;
  swingVideos: ITmSwingVideoInfoResponse[];
}

export interface RecordsInfoState {
  offsetListRecord: IOffsetListRecord;
  dataGsRecordScore: IObjectData<Record<string, IRecordScore[]>>;
  dataGsSwingVideo: IObjectData<IGsSwingVideoValues>;
  dataGdrDriving: IObjectData<Record<string, IGDRDrivingClubResponse[]>>;
  dataGdrPractice: IObjectData<Record<string, IGDRMiniRoundResponse[]>>;
  dataTmDrivingRange: ITmValues<ITmDrivingRangeResponse[]>;
  dataTmApproach: ITmValues<ITmApproachResponse[]>;
  dataTmPutting: ITmValues<ITmPuttingResponse[]>;
  dataTmSwingVideo: ITmSwingVideosValues;
}

export const initialState: RecordsInfoState = {
  offsetListRecord: {
    driving: 0,
    practice: 0,
    round: 0,
    gsSwingVideo: 0,
    tmDrivingRange: 0,
    tmApproach: 0,
    tmPutting: 0,
    tmSwingVideo: 0,
  },
  dataGsRecordScore: {
    keySort: [],
    values: {},
    length: 0,
    loaded: 0,
    lastPage: false,
    loading: false,
  },
  dataGsSwingVideo: {
    keySort: [],
    values: {
      data: [],
      videoDetail: null,
    },
    length: 0,
    loaded: 0,
    lastPage: false,
    loading: false,
  },
  dataGdrDriving: {
    keySort: [],
    values: {},
    length: 0,
    loaded: 0,
    lastPage: false,
    loading: false,
  },
  dataGdrPractice: {
    keySort: [],
    values: {},
    length: 0,
    loaded: 0,
    lastPage: false,
    loading: false,
  },
  dataTmDrivingRange: {
    data: [],
    months: [],
    date: undefined,
    loading: false,
    loaded: false,
  },
  dataTmApproach: {
    data: [],
    months: [],
    date: undefined,
    loading: false,
    loaded: false,
  },
  dataTmPutting: {
    data: [],
    months: [],
    date: undefined,
    loading: false,
    loaded: false,
  },
  dataTmSwingVideo: {
    data: [],
    videoDetail: undefined,
    loaded: 0,
    lastPage: false,
    loading: false,
  },
};

export function recordsReducer(state: RecordsInfoState = initialState, action: any) {
  switch (action.type) {
    case RECORS_OFFSET:
      return {
        ...state,
        offsetListRecord: action.payload.offsetListRecord,
      };
    case RESET_RECORD:
      return initialState;
    case GS_ROUND:
      return {
        ...state,
        dataGsRecordScore: action.payload.dataGsRecordScore,
      };
    case GS_SWING_VIDEO:
      return {
        ...state,
        dataGsSwingVideo: action.payload.dataGsSwingVideo,
      };
    case GDR_DRIVING:
      return {
        ...state,
        dataGdrDriving: action.payload.dataGdrDriving,
      };
    case GDR_PRACTICE:
      return {
        ...state,
        dataGdrPractice: action.payload.dataGdrPractice,
      };
    case TM_DRIVING_RANGE:
      return {
        ...state,
        dataTmDrivingRange: action.payload,
      };
    case TM_APPROACH:
      return {
        ...state,
        dataTmApproach: action.payload,
      };
    case TM_PUTTING:
      return {
        ...state,
        dataTmPutting: action.payload,
      };
    case TM_SWING_VIDEO:
      return {
        ...state,
        dataTmSwingVideo: action.payload,
      };
    default:
      return state;
  }
}
