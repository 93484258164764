import i18n from 'src/locale';
import { IStoreDetail } from 'src/models';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { findStoreRequests } from 'src/requests/api/store-news/find-store';
import {
  EnumRequestCode,
  EnumResultTypeStoreMap,
  EnumSortTypeStoreMap,
  EnumValueBoolean,
  pageSize,
} from 'src/constants/enum';
import {
  ISearchStoreGetAutoFillParams,
  ISearchStoreSuggestionHistory,
} from 'src/requests/api/store-news/prop-state.type';
import { history } from 'src/services/history';
import { pathConstants } from 'src/constants/const';
import { IAutoFillState, IFilteringStoresState, IMapLocation } from 'src/store/store-map/type';
import { DEFAULT_DISTANCE_AWAY, INIT_DISTANCE_AWAY } from 'src/constants/const/store-map.constants';
import cloneDeep from 'lodash/cloneDeep';

export const STORE_NEWS_ACTION_TYPES = {
  STORE_MAP_UPDATE_SEARCHING_STORE_LIST: 'STORE_MAP_UPDATE_SEARCHING_STORE_LIST',
  STORE_MAP_SET_SHOW_MAP: 'STORE_MAP_SET_SHOW_MAP',
  STORE_MAP_UPDATE_SUGGESTION_HISTORY: 'STORE_MAP_UPDATE_SUGGESTION_HISTORY',
  STORE_MAP_UPDATE_FILTERING_STORES: 'STORE_MAP_UPDATE_FILTERING_STORES',
  STORE_MAP_UPDATE_AUTO_FILL: 'STORE_MAP_UPDATE_AUTO_FILL',
  STORE_MAP_UPDATE_MAP_INFO: 'STORE_MAP_UPDATE_MAP_INFO',
  STORE_MAP_UPDATE_PAGE_OFFSET: 'STORE_MAP_UPDATE_PAGE_OFFSET',
  RESET_MAP: 'RESET_MAP',
  SET_SHOW_PERMISSION_ALERT: 'SET_SHOW_PERMISSION_ALERT',
};

export interface ISearchingStoresParams {
  data?: IStoreDetail[];
  total?: number;
  page?: number;
  loading?: boolean;
  loadingCurrentMap?: boolean;
  firstLoaded?: boolean;
}

export interface ISuggestionHistoryParams {
  data?: ISearchStoreSuggestionHistory[];
  loading?: boolean;
  loaded?: boolean;
}

export interface IFilteringStoresStateParams {
  versions?: string[];
  facility?: string[];
  anyDistance?: boolean;
  distance?: number;
  latitude?: number;
  longitude?: number;
  countryCode?: string;
  regionCode?: string;
  sortType?: EnumSortTypeStoreMap;
  latitudeSouthwest?: number;
  longitudeSouthwest?: number;
  latitudeNortheast?: number;
  longitudeNortheast?: number;
}

export interface IAutoFillParams {
  searchText?: string;
  data?: ISearchStoreSuggestionHistory[];
  loading?: boolean;
  loadingMore?: boolean;
  loaded?: number;
  hasNext?: boolean;
}

export interface IMapInfoParams {
  title?: string;
  focusedStore?: IStoreDetail;
  hideFocusedStore?: boolean;
  firstLoad?: boolean;
  zoom?: number;
  center?: IMapLocation;
  address?: string;
  lockedMove?: boolean;
  nearby?: boolean;
  loadOnCurrentMapBtn?: boolean;
}

export const storeMapActions = {
  updateSearchStoreList: (searchStore: ISearchingStoresParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_SEARCHING_STORE_LIST,
    payload: searchStore,
  }),
  setShowMap: (showMap: boolean = true, isSearching: boolean = true) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_SET_SHOW_MAP,
    payload: {
      showMap,
      isSearching,
    },
  }),
  updateSuggestionHistory: (payload: ISuggestionHistoryParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_SUGGESTION_HISTORY,
    payload,
  }),
  updateFilteringStores: (payload: IFilteringStoresStateParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_FILTERING_STORES,
    payload,
  }),
  updateAutoFill: (payload: IAutoFillParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_AUTO_FILL,
    payload,
  }),
  updateMapInfo: (payload: IMapInfoParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_MAP_INFO,
    payload,
  }),
  updatePageOffset: (payload: number) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_PAGE_OFFSET,
    payload,
  }),
  resetMap: () => ({
    type: STORE_NEWS_ACTION_TYPES.RESET_MAP,
  }),
  setShowPermissionAlert: (showAlert: boolean) => ({
    type: STORE_NEWS_ACTION_TYPES.SET_SHOW_PERMISSION_ALERT,
    payload: {
      showAlert,
    },
  }),
};

let lastRequestIndex = 0;
const getSearchStoreList = (params?: any) => async (dispatch: any, getState: any) => {
  const filteringStoresState: IFilteringStoresState = getState().storeMapReducer.filteringStores;
  const payload: ISearchingStoresParams = {
    data: [],
    page: 1,
    total: 0,
    loading: false,
    loadingCurrentMap: false,
    firstLoaded: false,
  };
  const currentLastRequestIndex = ++lastRequestIndex;

  try {
    const filtering: any = { ...filteringStoresState };
    if (filtering.anyDistance && filtering.distance !== INIT_DISTANCE_AWAY) {
      delete filtering.distance;
    } else if (filtering.distance === INIT_DISTANCE_AWAY && filtering.countryCode) {
      delete filtering.distance;
    } else {
      delete filtering.latitudeSouthwest;
      delete filtering.longitudeSouthwest;
      delete filtering.latitudeNortheast;
      delete filtering.longitudeNortheast;
    }
    delete filtering.anyDistance;
    if (filtering.versions.includes('S')) {
      filtering.isSocial = true;
      filtering.versions = filtering.versions.filter((version: string) => version !== 'S');
    }
    const response = await findStoreRequests.getSearchStoreList({
      page: 1,
      rows: pageSize.BIG_SIZE,
      isAllData: 'true',
      ...filtering,
      ...params,
    });
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get search store list');
    payload.data = response.entities ?? [];
    payload.total = response.totalCount ?? 0;
  } catch {
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  } finally {
    if (currentLastRequestIndex === lastRequestIndex) {
      dispatch(storeMapActions.updateSearchStoreList(payload));
    }
  }
};

const getSuggestionHistory = () => async (dispatch: any, getState: any) => {
  const isLoggedIn = getState().authentication.isLoggedIn;

  const currentLastRequestIndex = ++lastRequestIndex;
  const payload: ISuggestionHistoryParams = {
    data: [],
    loading: false,
    loaded: true,
  };
  try {
    if (!isLoggedIn) return;
    const response = await findStoreRequests.getSuggestionHistory();
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get suggestion history');
    payload.data = response.entities ?? [];
  } catch {
  } finally {
    if (currentLastRequestIndex === lastRequestIndex) {
      dispatch(storeMapActions.updateSuggestionHistory(payload));
    }
  }
};

const clickSuggestion =
  (suggestion: ISearchStoreSuggestionHistory, embedded: boolean) => async (dispatch: any, getState: any) => {
    const isLoggedIn = getState().authentication.isLoggedIn;
    const suggestionHistoryList: ISearchStoreSuggestionHistory[] =
      getState().storeMapReducer.suggestionHistory.data ?? [];
    if (isLoggedIn) {
      try {
        findStoreRequests.saveSuggestionHistory(suggestion);
        const newSuggestionHistory = [
          suggestion,
          ...suggestionHistoryList.filter(
            (suggestionHistory) =>
              !(
                suggestionHistory.rgnNo === suggestion.rgnNo &&
                suggestionHistory.rgnCd === suggestion.rgnCd &&
                suggestionHistory.countryCode === suggestion.countryCode
              ),
          ),
        ];
        dispatch(
          storeMapActions.updateSuggestionHistory({
            data: newSuggestionHistory.splice(0, 5),
          }),
        );
      } catch {
        // do nothing, only save
      }
    }

    if ([EnumResultTypeStoreMap.OTHER, EnumResultTypeStoreMap.LOCATION].includes(suggestion.typeResult)) {
      history.push(
        !embedded
          ? pathConstants.STORE_DETAIL(suggestion.rgnNo?.toString())
          : pathConstants.EMBEDDED_STORE_DETAIL(suggestion.rgnNo?.toString()),
      );
      return;
    }
    const params: any = {};
    if (suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY) {
      params.countryCode = suggestion.countryCode;
    } else {
      params.subCode = suggestion.rgnCd;
    }
    dispatch(
      storeMapActions.updateFilteringStores({
        anyDistance: true,
        distance: DEFAULT_DISTANCE_AWAY,
        latitudeNortheast: undefined,
        latitudeSouthwest: undefined,
        longitudeNortheast: undefined,
        longitudeSouthwest: undefined,
      }),
    );
    dispatch(
      storeMapActions.updateSearchStoreList({
        loading: true,
        data: [],
        total: 0,
        page: 1,
      }),
    );
    dispatch(getSearchStoreList(params));
    dispatch(storeMapActions.setShowMap(true));
    dispatch(
      storeMapActions.updateMapInfo({
        lockedMove: true,
        title: suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY ? suggestion.countryNm : suggestion.comNm,
      }),
    );
    setTimeout(() => {
      dispatch(
        storeMapActions.updateMapInfo({
          address: suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY ? suggestion.countryNm : suggestion.comNm,
        }),
      );
    }, 500);
  };

const getAutoFill = (searchText: string) => async (dispatch: any) => {
  const payload: IAutoFillParams = {
    data: [],
    loading: false,
    loaded: 1,
    hasNext: false,
  };
  try {
    dispatch(
      storeMapActions.updateAutoFill({
        loading: true,
        searchText,
      }),
    );
    if (!searchText?.trim()) return;
    const params: ISearchStoreGetAutoFillParams = {
      searchName: searchText?.trim(),
      page: 1,
      rows: pageSize.MEDIUM,
    };
    const response = await findStoreRequests.getAutoFill(params);
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get auto fill');
    payload.data = response.entities ?? [];
    payload.hasNext =
      !!response.entities?.length &&
      response.entities?.length === params.rows &&
      payload.data &&
      payload.data?.length < response.totalCount;
  } catch {
  } finally {
    dispatch(storeMapActions.updateAutoFill(payload));
  }
};

const loadMoreAutoFill = () => async (dispatch: any, getState: any) => {
  const autoFill: IAutoFillState = getState().storeMapReducer.autoFill;
  const payload: IAutoFillParams = {
    loadingMore: false,
    loaded: autoFill.loaded + 1,
    hasNext: false,
  };
  try {
    dispatch(
      storeMapActions.updateAutoFill({
        loadingMore: true,
      }),
    );
    const params: ISearchStoreGetAutoFillParams = {
      searchName: autoFill.searchText,
      page: autoFill.loaded + 1,
      rows: pageSize.MEDIUM,
    };
    const response = await findStoreRequests.getAutoFill(params);
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get auto fill');
    payload.data = [...autoFill.data, ...response.entities];
    payload.hasNext =
      !!response.entities?.length &&
      response.entities?.length === params.rows &&
      payload.data &&
      payload.data?.length < response.totalCount;
  } catch {
  } finally {
    dispatch(storeMapActions.updateAutoFill(payload));
  }
};

const updateInterestStore = (rgnNo: number, interestYn: EnumValueBoolean) => async (dispatch: any, getState: any) => {
  const focusedStore: IStoreDetail | undefined = getState().storeMapReducer.mapInfo.focusedStore;
  const searchingStores: IStoreDetail[] = cloneDeep(getState().storeMapReducer.searchingStores.data);

  if (focusedStore && focusedStore.rgnNo === rgnNo) {
    dispatch(
      storeMapActions.updateMapInfo({
        focusedStore: {
          ...focusedStore,
          interestYn,
        },
      }),
    );
  }
  dispatch(
    storeMapActions.updateSearchStoreList({
      data: searchingStores.map((store) => {
        if (store.rgnNo === rgnNo)
          return {
            ...store,
            interestYn,
          };
        return store;
      }),
    }),
  );
};

export const storeMapMiddleware = {
  getSearchStoreList,
  getSuggestionHistory,
  clickSuggestion,
  getAutoFill,
  loadMoreAutoFill,
  updateInterestStore,
};
