import _ from 'lodash';
import { MAX_SMALL_HOLE_COUNT, scorecardConstants, scoreCardWidths, scoreTypeColor } from 'src/constants/const';
import {
  EnumTypeScoreCard,
  EnumScoreCardMatchCode,
  EnumScoreCardMatch,
  EnumPrefixKey,
  EnumTypeStablefordScoreCard,
} from 'src/constants/enum';
import { bestBallMatchData, bestBallMatchHeader } from 'src/services/scorecard-modes/best-ball-match';
import { bestBallStrokeData, bestBallStrokeHeader } from 'src/services/scorecard-modes/best-ball-stroke';
import { bestShotMatchData, bestShotMatchHeader } from 'src/services/scorecard-modes/best-shot-match';
import { bestShotStrokeData, bestShotStrokeHeader } from 'src/services/scorecard-modes/best-shot-stroke';
import { foursomesHeader, foursomesData } from 'src/services/scorecard-modes/foursomes';
import { lasVegasData, myLasVegasHeader, otherLasVegasHeader } from 'src/services/scorecard-modes/las-vegas';
import { matchData, matchHeader } from 'src/services/scorecard-modes/match';
import { newPerioData, newPerioHeader } from 'src/services/scorecard-modes/new-perio';
import { skinsData, skinsHeader } from 'src/services/scorecard-modes/skins';
import { stablefordData, stablefordHeader } from 'src/services/scorecard-modes/stableford';
import { strokeData, strokeHeader } from 'src/services/scorecard-modes/stroke';
import { twMerge } from 'tailwind-merge';
interface ICustomColumn {
  title: string;
  key: string;
  className?: string; // When define w-[Xpx] in "className" field, please define width = X too
  width?: number;
  rowSpan?: number;
  colSpan?: number;
  fixed?: boolean;
}
interface IScoreCardHeader {
  customColumns: ICustomColumn[];
  totalHoleCnt: number;
  prefixHoleKey: EnumPrefixKey;
  hiddenHoleList?: number[];
  color: string;
}

interface IGetMatchValueOptions {
  isBaseScore?: boolean;
  isCurrentUser?: boolean;
  currentUserScore?: number;
  hasSameScore?: boolean;
}

export const customHeader = {
  TEAM: (color: string) => {
    return {
      title: '',
      key: scorecardConstants.TEAM_KEY,
      className: twMerge(`w-[${scoreCardWidths.TEAM_WIDTH}%]`, color),
      width: scoreCardWidths.TEAM_WIDTH,
      fixed: true,
    };
  },
  HOLE: (color: string, initialWidth?: number) => {
    const width = initialWidth ?? scoreCardWidths.HOLE_WIDTH;
    return {
      title: scorecardConstants.HOLE_HEADER,
      key: scorecardConstants.HOLE_KEY,
      className: twMerge(`w-[${width}%] pl-[8px]`, color),
      width,
      fixed: true,
    };
  },
  BASE_SCORE: (color: string, totalHoleCnt?: number, initialWidth?: number) => {
    const width =
      initialWidth ?? totalHoleCnt === MAX_SMALL_HOLE_COUNT
        ? scoreCardWidths.DEFAULT_WIDTH
        : scoreCardWidths.DEFAULT_2X_WIDTH;
    return {
      title: scorecardConstants.BASE_SCORE_HEADER,
      key: scorecardConstants.BASE_SCORE_KEY,
      className: twMerge('text-center', color, `w-[${width}%]`),
      width,
    };
  },
};

export const generateTableHeaders = (params: IScoreCardHeader) => {
  let headers: ICustomColumn[][] = [];
  const beforeColumns = params.customColumns.filter((column) => column.fixed);
  const holeColumns: ICustomColumn[] = [...Array(params.totalHoleCnt)]
    .map((_, index: number) => {
      return {
        title: `${index + 1}`,
        key: `${params.prefixHoleKey}${index + 1}`,
        className: twMerge(`w-[${scoreCardWidths.DEFAULT_WIDTH}%] text-center`, params.color),
        width: scoreCardWidths.DEFAULT_WIDTH,
      };
    })
    .filter((_, index: number) => !params.hiddenHoleList || !params.hiddenHoleList.includes(index + 1));
  let header = [...beforeColumns];
  const beforeColumnsTotalWidth = beforeColumns
    .map((column) => column.width ?? scoreCardWidths.DEFAULT_WIDTH)
    .reduce((prev, current) => prev + current, 0);
  let currentTotalWidth = beforeColumnsTotalWidth;
  const afterColumns = params.customColumns.filter((column) => !column.fixed);
  const allColumns = [...holeColumns, ...afterColumns];
  for (let index = 0; index < allColumns.length; index++) {
    const columnWidth = allColumns[index].width ?? scoreCardWidths.DEFAULT_WIDTH;
    if (currentTotalWidth + columnWidth <= scoreCardWidths.MAX_WIDTH && index < allColumns.length - 1) {
      header = [...header, allColumns[index]];
      currentTotalWidth += columnWidth;
    } else if (currentTotalWidth + columnWidth <= scoreCardWidths.MAX_WIDTH && index === allColumns.length - 1) {
      header = [...header, allColumns[index]];
      headers = [...headers, header];
    } else if (index < allColumns.length - 1) {
      headers = [...headers, header];
      header = [...beforeColumns, allColumns[index]];
      currentTotalWidth = beforeColumnsTotalWidth + columnWidth;
    } else {
      headers = [...headers, header];
      header = [...beforeColumns, allColumns[index]];
      headers = [...headers, header];
    }
  }
  return headers;
};

export const getBgColor = (value: number, parValue: number, hiddenScore = scorecardConstants.HIDDEN_SCORE) => {
  if (value === hiddenScore) return 'bg-bg';
  const diffValue = value - parValue;
  if (diffValue <= EnumTypeScoreCard.EAGLE) {
    return scoreTypeColor.EAGLE;
  } else if (diffValue === EnumTypeScoreCard.BIRDIE) {
    return scoreTypeColor.BIRDIE;
  } else if (diffValue === EnumTypeScoreCard.BOGEY) {
    return scoreTypeColor.BOGEY;
  } else if (diffValue >= EnumTypeScoreCard.DOUBLE_BOGEY) {
    return scoreTypeColor.DOUBLE_BOGEY;
  }
  return '';
};

export const getStablefordBgColor = (value: number, hiddenScore = scorecardConstants.HIDDEN_SCORE) => {
  if (value === hiddenScore) return 'bg-bg';
  if (value === EnumTypeStablefordScoreCard.ALBATROSS) {
    return scoreTypeColor.ALBATROSS;
  } else if (value === EnumTypeStablefordScoreCard.EAGLE) {
    return scoreTypeColor.EAGLE;
  } else if (value === EnumTypeStablefordScoreCard.BIRDIE) {
    return scoreTypeColor.BIRDIE;
  } else if (value === EnumTypeStablefordScoreCard.PAR) {
    return scoreTypeColor.PAR;
  } else if (value === EnumTypeStablefordScoreCard.BOGEY) {
    return scoreTypeColor.BOGEY;
  } else if (value === EnumTypeStablefordScoreCard.DOUBLE_BOGEY_AND_BELOW) {
    return scoreTypeColor.DOUBLE_BOGEY;
  }
  return '';
};

export const getHoleScore = (value: number | string, hiddenScore = scorecardConstants.HIDDEN_SCORE) => {
  if (Number(value) === hiddenScore) return scorecardConstants.ALTERNATIVE_HIDDEN_SCORE;
  return value;
};

export const getMatchValue = (matchTotal: number | string, options?: IGetMatchValueOptions) => {
  const value = Number(matchTotal);
  if (options?.isBaseScore && matchTotal == null) {
    return scorecardConstants.ALTERNATIVE_HIDDEN_SCORE;
  } else if (
    options?.isBaseScore &&
    ((!options?.isCurrentUser && value === options?.currentUserScore) ||
      (options?.isCurrentUser && options?.hasSameScore))
  ) {
    return EnumScoreCardMatch.AS;
  } else if (options?.isBaseScore) {
    return `${Math.abs(value)} ${value < 0 ? EnumScoreCardMatch.DOWN : EnumScoreCardMatch.UP}`;
  }
  if (value === EnumScoreCardMatchCode.DRAW) return scorecardConstants.ALTERNATIVE_ZERO_MATCH;
  if (value === EnumScoreCardMatchCode.DOWN) return EnumScoreCardMatch.DOWN;
  if (value === EnumScoreCardMatchCode.UP) return EnumScoreCardMatch.UP;
  return scorecardConstants.ALTERNATIVE_HIDDEN_SCORE;
};

export const getMatchTextColor = (value: number) => {
  if (value === EnumScoreCardMatchCode.DOWN) return 'text-primary-75';
  if (value === EnumScoreCardMatchCode.UP) return 'text-gz-danger';
  return 'bg-bg';
};

export const getTeamName = (code: string) => {
  const teamCodeMap = {
    '1': {
      text: 'text-gz-danger',
      style: 'text-gz-white bg-gz-danger',
      image: 'ring-gz-danger',
      name: 'A',
    },
    '2': {
      text: 'text-primary-75',
      style: 'text-gz-white bg-primary-75',
      image: 'ring-primary-75',
      name: 'B',
    },
    '3': {
      text: 'text-orange-40',
      style: 'text-gz-white bg-orange-40',
      image: 'ring-orange-40',
      name: 'C',
    },
  };
  if (teamCodeMap[code] == null) return teamCodeMap['1'];
  return teamCodeMap[code];
};

/*
  Sorting team scorecard list by team and keeping the order of team 
*/
export const rearrangeTeamScorecardList = (data: any[]) => {
  const newData = [];
  let beforeArray = [...data];
  while (beforeArray.length > 0) {
    const firstItemTeam = beforeArray[0].team;
    const [pass, fail] = _.partition(beforeArray, (el) => el.team === firstItemTeam);
    beforeArray = fail;
    newData.push(...pass);
  }
  return newData;
};

export const scorecard = {
  strokeHeader,
  strokeData,
  newPerioHeader,
  newPerioData,
  stablefordHeader,
  stablefordData,
  skinsHeader,
  skinsData,
  matchHeader,
  matchData,
  myLasVegasHeader,
  otherLasVegasHeader,
  lasVegasData,
  foursomesHeader,
  foursomesData,
  bestShotStrokeHeader,
  bestShotStrokeData,
  bestShotMatchHeader,
  bestShotMatchData,
  bestBallStrokeHeader,
  bestBallStrokeData,
  bestBallMatchHeader,
  bestBallMatchData,
};
