import { Fragment, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { pathConstants } from 'src/constants/const';
import { IComponentRouteGroup } from 'src/models/route';
import GlobalLayout from 'src/view/layouts/global/Layout';
import GuestLayout from 'src/view/layouts/guest/Layout';
import LinkToApp from 'src/view/pages/link-to-app';
import RecordListHeader from 'src/view/pages/record/commons/Header';
import RecordGsRound from 'src/view/pages/record/about/GS/list-score';
import RecordGsSwingVideo from 'src/view/pages/record/about/GS/swing-video';
import RecordGdrDrivingRange from 'src/view/pages/record/about/GDR/driving-range';
import RecordGdrPracticeRound from 'src/view/pages/record/about/GDR/practice-round';
import RecordGdrSwingVideo from 'src/view/pages/record/about/GDR/swing-video';
import RecordTrainingModeDrivingRange from 'src/view/pages/record/about/training-mode/driving-range';
import RecordTrainingModeApproach from 'src/view/pages/record/about/training-mode/approach';
import RecordTrainingModePutting from 'src/view/pages/record/about/training-mode/putting';
import RecordTrainingModeSwingVideo from 'src/view/pages/record/about/training-mode/swing-video';
import { EnumPolicyType, EnumTypeTableRanking } from 'src/constants/enum';
import GuestRecord from 'src/view/pages/record/commons/GuestRecord';
import LeaderBoardTourNament from 'src/view/pages/leader-board';
import FindPassword from 'src/view/pages/account/find-password';
import EmbeddedLayout from 'src/view/layouts/embedded/Layout';
import RankingDetailPage from 'src/view/pages/profile/ranking-detail';
import EventHomeHeader from 'src/view/pages/events/components/HomeHeader';

const HomePage = lazy(() => import('src/view/pages/home'));
const AboutCoursePage = lazy(() => import('src/view/pages/course/about'));
const UpdatePhoneNumber = lazy(() => import('src/view/pages/account/login/MappingPhoneNumber'));
const GuidelineConfig = lazy(() => import('src/view/pages/setting/guideline'));
const CourseDetail = lazy(() => import('src/view/pages/course/detail'));
const SettingPage = lazy(() => import('src/view/pages/setting/about'));
const ChangeCountry = lazy(() => import('src/view/pages/setting/country'));
const DistanceSetting = lazy(() => import('src/view/pages/setting/distance'));
const FAQSetting = lazy(() => import('src/view/pages/setting/faq'));
const AlarmListSetting = lazy(() => import('src/view/pages/setting/alarm'));
const AlarmList = lazy(() => import('src/view/pages/home/alarm-list'));
const StoreFeedPage = lazy(() => import('src/view/pages/store-news/detail/list-feed'));
const StoreFeedDetailPage = lazy(() => import('src/view/pages/feed/store-feed-detail'));
const RecentFeed = lazy(() => import('src/view/pages/feed/about/RecentFeed'));
const FavouriteStoreFeed = lazy(() => import('src/view/pages/feed/about/FavouriteStoreFeed'));
const LeaguesPage = lazy(() => import('src/view/pages/events/leagues/home'));
const TournamentsPage = lazy(() => import('src/view/pages/events/tournaments/home'));
const TournamentDetailPage = lazy(() => import('src/view/pages/events/tournaments/detail'));
const StoresPage = lazy(() => import('src/view/pages/store-news/about'));
const StoreDetailPage = lazy(() => import('src/view/pages/store-news/detail'));
const StoresMap = lazy(() => import('src/view/pages/store-news/map'));
const EmbeddedStoreDetail = lazy(() => import('src/view/pages/embedded/store-detail'));
const EmbeddedStoresMap = lazy(() => import('src/view/pages/embedded/stores-map'));
const TermsOfUsePage = lazy(() => import('src/view/pages/setting/terms-of-use'));
const PrivacyPolicyPage = lazy(() => import('src/view/pages/setting/privacy-policy'));
const MarketingPolicyPage = lazy(() => import('src/view/pages/setting/marketing-policy'));
const NewsList = lazy(() => import('src/view/pages/news'));
const NewsDetail = lazy(() => import('src/view/pages/news/detail'));
const NotFound = lazy(() => import('src/view/pages/not-found'));
const Roulette = lazy(() => import('src/view/pages/roulette'));

export const globalRoutesGroup: IComponentRouteGroup[] = [
  {
    layout: <GlobalLayout />,
    components: [
      { path: pathConstants.HOME_PAGE, component: <HomePage /> },
      { path: pathConstants.FEED_PAGE, component: <RecentFeed /> },
      { path: pathConstants.FEED_FAVORITE, component: <FavouriteStoreFeed /> },
    ],
  },
  {
    layout: <GlobalLayout showMobileSidebar={false} />,
    components: [
      { path: pathConstants.ALARM_LIST, component: <AlarmList /> },
      { path: pathConstants.ABOUT_COURSE, component: <AboutCoursePage /> },
      { path: pathConstants.COURSE_DETAIL(), component: <CourseDetail /> },
      { path: pathConstants.STORE_DETAIL(), component: <StoreDetailPage /> },
      { path: pathConstants.STORES_MAP, component: <StoresMap /> },
      { path: pathConstants.STORE_FEED(), component: <StoreFeedPage /> },
      { path: pathConstants.STORE_FEED_DETAIL(), component: <StoreFeedDetailPage /> },
      { path: pathConstants.FEED_DETAIL(), component: <StoreFeedDetailPage /> },
      { path: pathConstants.TOURNAMENT_DETAIL(), component: <TournamentDetailPage /> },
      { path: pathConstants.SETTING, component: <SettingPage /> },
      //Todo rollback
      // {
      //   path: pathConstants.PROFILE_RANKING_STORE_DETAIL(),
      //   component: <RankingDetailPage type={EnumTypeTableRanking.STORE} />,
      // },
      // {
      //   path: pathConstants.PROFILE_RANKING_GLOBAL_DETAIL(),
      //   component: <RankingDetailPage type={EnumTypeTableRanking.GLOBAL} />,
      // },
      // {
      //   path: pathConstants.PROFILE_RANKING_COUNTRY_DETAIL(),
      //   component: <RankingDetailPage type={EnumTypeTableRanking.COUNTRY} />,
      // },
      // {
      //   path: pathConstants.PROFILE_RANKING_CITY_DETAIL(),
      //   component: <RankingDetailPage type={EnumTypeTableRanking.SUB_COUNTRY} />,
      // },
      // {
      //   path: pathConstants.PROFILE_RANKING_LEVEL_DETAIL(),
      //   component: <RankingDetailPage type={EnumTypeTableRanking.LEVEL} />,
      // },
      { path: pathConstants.SETTING_CHANGE_COUNTRY, component: <ChangeCountry /> },
      { path: pathConstants.SETTING_DISTANCE, component: <DistanceSetting /> },
      { path: pathConstants.SETTING_ALARM_LIST, component: <AlarmListSetting /> },
      { path: pathConstants.SETTING_FAQ, component: <FAQSetting /> },
      { path: pathConstants.GUIDELINE_CONFIG, component: <GuidelineConfig /> },
      { path: pathConstants.NEWS, component: <NewsList /> },
      { path: pathConstants.NEWS_DETAIL(), component: <NewsDetail /> },
      { path: pathConstants.PRIVACY_POLICY, component: <PrivacyPolicyPage version={EnumPolicyType.CURRENT} /> },
      {
        path: pathConstants.PRIVACY_POLICY_FIRST,
        component: <PrivacyPolicyPage version={EnumPolicyType.FIRST} />,
      },
      { path: pathConstants.TERMS_OF_USE, component: <TermsOfUsePage /> },
      { path: pathConstants.MARKETING_POLICY, component: <MarketingPolicyPage /> },
    ],
  },
  {
    layout: <GlobalLayout />,
    header: <RecordListHeader />,
    components: [
      { path: pathConstants.RECORDS_GS_ROUND, authComponent: <RecordGsRound />, guestComponent: <GuestRecord /> },
      { path: pathConstants.RECORDS_GS_SWING, authComponent: <RecordGsSwingVideo />, guestComponent: <GuestRecord /> },
      { path: pathConstants.RECORDS_GDR, authComponent: <RecordGdrDrivingRange />, guestComponent: <GuestRecord /> },
      {
        path: pathConstants.RECORDS_GDR_PRACTICE,
        authComponent: <RecordGdrPracticeRound />,
        guestComponent: <GuestRecord />,
      },
      {
        path: pathConstants.RECORDS_GDR_SWING,
        authComponent: <RecordGdrSwingVideo />,
        guestComponent: <GuestRecord />,
      },
      {
        path: pathConstants.RECORDS_TRAINING_MODE,
        authComponent: <RecordTrainingModeDrivingRange />,
        guestComponent: <GuestRecord />,
      },
      {
        path: pathConstants.RECORDS_TRAINING_MODE_APPROACH,
        authComponent: <RecordTrainingModeApproach />,
        guestComponent: <GuestRecord />,
      },
      {
        path: pathConstants.RECORDS_TRAINING_MODE_PUTTING,
        authComponent: <RecordTrainingModePutting />,
        guestComponent: <GuestRecord />,
      },
      {
        path: pathConstants.RECORDS_TRAINING_MODE_SWING,
        authComponent: <RecordTrainingModeSwingVideo />,
        guestComponent: <GuestRecord />,
      },
    ],
  },
  {
    layout: <GlobalLayout showFooter />,
    components: [{ path: pathConstants.STORE_PAGE, component: <StoresPage /> }],
  },
  {
    layout: <GlobalLayout showFooter />,
    header: <EventHomeHeader />,
    components: [
      { path: pathConstants.LEAGUE_PAGE, component: <LeaguesPage /> },
      { path: pathConstants.TOURNAMENT_PAGE, component: <TournamentsPage /> },
    ],
  },
  {
    layout: <GuestLayout />,
    components: [
      { path: pathConstants.UPDATE_NUMBER, component: <UpdatePhoneNumber /> },
      { path: pathConstants.FIND_PASSWORD, component: <FindPassword /> },
    ],
  },
  {
    layout: <EmbeddedLayout />,
    components: [
      { path: pathConstants.EMBEDDED_STORES_MAP, component: <EmbeddedStoresMap /> },
      { path: pathConstants.EMBEDDED_STORE_DETAIL(), component: <EmbeddedStoreDetail /> },
    ],
  },
  {
    layout: <Fragment />,
    components: [
      { path: pathConstants.LINK_TO_APP, component: <LinkToApp /> },
      { path: pathConstants.PAGE_NOT_FOUND, component: <NotFound /> },
      { path: pathConstants.LEADER_BOARD, component: <LeaderBoardTourNament /> },
      { path: pathConstants.ROULETTE, component: <Roulette /> },
      { path: '*', component: <Navigate to={pathConstants.PAGE_NOT_FOUND} replace /> },
    ],
  },
];
