import { MAX_STANDARD_HOLE_COUNT, scorecardConstants, scoreCardWidths } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import { generateTableHeaders, getBgColor, getHoleScore } from 'src/services/scorecard-modes';
import { twMerge } from 'tailwind-merge';

export const newPerioHeader = (
  normalColor: string,
  customColor: string,
  totalHoleCnt: number,
  hiddenHoleList: number[],
) => {
  return generateTableHeaders({
    customColumns: [
      {
        title: scorecardConstants.HIDDEN_HOLE_HEADER,
        key: scorecardConstants.HOLE_KEY,
        className: twMerge(`w-[${scoreCardWidths.HOLE_WIDTH}%] pl-[8px] gz-text-tiny`, normalColor),
        width: scoreCardWidths.HOLE_WIDTH,
        fixed: true,
      },
      {
        title: scorecardConstants.TOTAL_HEADER,
        key: scorecardConstants.TOTAL_KEY,
        className: twMerge(`text-center w-[${scoreCardWidths.DEFAULT_2X_WIDTH}%]`, normalColor),
        width: scoreCardWidths.DEFAULT_2X_WIDTH,
      },
      {
        title: scorecardConstants.HANDICAP_HEADER,
        key: scorecardConstants.HANDICAP_KEY,
        className: twMerge(`text-center w-[${scoreCardWidths.DEFAULT_3X_WIDTH}%]`, normalColor),
        width: scoreCardWidths.DEFAULT_3X_WIDTH,
      },
      {
        title: scorecardConstants.NEW_PERIO_HEADER,
        key: scorecardConstants.NEW_PERIO_KEY,
        className: twMerge(`text-center w-[${scoreCardWidths.DEFAULT_3X_WIDTH}%]`, customColor),
        width: scoreCardWidths.DEFAULT_3X_WIDTH,
      },
    ],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    hiddenHoleList,
    color: normalColor,
  });
};

export const newPerioData = (gsScoreCard: any[]) => {
  const scorecardData = [...gsScoreCard];
  const hiddenHoleList: number[] = [];
  const currentUserIndex = scorecardData.findIndex((data) => data.ordernum === 1);
  const handicap = scorecardData[currentUserIndex]?.handicap ?? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE;
  const newPerioScore = scorecardData[currentUserIndex]?.newparioscore ?? scorecardConstants.ALTERNATIVE_HIDDEN_SCORE;
  scorecardData.unshift(scorecardData.splice(currentUserIndex, 1)[0]);
  const parIndex = scorecardData.findIndex((data) => data.usrId === scorecardConstants.PAR_ID_VALUE);
  scorecardData.unshift(scorecardData.splice(parIndex, 1)[0]);
  const parData = scorecardData[0];
  const dataList = scorecardData.map((scoreCard) => {
    const data = {} as any;
    data.isPar = scoreCard.usrId === scorecardConstants.PAR_ID_VALUE;
    data.isCurrentUser = scoreCard.ordernum === 1;

    data[scorecardConstants.HOLE_KEY] = {
      value: data.isPar ? scorecardConstants.PAR_NAME_VALUE : scoreCard[scorecardConstants.USER_NICKNAME_KEY],
      className: twMerge('px-[8px] !border-l-0', data.isPar ? 'bg-bg text-sub-body' : 'bg-gz-white'),
    };

    data[scorecardConstants.BASE_SCORE_KEY] = {
      value: scoreCard[scorecardConstants.BASE_SCORE_KEY],
      className: twMerge('text-center', !data.isPar ? 'gz-text-sm font-bold' : 'bg-bg'),
    };

    data[scorecardConstants.TOTAL_KEY] = {
      value: scoreCard[scorecardConstants.TOTAL_KEY],
      className: twMerge('text-center', !data.isPar ? 'gz-text-sm font-bold' : 'bg-bg'),
    };

    data[scorecardConstants.HANDICAP_KEY] = {
      value: scoreCard[scorecardConstants.HANDICAP_KEY] ?? handicap,
      className: twMerge('text-center gz-text-sm font-bold'),
    };
    if (data.isPar) {
      data[scorecardConstants.HANDICAP_KEY].rowSpan = 2;
    } else if (data.isCurrentUser) {
      data[scorecardConstants.HANDICAP_KEY].value = '';
    }

    data[scorecardConstants.NEW_PERIO_KEY] = {
      value: scoreCard[scorecardConstants.NEW_PERIO_KEY] ?? newPerioScore,
      className: twMerge('text-center gz-text-sm font-bold'),
    };
    if (data.isPar) {
      data[scorecardConstants.NEW_PERIO_KEY].rowSpan = 2;
    } else if (data.isCurrentUser) {
      data[scorecardConstants.NEW_PERIO_KEY].value = '';
    }

    for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
      if (
        data.isCurrentUser &&
        Number(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === scorecardConstants.HIDDEN_SCORE
      ) {
        hiddenHoleList.push(i);
      }
      data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
        value: getHoleScore(
          scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`],
          scorecardConstants.NEW_PERIO_HIDDEN_SCORE,
        ),
        className: twMerge(
          'text-center',
          !data.isPar && parData
            ? getBgColor(
                Number(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
                Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
                scorecardConstants.NEW_PERIO_HIDDEN_SCORE,
              )
            : '',
          data.isPar ? 'bg-bg text-sub-body' : '',
        ),
      };
    }
    return data;
  });
  const currentUserData = dataList
    .filter((data: any) => data.isPar || data.isCurrentUser)
    .map((data: any) => {
      return {
        ...data,
        [scorecardConstants.HOLE_KEY]: data.isCurrentUser
          ? {
              ...data[scorecardConstants.HOLE_KEY],
              value: scorecardConstants.SCORE_NAME,
            }
          : data[scorecardConstants.HOLE_KEY],
      };
    });
  const otherUsersData = dataList;
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList,
  };
};
