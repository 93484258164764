import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countryCode } from 'src/constants/const';
import TourNament from 'src/view/pages/leader-board/TourNaments';
import { EnumDistance } from 'src/view/pages/leader-board/constants';

interface LeaderBoardContextProps {
  lang: {
    currentLang: string;
    setCurrentLang: any;
  };
  select: {
    isChecked: boolean;
    setIsChecked: any;
  };
}

export const LeaderBoardContext = createContext<LeaderBoardContextProps | undefined>({
  lang: {
    currentLang: countryCode.USA,
    setCurrentLang: () => {},
  },
  select: {
    isChecked: false,
    setIsChecked: () => {},
  },
});

const LeaderBoardTourNament = () => {
  const { t: translate, i18n: configLang } = useTranslation();
  const settingInfo = useSelector((state: any) => state.settingInfoReducer.settingInfo);
  const [currentLang, setCurrentLang] = useState<string>(countryCode.USA);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleVisibilityChange = () => {
    configLang.changeLanguage(settingInfo.langCd);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <LeaderBoardContext.Provider
      value={{
        lang: {
          currentLang,
          setCurrentLang,
        },
        select: {
          isChecked,
          setIsChecked,
        },
      }}
    >
      <TourNament />
    </LeaderBoardContext.Provider>
  );
};

export default LeaderBoardTourNament;
