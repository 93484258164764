import { Component } from 'react';
import { pathConstants } from 'src/constants/const';
import { EnumFireStoreCollectionName } from 'src/constants/enum';
import { firebaseInstance } from 'src/init-firebase';

// ErrorBoundary Component
export default class ErrorBoundary extends Component {
  componentDidCatch(error: Error) {
    this.setState(null);
    if (window.location.pathname === pathConstants.SIGNUP) {
      firebaseInstance.logCrashlytics(error?.stack ?? error?.message, EnumFireStoreCollectionName.CRASHLYITC_ERRORS);
    } else {
      firebaseInstance.logError(error?.stack ?? error?.message);
    }
  }

  render() {
    return (this.props as any).children;
  }
}
