import { MAX_STANDARD_HOLE_COUNT, scorecardConstants, scoreCardWidths } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import {
  customHeader,
  generateTableHeaders,
  getMatchTextColor,
  getMatchValue,
  getTeamName,
  rearrangeTeamScorecardList,
} from 'src/services/scorecard-modes';
import { booleanValue } from 'src/services/utils';
import { twMerge } from 'tailwind-merge';

export const bestBallMatchHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [
      customHeader.TEAM(normalColor),
      customHeader.HOLE(normalColor, scoreCardWidths.HOLE_WITH_TEAM_WIDTH),
      customHeader.BASE_SCORE(customColor, totalHoleCnt),
    ],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    color: normalColor,
  });
};

export const bestBallMatchData = (gsScoreCard: any[]) => {
  const newGsScoreCard = rearrangeTeamScorecardList(gsScoreCard);
  const currentUserIndex = newGsScoreCard.findIndex((data) => booleanValue(data.searchplayer));
  const currentUserScore = newGsScoreCard[currentUserIndex][scorecardConstants.MATCH_TOTAL_KEY];
  const parData = newGsScoreCard[0];
  const dataList = newGsScoreCard.map((scoreCard, index) => {
    const data = {} as any;
    data.isPar = scoreCard.usrId === scorecardConstants.PAR_ID_VALUE;
    data.isCurrentUser = booleanValue(scoreCard.searchplayer);

    data[scorecardConstants.HOLE_KEY] = {
      value: data.isPar ? scorecardConstants.PAR_NAME_VALUE : scoreCard[scorecardConstants.USER_NICKNAME_KEY],
      className: twMerge('px-[8px] !border-l-0', data.isPar ? 'bg-bg text-sub-body' : 'bg-gz-white'),
    };

    const teamName = getTeamName(scoreCard[scorecardConstants.TEAM_KEY]?.toString() ?? '');
    data[scorecardConstants.TEAM_KEY] = {
      value: !data.isPar ? teamName.name : '',
    };
    let rowSpan = 1;
    if (scoreCard.team) {
      data.team.className = twMerge(
        '!border-l-0 px-[8px] text-center',
        data.isPar ? ' text-sub-body' : 'text-gz-white',
        data.isPar ? 'bg-bg' : teamName.style,
      );
    }
    if (
      scoreCard[scorecardConstants.TEAM_KEY] &&
      scoreCard[scorecardConstants.TEAM_KEY] === newGsScoreCard[index - 1]?.team
    ) {
      return data;
    }
    if (scoreCard[scorecardConstants.TEAM_KEY]) {
      rowSpan = newGsScoreCard.filter(
        (el) => el[scorecardConstants.TEAM_KEY] === scoreCard[scorecardConstants.TEAM_KEY],
      ).length;
    }

    data[scorecardConstants.BASE_SCORE_KEY] = {
      value: data.isPar
        ? scoreCard[scorecardConstants.BASE_SCORE_KEY]
        : getMatchValue(scoreCard[scorecardConstants.MATCH_TOTAL_KEY], {
            isBaseScore: true,
            isCurrentUser: data.isCurrentUser,
            hasSameScore:
              data.isCurrentUser &&
              newGsScoreCard.findIndex(
                (el, subIndex) =>
                  el[scorecardConstants.MATCH_TOTAL_KEY] === scoreCard[scorecardConstants.MATCH_TOTAL_KEY] &&
                  index !== subIndex &&
                  el.team !== scoreCard.team &&
                  el.usrId !== scorecardConstants.PAR_ID_VALUE,
              ) > -1,
            currentUserScore,
          }),
      className: twMerge(
        'text-center',
        !data.isPar
          ? `gz-text-sm font-bold ${
              scoreCard[scorecardConstants.MATCH_TOTAL_KEY] === 0
                ? 'text-sub-body'
                : scoreCard[scorecardConstants.MATCH_TOTAL_KEY] > 0
                ? 'text-gz-danger'
                : 'text-primary-75'
            }`
          : 'bg-bg',
      ),
      rowSpan,
    };

    for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
      const isEmptyHole = Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === 0;
      if (isEmptyHole && !data.isPar) {
        data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
          value: scorecardConstants.ALTERNATIVE_HIDDEN_SCORE,
          className: 'text-center',
        };
        continue;
      }
      data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
        value: data.isPar
          ? scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]
          : getMatchValue(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
        className: twMerge(
          'text-center',
          data.isPar
            ? 'bg-bg text-sub-body'
            : getMatchTextColor(Number(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`])),
        ),
        rowSpan,
      };
    }
    return data;
  });
  const currentUserData = dataList
    .filter((data: any) => data.isPar || data.isCurrentUser)
    .map((data: any) => {
      return {
        ...data,
        [scorecardConstants.HOLE_KEY]: data.isCurrentUser
          ? {
              ...data[scorecardConstants.HOLE_KEY],
              value: scorecardConstants.LEADZONE_NAME,
            }
          : data[scorecardConstants.HOLE_KEY],
      };
    });
  const otherUsersData = dataList;
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList: [],
  };
};
