import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { IconChevronDown, IconCircleCheck, IconCircleClose } from 'src/assets/svg';
import { InputStatus } from 'src/view/commons/elements/Input';
import { signupMiddleware } from 'src/requests/api/account/signup';
import { findPasswordMiddleware } from 'src/requests/api/account/find-password';
import { NotificationType } from 'src/models';
import { EnumRequestCode, EnumStatusCode } from 'src/constants/enum';
import { notificationActions } from 'src/store/notification';
import { ICountry, IVerifyPhoneEntitiesResponse } from 'src/requests/api/account/prop-state.type';
import { VERIFY_CODE_SECONDS, initialCountry } from 'src/constants/const/global.constants';
import Button from 'src/view/commons/elements/Button';
import CountrySelection from './CountrySelection';
import Modal from 'src/view/commons/elements/Modal';
import { CT_RECAPTCHA_KEY } from 'src/constants/const';
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  addNotification: (message: string, type: NotificationType) => void;
  containerClassName?: string;
  className?: string;
  defaultCountry?: any;
  classNameSendButton?: any;
  disabledVerifyButton?: boolean;
  usrNo?: string;
  message?: string | JSX.Element;
  errorMessage?: string | JSX.Element;
  doneMessage?: string | JSX.Element;
  status?: InputStatus;
  hideVerifyBtn?: boolean;
  onChangeCountry?: (country: ICountry) => void;
  onSendSmsSuccess?: (entities: any, token?: string) => void;
  onStartVerify?: () => void;
  haveSendSms?: (value: boolean) => void;
}

function InputPhoneNumber({
  addNotification,
  containerClassName,
  className,
  defaultCountry,
  classNameSendButton,
  usrNo,
  disabledVerifyButton,
  message,
  errorMessage,
  doneMessage,
  status = InputStatus.Normal,
  hideVerifyBtn = false,
  onChangeCountry,
  onSendSmsSuccess,
  onStartVerify,
  haveSendSms,
  ...rest
}: Props): JSX.Element {
  const [countryInfo, setCountryInfo] = useState(defaultCountry ? defaultCountry : initialCountry);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenCaptcha, setTokenCaptcha] = useState<string | null>(null);
  const translate = useTranslation().t;
  const sendSmsEntitiesResponse = useRef(null as IVerifyPhoneEntitiesResponse | null);

  // Update coundown time
  const [duration, setDuration] = useState<number>(0);
  const timeout = useRef<any>(null);
  useEffect(() => {
    timeout.current = setTimeout(() => {
      setDuration(duration - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [duration]);

  useEffect(() => {
    !!defaultCountry?.countryNum &&
      setCountryInfo({
        countryNum: defaultCountry?.countryNum,
        countryCd: defaultCountry?.countryCd,
        flag: defaultCountry?.flag,
      });
  }, [defaultCountry?.countryNum]);

  const changeCountry = (country: ICountry) => {
    setCountryInfo({
      countryNum: country.countryNum,
      countryCd: country.countryCd,
      flag: country.flag,
    });
    onChangeCountry && onChangeCountry(country);
    setIsOpen(false);
  };

  const openModal = () => {
    if (isLoading) return;
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const borderColor = (): string => {
    if (status === InputStatus.Error) return 'border-gz-danger';
    if (status === InputStatus.Done) return 'border-primary-75';
    return 'border-gray-20';
  };

  const textColor = (): string => {
    if (status === InputStatus.Error) return 'text-gz-danger';
    if (status === InputStatus.Done) return 'text-primary-75';
    return 'text-text';
  };

  const suffixIcon = (): JSX.Element => {
    if (status === InputStatus.Loading) {
      return <LoadingIndicator />;
    }
    if (status === InputStatus.Error) {
      return (
        <span className="cursor-pointer py-1 pl-2">
          <IconCircleClose className="fill-gz-danger stroke-white" />
        </span>
      );
    }
    if (status === InputStatus.Done) {
      return (
        <span className="cursor-pointer py-1 pl-2">
          <IconCircleCheck className="fill-primary-75" />
        </span>
      );
    }
    return <></>;
  };

  const messageElement = (): JSX.Element => {
    let text;
    if (status === InputStatus.Done) {
      text = doneMessage;
    } else if (status === InputStatus.Error) {
      text = errorMessage;
    } else {
      text = message;
    }
    if (text && typeof text === 'string') return <div className={twMerge('gz-text-xsm mt-2', textColor())}>{text}</div>;
    if (text) return <>{text}</>;
    return <></>;
  };

  const checkUserCellPhone = async () => {
    const result = await findPasswordMiddleware.checkUserCellphone({
      countryNum: countryInfo.countryNum,
      countryCd: countryInfo.countryCd,
      cellphone: rest.value as string,
      usrNo: usrNo as string,
    });
    if (result.code === EnumRequestCode.SUCCESS && result.entities.usrYn) {
      return result.entities.token;
    }
    return null;
  };

  const reCaptchaRef: any = useRef();
  const sendSms = async (token: string) => {
    try {
      setIsLoading(false);
      if (tokenCaptcha) {
        reCaptchaRef.current?.reset();
      }
      const tokenCaptchaStamp = await reCaptchaRef.current?.executeAsync();
      setTokenCaptcha(tokenCaptchaStamp);
      if (!tokenCaptchaStamp) {
        setDuration(VERIFY_CODE_SECONDS);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const result = await signupMiddleware.sendSmsVerifyPhone(
        {
          countryNum: countryInfo.countryNum,
          cellphone: rest?.value as string,
          countryCd: countryInfo.countryCd,
          sendCnt: sendSmsEntitiesResponse.current ? parseInt(sendSmsEntitiesResponse.current?.sendCnt) : 0,
        },
        tokenCaptchaStamp,
      );
      if (result?.code === EnumRequestCode.SUCCESS) {
        sendSmsEntitiesResponse.current = result?.entities;
        haveSendSms && haveSendSms(true);
        onSendSmsSuccess && onSendSmsSuccess(result?.entities, token);
      } else if (result?.code === EnumStatusCode.FAILED.toString()) {
        haveSendSms && haveSendSms(false);
        addNotification(translate('error.PHONE_NUMBER_iS_INVALID'), NotificationType.DANGER);
      } else {
        haveSendSms && haveSendSms(true);
        addNotification(translate('error.SEND_SMS_VERIFY_CODE_FAILED'), NotificationType.DANGER);
      }
    } catch (error) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    }
  };

  const sendVerifyCode = async () => {
    onStartVerify?.call(null);
    setIsLoading(true);
    try {
      let token;
      if (usrNo) {
        token = await checkUserCellPhone();
      }
      if (usrNo && !token) {
        addNotification(translate('error.PHONE_NOT_MATCH_USRNO'), NotificationType.DANGER);
        return;
      }
      await sendSms(token);
    } catch (error: any) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setDuration(VERIFY_CODE_SECONDS);
      setIsLoading(false);
    }
  };

  return (
    <div className={twMerge('flex w-full flex-col', containerClassName)}>
      <div className={twMerge('flex')}>
        {!hideVerifyBtn && <ReCAPTCHA ref={reCaptchaRef} size="invisible" sitekey={CT_RECAPTCHA_KEY} />}
        <div
          className={twMerge(
            'flex w-[229px] min-w-0 flex-auto items-center rounded-[6px] border-[1px] border-solid',
            'h-[3rem] py-2 px-3 transition-colors',
            borderColor(),
            status === InputStatus.Normal ? 'focus-within:border-primary-75' : '',
            className ?? '',
          )}
        >
          <div
            onClick={openModal}
            className={twMerge(
              'gz-text-xs flex shrink-0 cursor-pointer items-center',
              countryInfo.countryNum ? 'min-w-[3.5rem]' : 'min-w-[1.5rem]',
              rest.disabled && 'pointer-events-none',
            )}
          >
            {countryInfo.flag && <img className="pr-0.5" height={16} width={16} src={countryInfo.flag} alt="flag" />}
            <div className="pr-0.5">{countryInfo.countryNum}</div>
            <IconChevronDown className="h-[20px] w-[20px] fill-text" />
          </div>
          <input {...rest} type="tel" className="min-w-0 flex-auto pl-2.5" />
          {suffixIcon()}
        </div>
        {!hideVerifyBtn && (
          <Button
            className={twMerge('ml-2 w-[90px]', classNameSendButton)}
            color="secondary"
            disabled={status === InputStatus.Error || !rest.value || disabledVerifyButton || isLoading || duration > 0}
            loading={isLoading}
            onClick={sendVerifyCode}
          >
            {duration > 0 ? (
              duration.toString()
            ) : (
              <div className="gz-text-xs">
                {translate(
                  status === InputStatus.Done
                    ? 'commons.customs.input_phone_number.RESEND_BTN'
                    : 'commons.customs.input_phone_number.SEND_BTN',
                )}
              </div>
            )}
          </Button>
        )}
      </div>
      <div>{messageElement()}</div>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <CountrySelection
          classWrapper="md:h-[60vh]"
          defaultCode={countryInfo.countryCd}
          isModalOpened={isOpen}
          onClose={closeModal}
          onChange={changeCountry}
        />
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(InputPhoneNumber);
