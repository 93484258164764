import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { IconAngleRight, IconEmail } from 'src/assets/svg';
import Button from 'src/view/commons/elements/Button';
import { BrowserView, MobileView } from 'react-device-detect';
import useDetectKeyboardOpen from 'src/hooks/use-detect-keyboard-open';
import { useSelector } from 'react-redux';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  textButton?: string;
  disabled?: boolean;
  color?: 'default' | 'secondary' | 'secondary-gray';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

function ButtonNext({
  className,
  textButton,
  color = 'default',
  size = 'large',
  disabled = false,
  ...rest
}: Props): JSX.Element {
  const translate = useTranslation().t;
  const isNativeApp = useSelector((state: any) => state.webviewReducer.isNativeApp);
  const isOpeningKeyboard = useDetectKeyboardOpen();

  const handleClick = (e: any) => {
    if (rest.loading) return;
    rest.onClick && rest.onClick(e);
  };

  const renderSentEmail = (customClass?: string) => (
    <a
      className={twMerge('gz-text-xs flex h-[48px] items-center align-middle text-sub-body', customClass)}
      href="mailto:globalcs@golfzon.com"
    >
      <div>
        <span className="align-middle">{translate('commons.customs.CUSTOMER_SERVICE_EMAIL')}</span>
        <IconEmail className="ml-[8px] inline-flex flex-shrink-0 align-middle" />
      </div>
    </a>
  );

  return (
    <>
      <BrowserView>
        <Button {...rest} color={color} size={size} className={twMerge('mt-4', className)} disabled={disabled}>
          {textButton ? textButton : translate('commons.customs.button_next.NEXT')}
        </Button>
        {renderSentEmail('absolute inset-x-0 bottom-[16px] justify-center')}
      </BrowserView>
      <MobileView className={twMerge()}>
        {renderSentEmail(
          `fixed left-[16px] -z-1 w-[calc(100vw_-_3rem_-_64px)] bottom-[16px] ${isOpeningKeyboard ? 'hidden' : ''}`,
        )}
        <Button
          {...rest}
          className={twMerge(
            'fixed right-[16px]',
            'h-[3rem] w-[3rem]',
            'flex items-center justify-center rounded-[50%]',
            className,
            !disabled ? 'bg-primary-gradient' : '',
            isOpeningKeyboard && !isNativeApp ? 'top-[calc(var(--viewport-height)_-_3rem_-_16px)]' : 'bottom-[16px]',
          )}
          onClick={handleClick}
          disabled={disabled || rest.loading}
        >
          <IconAngleRight stroke={!disabled ? '#FFFFFF' : '#C9C8CD'} />
        </Button>
      </MobileView>
    </>
  );
}

export default ButtonNext;
