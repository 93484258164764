import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { isDesktop, isIOS, isMobile } from 'react-device-detect';
import { DateTime } from 'src/services/datetime';
import { noVideo, picVideoThumb } from 'src/assets/images';
import { IconVideoPlay, IconVideoPause } from 'src/assets/svg';
import { EnumWifiAction } from 'src/constants/enum';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import useVideoPlayer from 'src/hooks/use-video-play';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';

interface IVideoCustomProps {
  userInfo?: IMyInfoResponse;
  playAuto?: boolean;
  wifiEnable?: boolean;
  videoPath?: string;
  className?: string;
  pauseStatus?: boolean;
  thumbnail?: string;
  repeatPlay?: boolean;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  classPrefix?: string;
  classSuffix?: string;
  onlyThumb?: boolean;
  handleProgressVideoStart?: () => void;
  handleProgressVideoEnd?: () => void;
  preload?: string | undefined;
  onPlayVideo?: () => void;
}

function VideoCustom({
  userInfo,
  playAuto = true,
  wifiEnable = false,
  videoPath,
  className,
  pauseStatus,
  thumbnail,
  repeatPlay,
  prefix,
  suffix,
  classPrefix,
  classSuffix,
  onlyThumb,
  preload,
  onPlayVideo,
}: IVideoCustomProps): JSX.Element {
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);
  const settingInfo = useSelector((state: any) => state.settingInfoReducer.settingInfo);
  const onlyWifiPlayYn = isLoggedIn ? userInfo?.onlyWifiPlayYn : settingInfo?.onlyWifiPlayYn;

  const settingAutoPlay =
    onlyWifiPlayYn === EnumWifiAction.TURN_ON_ALL ||
    (wifiEnable && onlyWifiPlayYn === EnumWifiAction.TURN_ON_WIFI_ONLY) ||
    (isDesktop && onlyWifiPlayYn === EnumWifiAction.TURN_ON_WIFI_ONLY);
  const autoPlay = playAuto && settingAutoPlay;

  if (videoPath) {
    return (
      <VideoPlay
        className={className}
        videoPath={videoPath}
        pauseStatus={pauseStatus}
        thumbnail={thumbnail}
        repeatPlay={repeatPlay}
        playAuto={autoPlay}
        prefix={prefix}
        suffix={suffix}
        classPrefix={classPrefix}
        classSuffix={classSuffix}
        onlyThumb={onlyThumb}
        preload={preload}
        onPlayVideo={onPlayVideo}
      />
    );
  }
  return (
    <div className={className}>
      <img src={noVideo} alt="no video" />
    </div>
  );
}

function VideoPlay({
  videoPath,
  className,
  pauseStatus,
  thumbnail,
  repeatPlay,
  playAuto,
  prefix,
  suffix,
  classPrefix,
  classSuffix,
  onlyThumb,
  onPlayVideo,
  preload,
}: IVideoCustomProps): JSX.Element {
  const [srcThumb, setSrcThumb] = useState<string>('invalid-link');
  const videoElement = useRef<HTMLVideoElement>(null);
  const [isIconReady, setIsIconReady] = useState(false);
  const [issetVideo, setIssetVideo] = useState(true);
  const [endTime, setEndTime] = useState('00:00');
  const [isLoading, setIsLoading] = useState(false);
  const [loadedVideo, setLoadedVideo] = useState(false);
  const [thumbnailError, setThumbnailError] = useState(false);
  const { playerState, togglePlay, handleOnTimeUpdate, handleVideoProgress } = useVideoPlayer(videoElement, repeatPlay);

  useEffect(() => {
    if (loadedVideo && playerState.isPlaying) {
      onPlayVideo?.call(null);
    }
  }, [playerState.isPlaying, loadedVideo]);

  useEffect(() => {
    if (pauseStatus && playerState.isPlaying) {
      togglePlay();
    }
  }, [pauseStatus]);

  const handlePlayVideo = () => {
    if ((playAuto && !playerState.isPlaying) || (!playAuto && playerState.isPlaying)) {
      togglePlay();
    }
  };

  useEffect(() => {
    // When change Video URL: Play video if have playAuto, stop video if haven't playAuto
    setIsIconReady(true);
    handlePlayVideo();
    setIssetVideo(true);
  }, [videoPath]);

  useEffect(() => {
    // Auto play/pause when user scroll to video
    handlePlayVideo();
  }, [playAuto]);

  const curentTime = () => {
    const curentTime = videoElement?.current?.currentTime || 0;
    return DateTime.convertDurationToMinute(Math.ceil(curentTime), 2);
  };

  const countdownTime = () => {
    const totalTime = videoElement?.current?.duration || 0;
    const curentTime = videoElement?.current?.currentTime || 0;
    let remainTime = Math.ceil(totalTime) - Math.ceil(curentTime);
    if (remainTime < 0) remainTime = 0;
    return DateTime.convertDurationToMinute(remainTime, 2);
  };

  const handleOnPlay = () => {
    togglePlay();
    // Update show icon pause 0.5 second. after, show icon play
    setIsIconReady(false);
    setTimeout(() => {
      setIsIconReady(true);
    }, 500);
  };

  const handleOnVideoSuccess = () => {
    // setEndTime(countdownTime());
    if (videoElement?.current?.duration) {
      const curentTime = videoElement?.current.duration || 0;
      const endTime = DateTime.convertDurationToMinute(Math.round(curentTime), 2);
      setEndTime(endTime);
    }
    setLoadedVideo(true);
    setIsLoading(false);
  };

  const handleOnVideoError = (err: any) => {
    const { currentTarget, target } = err;
    if (isMobile && isIOS) {
      return;
    }
    setIssetVideo(false);
  };

  const handleProgressVideo = (event: any) => {
    handleVideoProgress(Number(event.target.value));
  };

  // Update Thumbnail for Video
  useEffect(() => {
    if (thumbnail) {
      setSrcThumb(thumbnail);
    }
  }, [thumbnail]);

  const handleOnThumbError = ({ currentTarget }: any) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = picVideoThumb;
    if (srcThumb === thumbnail) {
      setThumbnailError(true);
    }
  };

  const renderActionVideo = () => {
    return (
      <>
        <div className="video-control">
          <div className="video-backdrop-play" onClick={handleOnPlay}></div>
          <div
            className={twMerge('video-play', !playerState.isPlaying && !isLoading ? 'block' : 'hidden')}
            onClick={handleOnPlay}
          >
            <div className="video-play-action">
              {playerState.progress <= 0.01 ? (
                <IconVideoPlay className="fill-gz-white" />
              ) : !isIconReady ? (
                <IconVideoPause className="fill-gz-white" />
              ) : (
                <IconVideoPlay className="fill-gz-white" />
              )}
            </div>
          </div>
          {(playerState.progress > 0.01 || playerState.isReplayed) && (
            <div className="video-time-control">
              <div className="controls-time">{curentTime()}</div>
              <div className="video-time-range">
                <input type="range" min="0" max="100" value={playerState.progress} onChange={handleProgressVideo} />
              </div>
              <div className="controls-time">{endTime}</div>
            </div>
          )}
        </div>
      </>
    );
  };

  if (issetVideo) {
    if (onlyThumb) {
      return (
        <video
          className={className}
          src={videoPath + '#t=0.0001'}
          ref={videoElement}
          onError={handleOnVideoError}
          autoPlay={false}
          playsInline
        />
      );
    }
    return (
      <div className={twMerge('video-wrapper video-wrapper__standar', className)}>
        <video
          className="video-crop"
          src={thumbnailError || isMobile ? videoPath + '#t=0.0001' : videoPath}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
          onLoadStart={() => {
            if (!!playAuto) {
              setIsLoading(true);
              setLoadedVideo(false);
            }
          }}
          onLoadedData={handleOnVideoSuccess}
          onError={handleOnVideoError}
          autoPlay={!!playAuto}
          playsInline
          preload={preload}
        />
        <div
          className={twMerge(
            'absolute top-0 left-0 h-full w-full bg-black-40',
            'flex items-center justify-center',
            isLoading ? 'z-[3]' : '-z-[3]',
          )}
        >
          <LoadingIndicator className="h-[24px] w-[24px]" />
        </div>
        {thumbnail && !thumbnailError && (
          <img
            className={twMerge(
              'absolute min-h-full min-w-full',
              'top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]',
              playerState.progress <= 0.01 ? 'z-[1]' : '',
            )}
            src={srcThumb}
            onError={handleOnThumbError}
            alt="poster"
          />
        )}
        {prefix && <div className={twMerge('absolute top-[16px] left-[16px] z-[3]', classPrefix)}>{prefix}</div>}
        {suffix && <div className={twMerge('absolute top-[16px] right-[16px] z-[3]', classSuffix)}>{suffix}</div>}
        {renderActionVideo()}
      </div>
    );
  }
  return (
    <div className={twMerge(prefix ? 'relative' : '', className)}>
      {prefix && <div className={twMerge('absolute top-[15px] left-[20px] z-[3]', classPrefix)}>{prefix}</div>}
      <img src={onlyThumb ? thumbnail : noVideo} alt="no video" />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userInfo: state.mainInfoReducer.userInfo,
  wifiEnable: state.settingInfoReducer.wifiEnable,
});

export default connect(mapStateToProps, null)(VideoCustom);
