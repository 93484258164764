import { useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp, flagGolfzon, IconLocation } from 'src/assets/svg';
import { countryCode, RUSSIA_COUNTRY_CODE, scorecardConstants } from 'src/constants/const';
import { getCountries } from 'src/constants/const/countries.constants';
import axios from 'src/requests';
import { ICountryGroup } from 'src/requests/api/account/prop-state.type';
import { scorecard } from 'src/services/scorecard-modes';
import { numberWithSign } from 'src/services/utils';
import ImageCustom from 'src/view/commons/customs/Image';
import { LeaderBoardContext } from 'src/view/pages/leader-board';
import { EnumDistance, TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';
import ScoreNotes from 'src/view/pages/record/score-card/ScoreNotes';
import ScoreTable from 'src/view/pages/record/score-card/ScoreTable';
import { twMerge } from 'tailwind-merge';

interface Props {
  infoTop1?: any;
}

const RankTop = (props: Props) => {
  const translate = useTranslation().t;
  const context = useContext(LeaderBoardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [arrData, setArrData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({
    greenRate: 0,
    fairwayRate: 0,
    averageDistance: 0,
    longestDistance: 0,
    distanceUnit: 'm',
  });

  const countries: ICountryGroup[] = useMemo(() => getCountries(translate), [translate]);

  const table = () => {
    return {
      myTableHeader: scorecard.newPerioHeader(
        'bg-semantic-yellow-50',
        'bg-semantic-yellow-70',
        19,
        scorecard.newPerioData(arrData).hiddenHoleList,
      ),
      tableData: scorecard.newPerioData(arrData).currentUserData,
    };
  };

  const convertToNewPerioScoreCard = (data: any) => {
    const scoreCard = [];
    //change par key, hole key
    const parInfo: any = {};
    Object.keys(data?.par).forEach((key) => {
      const newKey = key.replace('par', 'holeNo')?.replace('No0', 'No');
      parInfo[newKey] = data?.par[key]?.toString();
    });
    const holeInfo: any = {};
    Object.keys(data?.hole).forEach((key) => {
      const newKey = key.replace('hole', 'holeNo')?.replace('No0', 'No');
      holeInfo[newKey] = data?.hole[key] === -1 ? '100' : data?.hole[key]?.toString();
    });

    scoreCard[0] = {
      ...parInfo,
      baseScore: data?.basicPar,
      ordernum: 99,
      usrId: scorecardConstants.PAR_ID_VALUE,
      usrNickimg: scorecardConstants.PAR_ID_VALUE,
      usrNicknm: scorecardConstants.PAR_ID_VALUE,
      hiddentotscore: 40,
    };
    scoreCard[1] = {
      ...holeInfo,
      baseScore: data?.totalPar,
      ordernum: 1,
      usrId: data?.user?.userId,
      usrNickimg: data?.user?.userNickImage,
      usrNicknm: data?.user?.userNickName,
      hiddentotscore: 0,
      handicap: 0,
      newparioscore: 0,
    };

    return scoreCard;
  };

  const getDetailItem = async () => {
    setLoading(true);

    await axios
      .get('tournament/score-card', {
        params: {
          unit: context?.select.isChecked ? EnumDistance.METERS : EnumDistance.YARDS,
          roundId: props?.infoTop1?.roundId,
        },
      })
      .then((res) => {
        setStatistics(res.data?.data?.entities?.statistics);
        setArrData(convertToNewPerioScoreCard(res.data?.data?.entities) || []);
      })
      .catch((err) => {
        setArrData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getDetailItem();
    }
  }, [isOpen, context?.select.isChecked]);

  const findCountry = (countryCode: string) => {
    if (countryCode === RUSSIA_COUNTRY_CODE) return flagGolfzon;

    let appCountryData: any;
    countries?.find((group) => {
      const value = group?.valueGroup?.find((country) => country.countryCd === countryCode);
      appCountryData = value;
      return !!value;
    });
    return appCountryData?.flag || '';
  };

  const skeleton = () => (
    <div className="mx-4 rounded-md border-[1px] border-blue-90 p-4 ">
      <div className="flex items-center justify-between bg-primary-50 p-[12px_18px]">
        <div className="h-[14px] w-[120px] animate-pulse rounded-[4px] bg-gz-white" />
      </div>
      <div className="mt-[16px] px-[18px]">
        <div>{context?.lang.currentLang === countryCode.JAPAN ? TextJapan.ROUND_RECORD : TextGlobal.ROUND_RECORD}</div>
        <div className="mt-[8px] flex cursor-pointer items-center">
          <div className="h-[16px] w-[180px] animate-pulse rounded-[4px] bg-disable" />
        </div>
        <div className="gz-text-xsm mt-[14px] flex items-center justify-between">
          <div className="text-light-blue-10">
            <div>
              {context?.lang.currentLang === countryCode.JAPAN
                ? TextJapan.AVERAGE_DISTANCE
                : TextGlobal.AVERAGE_DISTANCE}
            </div>
            <div className="mt-[8px]">
              {context?.lang.currentLang === countryCode.JAPAN
                ? TextJapan.LONGEST_DISTANCE
                : TextGlobal.LONGEST_DISTANCE}
            </div>
          </div>
          <div>
            <div className="h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
            <div className="mt-[8px] h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
          </div>
          <div className="text-light-blue-10">
            <div>
              {context?.lang.currentLang === countryCode.JAPAN
                ? TextJapan.FAIRWAY_LANDING_RATE
                : TextGlobal.FAIRWAY_LANDING_RATE}
            </div>
            <div className="mt-[8px]">
              {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.GREEN_RATE : TextGlobal.GREEN_RATE}
            </div>
          </div>
          <div>
            <div className="h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
            <div className="mt-[8px] h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
          </div>
        </div>
      </div>
      <div className="mt-[16px] px-[18px]">
        <div className="h-[100px] w-full animate-pulse rounded-[4px] bg-disable" />
      </div>
      <ScoreNotes className={twMerge('px-[18px] pt-[16px]', isMobile ? 'justify-between gap-0' : 'gap-[14px]')} />
    </div>
  );

  return (
    <div className="w-full">
      <div className={` flex w-full items-center justify-center`}>
        <div
          className={`flex  flex-row border-t py-3 ${
            !isOpen || arrData.length < 1 ? 'border-b' : ''
          } items-center justify-center border-blue-85 px-3 py-1`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="relative">
            <img
              src={findCountry(props?.infoTop1?.userCountryCode || 'USA')}
              alt=""
              className="absolute bottom-0 right-[-5px] h-[14px] w-[14px] rounded-full lg:h-[20px] lg:w-[20px]"
            />
            <div className="avt-border-gradient flex-shrink-0 rounded-full bg-border-top-rank">
              <ImageCustom
                imgPath={props.infoTop1?.userNickImage}
                imgAlt="avatar"
                className="h-[24px] w-[24px] rounded-full lg:h-[36px] lg:w-[36px]"
              />
            </div>
          </div>
          <p className="mx-3 mb-0 text-[20px] font-medium text-blue-85 lg:text-[32px] ">
            {props.infoTop1?.usrNickName || ''}
          </p>
          <p className="mr-3 mb-0 text-[20px] font-medium text-blue-85 lg:text-[32px] ">
            {numberWithSign(props.infoTop1?.score)}
          </p>
          {!isOpen ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>
      {isOpen
        ? loading
          ? skeleton()
          : arrData.length > 0 && (
              <div className="mx-4 rounded-md border-[1px] border-blue-90 bg-blue-25 p-4 ">
                <div className="font-medium">
                  <div>
                    {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.ROUND_RECORD : TextGlobal.ROUND_RECORD}
                  </div>
                </div>
                <div className="mt-[8px] flex cursor-pointer items-center py-[6px]">
                  <IconLocation className="flex-shrink-0 stroke-text" />
                  <div className="ml-[8px] font-medium">{props.infoTop1?.storeName}</div>
                </div>
                <div className="gz-text-xsm mt-[8px] flex items-center justify-between">
                  <div className={twMerge('leading-[16px] text-light-blue-10', isMobile && 'gz-text-xtiny ')}>
                    {context?.lang.currentLang === countryCode.JAPAN
                      ? TextJapan.AVERAGE_DISTANCE
                      : TextGlobal.AVERAGE_DISTANCE}
                    <div className="mt-[8px]">
                      {context?.lang.currentLang === countryCode.JAPAN
                        ? TextJapan.LONGEST_DISTANCE
                        : TextGlobal.LONGEST_DISTANCE}
                    </div>
                  </div>
                  <div className="font-medium">
                    <div>
                      {statistics.averageDistance} {statistics.distanceUnit || 'yd'}
                    </div>
                    <div className="mt-[8px]">
                      {statistics.longestDistance} {statistics.distanceUnit || 'yd'}
                    </div>
                  </div>
                  <div className={twMerge('leading-[16px] text-light-blue-10', isMobile && 'gz-text-xtiny ')}>
                    <div>
                      {context?.lang.currentLang === countryCode.JAPAN
                        ? TextJapan.FAIRWAY_LANDING_RATE
                        : TextGlobal.FAIRWAY_LANDING_RATE}
                    </div>
                    <div className="mt-[8px]">
                      {context?.lang.currentLang === countryCode.JAPAN ? TextJapan.GREEN_RATE : TextGlobal.GREEN_RATE}
                    </div>
                  </div>
                  <div className="font-medium">
                    <div>{statistics.fairwayRate}%</div>
                    <div className="mt-[8px]">{statistics.greenRate}%</div>
                  </div>
                </div>
                <div className="mt-[8px]">
                  <ScoreTable
                    headers={scorecard.strokeHeader('bg-blue-95', 'bg-blue-95', 18)}
                    data={table().tableData}
                  />
                </div>
                <ScoreNotes className={twMerge('mt-[14px] justify-between')} />
              </div>
            )
        : ''}
    </div>
  );
};

export default RankTop;
