import { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logoHeader } from 'src/assets/images';
import { countryCode, localesLeaderBoard } from 'src/constants/const';
import { useGeoLocation } from 'src/hooks/use-geo-location';
import { convertLangCodeToCountryCode, setFontFamily } from 'src/services/global';
import DropdownMenu from 'src/view/commons/elements/DropdownMenu';
import { LeaderBoardContext } from 'src/view/pages/leader-board';
import { TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';
import { twMerge } from 'tailwind-merge';

const HeaderLoadBoard = () => {
  const context = useContext(LeaderBoardContext);
  const { t: translate, i18n: configLang } = useTranslation();
  const { countryCode: geoCountryCode } = useGeoLocation();

  useEffect(() => {
    const langDefault =
      convertLangCodeToCountryCode(navigator.language.split('-')[0]) || geoCountryCode?.toLocaleLowerCase();
    configLang.changeLanguage(langDefault).finally(() => {
      context?.lang.setCurrentLang(langDefault !== countryCode.JAPAN ? countryCode.USA : countryCode.JAPAN);
    });

    setFontFamily(langDefault !== countryCode.JAPAN ? countryCode.USA : countryCode.JAPAN);
  }, [geoCountryCode]);

  const changeLanguage = (language: string) => {
    setFontFamily(language);
    configLang.changeLanguage(language).finally(() => {
      context?.lang.setCurrentLang(language);
    });
  };

  return (
    <header className={twMerge('flex h-[50px] items-center justify-between')}>
      <>
        <div>
          <img src={logoHeader} className="object-stretch md: ml-3 w-full" alt="logo" />
        </div>
        <div className="gz-text-xs flex items-center py-2.5 px-3 ">
          <div>{context?.lang.currentLang === countryCode.JAPAN ? TextJapan.language : TextGlobal.language}</div>
          <DropdownMenu
            value={context?.lang.currentLang}
            items={localesLeaderBoard}
            classItem={isMobile ? 'hover:bg-gz-white w-[104px]' : 'w-[128px]'}
            onChange={changeLanguage}
            classButton="text-darkblue-60"
          />
        </div>
      </>
    </header>
  );
};
const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = {};

// export default HeaderLoadBoard;
export default connect(mapStateToProps, mapDispatchToProps)(HeaderLoadBoard);
