import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

interface IScoreTableProps {
  id?: string;
  className?: string;
  classContainer?: string;
  headers: any[];
  data: any[];
}

function ScoreTable({ id, className, classContainer, headers, data }: IScoreTableProps): JSX.Element {
  /*
    Hide tr tag if the colSpan of the previous tr tag >= 2
  */
  const getColSpanNextIndex = (header: any) => {
    const colSpanNextIndex = [];
    for (let index = 0; index < header.length; index++) {
      if (header[index].colSpan && header[index].colSpan > 1) {
        colSpanNextIndex.push(...[...Array(header[index].colSpan - 1)].map((_, nextIndex) => index + nextIndex + 1));
      }
    }
    return colSpanNextIndex;
  };

  return (
    <div className={twMerge('bg-gz-white', classContainer)} id={id}>
      {headers.map((header: any, hIndex: number) => {
        const colSpanIndex = getColSpanNextIndex(header);
        return (
          <table
            key={hIndex}
            className={twMerge(
              'table-score-card gz-text-xsm w-full table-auto [&:not(:first-child)]:mt-[8px]',
              className,
            )}
          >
            <tbody>
              <tr className="text-gz-white">
                {header
                  .filter((_: any, iIndex: number) => !colSpanIndex.includes(iIndex))
                  .map((item: any, iIndex: number) => (
                    <td key={iIndex} rowSpan={item.rowSpan} colSpan={item.colSpan} className={item.className}>
                      {item.title}
                    </td>
                  ))}
              </tr>
              {data.map((row: any, rIndex: number) => (
                <tr key={rIndex}>
                  {header.map((cell: any, cIndex: number) => (
                    <Fragment key={cIndex}>
                      {row[cell.key] && (
                        <td
                          rowSpan={row[cell.key]?.rowSpan}
                          colSpan={row[cell.key]?.colSpan}
                          className={row[cell.key]?.className}
                        >
                          {row[cell.key]?.value}
                        </td>
                      )}
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      })}
    </div>
  );
}

export default ScoreTable;
