import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { notification } from 'src/store/notification';
import { globalReducer } from 'src/store/global';
import { authentication, LOGOUT_SUCCESS } from 'src/store/account/login';
import { registration } from 'src/store/account/signup';
import { webviewReducer } from 'src/store/webview';
import { mainInfoReducer } from 'src/store/home';
import { courseReducer } from 'src/store/course';
import { storeMapReducer } from 'src/store/store-map';
import { commentsReducer } from 'src/store/comments';
import { recordsReducer } from 'src/store/records';
import { feedReducer } from 'src/store/feed';
import { newsNoticeReducer } from 'src/store/news';
import { faqReducer } from 'src/store/faq';
import { settingInfoReducer } from 'src/store/setting';
import { ipInfoReducer } from 'src/store/ipinfo';
import { tournamentReducer } from 'src/store/tournament';
import { trainingModeDetailReducer } from 'src/store/training-mode-detail';
import { trendingStoresReducer } from 'src/store/trending-stores';
import { leagueReducer } from 'src/store/league';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const allReducers = combineReducers({
  notification,
  globalReducer,
  authentication,
  registration,
  webviewReducer,
  mainInfoReducer,
  courseReducer,
  storeMapReducer,
  commentsReducer,
  recordsReducer,
  feedReducer,
  newsNoticeReducer,
  faqReducer,
  settingInfoReducer,
  ipInfoReducer,
  tournamentReducer,
  trainingModeDetailReducer,
  trendingStoresReducer,
  leagueReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_SUCCESS) {
    const { globalReducer, trendingStoresReducer } = state;
    state = { globalReducer, trendingStoresReducer };
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
