import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { history } from 'src/services/history';
import { iconGolfFlag, iconGolfTraining, iconGolfUser } from 'src/assets/svg';
import { LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL, pathConstants } from 'src/constants/const';
import { EnumPlayType, EnumRecordType } from 'src/constants/enum';
import DraggableScrollView from 'src/view/commons/customs/DraggableScrollView';
import useScroll from 'src/hooks/use-scroll';
import { useLocation } from 'react-router-dom';
import { getLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { eventName } from 'src/constants/const/firebase.constants';
import HeaderTabsCustom from 'src/view/commons/customs/HeaderTabsCustom';

function RecordListHeader(): JSX.Element {
  const translate = useTranslation().t;
  const [showNewLabel, setShowNewLabel] = useState(!getLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL));
  const { pathname } = useLocation();
  const { scrollY } = useScroll();

  const typeRound = useMemo(() => {
    if (pathname.startsWith(pathConstants.RECORDS_GDR)) return EnumPlayType.GDR;
    if (pathname.startsWith(pathConstants.RECORDS_TRAINING_MODE)) return EnumPlayType.TRAINING_MODE;
    return EnumPlayType.GS;
  }, [pathname]);

  useEffect(() => {
    if (typeRound === EnumPlayType.TRAINING_MODE) {
      setShowNewLabel(false);
      saveObjectLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL, true);
    }
  }, [typeRound]);

  const pages = [
    {
      title: translate('pages.record.ROUND'),
      path: pathConstants.RECORDS_GS_ROUND,
      icon: iconGolfFlag,
      type: EnumPlayType.GS,
      eventName: eventName.ACTIVITY_ROUNDS,
      tabs: [
        {
          title: translate('pages.record.type.GS.score.TITLE'),
          path: pathConstants.RECORDS_GS_ROUND,
          type: EnumRecordType.GS_ROUND,
        },
        {
          title: translate('pages.record.type.GS.swing_video.TITLE'),
          path: pathConstants.RECORDS_GS_SWING,
          type: EnumRecordType.GS_SWING_VIDEO,
        },
      ],
    },
    {
      title: translate('pages.record.PRACTICE'),
      path: pathConstants.RECORDS_GDR,
      icon: iconGolfUser,
      type: EnumPlayType.GDR,
      eventName: eventName.ACTIVITY_GDR,
      tabs: [
        {
          title: translate('pages.record.type.GDR.driving_range.TITLE'),
          path: pathConstants.RECORDS_GDR,
          type: EnumRecordType.GDR_DRIVING,
        },
        {
          title: translate('pages.record.type.GDR.practice_round.TITLE'),
          path: pathConstants.RECORDS_GDR_PRACTICE,
          type: EnumRecordType.GDR_ROUND,
        },
        {
          title: translate('pages.record.type.GDR.swing_video.TITLE'),
          path: pathConstants.RECORDS_GDR_SWING,
          type: EnumRecordType.GDR_SWING_VIDEO,
        },
      ],
    },
    {
      title: (
        <div className="flex items-center">
          <div>{translate('pages.record.TRAINING_MODE')}</div>
          {typeRound !== EnumPlayType.TRAINING_MODE && showNewLabel && (
            <div className="gz-text-xtiny ml-[4px] rounded-[10px] bg-gz-danger p-[2px_4px] text-gz-white">
              {translate('pages.record.type.training_mode.NEW_LABEL')}
            </div>
          )}
        </div>
      ),
      path: pathConstants.RECORDS_TRAINING_MODE,
      icon: iconGolfTraining,
      type: EnumPlayType.TRAINING_MODE,
      eventName: eventName.ACTIVITY_MYPRACTICE,
      tabs: [
        {
          title: translate('pages.record.type.training_mode.driving_range.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE,
          type: EnumRecordType.TM_DRIVING_RANGE,
        },
        {
          title: translate('pages.record.type.training_mode.approach.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_APPROACH,
          type: EnumRecordType.TM_APPROACH,
        },
        {
          title: translate('pages.record.type.training_mode.putting.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_PUTTING,
          type: EnumRecordType.TM_PUTTING,
        },
        {
          title: translate('pages.record.type.training_mode.swing_video.TITLE'),
          path: pathConstants.RECORDS_TRAINING_MODE_SWING,
          type: EnumRecordType.TM_SWING_VIDEO,
        },
      ],
    },
  ];

  return (
    <>
      <HeaderTabsCustom
        classContainer="border-b-[1px] border-gray-20"
        className="shadow-none"
        tabs={pages.map((page) => ({
          title: page.title,
          path: page.path,
          eventName: page.eventName,
          startWith: true,
        }))}
      />
      <DraggableScrollView
        sticky
        className={twMerge(
          'scroll-hidden sticky top-[48px] z-[10] flex bg-gz-white py-[12px] transition-all',
          scrollY >= 1 && 'shadow-bottom',
        )}
      >
        {pages.map((page) => (
          <Fragment key={page.path}>
            {typeRound === page.type && (
              <>
                {page.tabs.map((tab) => (
                  <button
                    key={tab.title}
                    className={twMerge(
                      'flex-shrink-0 cursor-pointer rounded-[30px] font-bold focus:!outline-none',
                      'gz-text-sm mr-[8px] px-[16px] py-[8px] first:ml-[24px] last:mr-[24px]',
                      pathname === tab.path ? 'bg-primary-75 text-gz-white ' : 'bg-primary-20 text-primary-75',
                    )}
                    onClick={() => history.replace(tab.path)}
                  >
                    {tab.title}
                  </button>
                ))}
              </>
            )}
          </Fragment>
        ))}
      </DraggableScrollView>
    </>
  );
}

export default RecordListHeader;
