export enum EnumUserLevelCode {
  DIAMOND_3 = 'REA1',
  DIAMOND_2 = 'REA2',
  DIAMOND_1 = 'REA3',
  BEFORE_DIAMOND = 'PEA0',
  INACTIVE_DIAMOND = 'DEA0',
  PLATINUM_3 = 'RFA1',
  PLATINUM_2 = 'RFA2',
  PLATINUM_1 = 'RFA3',
  BEFORE_PLATINUM = 'PFA0',
  INACTIVE_PLATINUM = 'DFA0',
  GOLD_3 = 'RCR1',
  GOLD_2 = 'RCR2',
  GOLD_1 = 'RCR3',
  BEFORE_GOLD = 'PCR0',
  INACTIVE_GOLD = 'DCR0',
  SILVER_3 = 'RMA1',
  SILVER_2 = 'RMA2',
  SILVER_1 = 'RMA3',
  BEFORE_SILVER = 'PMA0',
  INACTIVE_SILVER = 'DMA0',
  BRONZE_3 = 'RSP1',
  BRONZE_2 = 'RSP2',
  BRONZE_1 = 'RSP3',
  BEFORE_BRONZE = 'PSP0',
  INACTIVE_BRONZE = 'DSP0',
}

export enum EnumPopupType {
  HAPPY_BIRTHDAY = '1a',
  GOOD_GAME = '2a',
  HOLE_IN_ONE = '2b',
}

export enum ETimeType {
  M = 'M',
  H = 'H',
  D = 'D',
}

export enum EAlarmListType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  L = 'L',
  BOOKING = 'BOOKING',
  PLUS_ITEM = 'IT',
  LEVEL_UP = 'LV',
}

export enum ELeagueAlarmCode {
  L1 = 'L1', // LEAGUE_CREATED
  L2 = 'L2', // LEAGUE_PUBLISH
  L3 = 'L3', // before league start , LEAGUE_REMIND
  L4 = 'L4', // LEAGUE_END
  L5 = 'L5', // RANKING_EACH_ROUND
  L6 = 'L6',
  L7 = 'L7', // Reject request join league
  L8 = 'L8', // Accept request join league
  L9 = 'L9', // update schedule
  L10 = 'L10', // before match start
}
