import { useTranslation } from 'react-i18next';
import Modal from 'src/view/commons/elements/Modal';
import { twMerge } from 'tailwind-merge';

interface IConfirmAlertDialogProps {
  title: string | JSX.Element;
  textOK?: string | JSX.Element;
  classOK?: string;
  textCancel?: string | JSX.Element;
  classCancel?: string;
  classTitle?: string;
  classAction?: string;
  isOpen: boolean;
  reverseAction?: boolean;
  staticMode?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

function ConfirmAlertDialog({
  title,
  textOK,
  textCancel,
  classOK,
  classCancel,
  classTitle,
  classAction,
  isOpen,
  staticMode = false,
  onClose,
  onConfirm,
  reverseAction = false,
}: IConfirmAlertDialogProps): JSX.Element {
  const translate = useTranslation().t;

  return (
    <Modal staticMode={staticMode} className="w-[270px] rounded-[14px]" isOpen={isOpen} onClose={onClose}>
      <>
        <div className={twMerge('gz-text-xs p-[24px_16px] text-center', classTitle)}>{title}</div>
        <div
          className={twMerge(
            'flex divide-x divide-gray-20',
            reverseAction ? 'flex-row-reverse divide-x-reverse' : '',
            classAction,
          )}
        >
          <div
            className={twMerge(
              'gz-text-xs w-1/2 pt-[10px] pb-[14px] text-center text-gz-danger',
              'cursor-pointer border-t-[1px] border-gray-20 active:bg-gray-10',
              classCancel,
            )}
            onClick={onClose}
          >
            {textCancel ? textCancel : translate('commons.customs.confirm_alert_dialog.CANCEL')}
          </div>
          <div
            className={twMerge(
              'gz-text-xs w-1/2 pt-[10px] pb-[14px] text-center text-primary-75',
              'cursor-pointer border-t-[1px] border-gray-20 active:bg-gray-10',
              classOK,
            )}
            onClick={onConfirm}
          >
            {textOK ? textOK : translate('commons.customs.confirm_alert_dialog.OK')}
          </div>
        </div>
      </>
    </Modal>
  );
}

export default ConfirmAlertDialog;
