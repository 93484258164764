export enum EnumZone {
  zonea = 'ZONE_A',
  zoneb = 'ZONE_B',
  zonec = 'ZONE_C',
}

export enum EnumDistance {
  METERS = 'm',
  YARDS = 'yd',
}

export const TextJapan = {
  ROUND_RECORD: 'ラウンド履歴',
  AVERAGE_DISTANCE: '平均飛距離',
  LONGEST_DISTANCE: '最大飛距離',
  FAIRWAY_LANDING_RATE: 'フェアウェイキープ率',
  GREEN_RATE: 'パーオン率',
  RANK: 'ランク',
  PLAYER: 'プレイヤー',
  SCORE: 'スコア',
  tournament: '大会に参加する前の大会番号を必ず確認してください!',
  language: '言語',
  content: {
    title: '大会要綱',
    title_list: 'ZONE別国のご案内',
    text_ZoneA: 'カナダ、メキシコ、アメリカ',
    text_ZoneB: '日本、カンボジア、香港、インド、マレーシア、フィリピン、シンガポール、台湾、タイ、ベトナム',
    text_ZoneC:
      'オーストラリア、オーストリア、フランス、ドイツ、ギリシャ、イスラエル、イタリア、カザフスタン、クウェート、ラトビア、モーリシャス、ニュージーランド、ロシア、サウジアラビア、 スロバキア、南アフリカ、スペイン、スイス、UAE、英国',
  },
  about_us: '会社の紹介',
  terms_of_use: '利用規約',
  privacy_policy: '個人情報ポリシー',
};
export const TextGlobal = {
  ROUND_RECORD: 'Round Record',
  AVERAGE_DISTANCE: 'Avg. Distance',
  LONGEST_DISTANCE: 'Longest Distance',
  FAIRWAY_LANDING_RATE: 'Fairway Hit Rate',
  GREEN_RATE: 'Green Hit Rate',
  RANK: 'Rank',
  PLAYER: 'Player',
  SCORE: 'Score',
  tournament: 'Tournament number:',
  language: 'Language',
  content: {
    title: 'Terms and conditions',
    title_list: 'List of countries in each Zone',
    text_ZoneA: 'Canada, Mexico, USA',
    text_ZoneB: 'Japan, Cambodia, Hong Kong, India, Malaysia, Philippines, Singapore, Taiwan, Thailand, Vietnam',
    text_ZoneC:
      'Australia, Austria, France, Germany, Greece, Israel, Italy, Kazakhstan, Kuwait, Latvia, Mauritius, New Zealand, Saudi Arabia, Slovakia, South Africa, Spain, Switzerland, UAE, UK',
  },
  about_us: 'About Us',
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
};
