import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { notificationActions } from 'src/store/notification';
import { EnumRequestCode, pageSize } from 'src/constants/enum';
import { NotificationType } from 'src/models';
import { INasmoDetail } from 'src/models/nasmo';
import { listGDR } from 'src/requests/api/record/GDR';
import { noImageSwingFeedLight } from 'src/assets/images';
import { IDetailID, IObjectData } from 'src/requests/api/common/prop-state.type';
import { removeItemMonthObject, reverseMonthObject } from 'src/services/gdr-and-gs';
import { TUNING_TIME_TIMEZONE, formatDate } from 'src/constants/const';
import { deleteFeedAfterDeletingSwingVideo } from 'src/services/feed';
import NasmoList, { ITypeLayout } from 'src/view/pages/record/commons/NasmoList';
import InfiniteScrollWrapper from 'src/view/commons/customs/InfiniteScroll';
import VideoRegion from 'src/view/pages/record/commons/VideoRegion';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import CustomPullToRefresh from 'src/view/commons/customs/PullToRefresh';
import { isDesktop } from 'react-device-detect';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { useEventFirebase } from 'src/hooks/use-event-firebase';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';
import VideoDescription from 'src/view/pages/record/about/GDR/swing-video/VideoDescription';

interface IRecordGdrSwingVideoProps {
  userInfo?: IMyInfoResponse;
  addNotification: (message: string, type: NotificationType) => void;
}
function RecordGdrSwingVideo(props: IRecordGdrSwingVideoProps): JSX.Element {
  const translate = useTranslation().t;
  const { isMenucard } = useEventFirebase();
  const [videoIdDefault, setVideoIdDefault] = useState<number>();
  const [videoDetail, setVideoDetail] = useState<INasmoDetail>();
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [listVideo, setListVideo] = useState<IObjectData<Record<string, INasmoDetail[]>>>({
    keySort: [],
    values: {},
    length: 0,
  });
  const listNasmoRef = useRef<IObjectData<Record<string, INasmoDetail[]>>>({
    keySort: [],
    values: {},
    length: 0,
  });
  const pageInfo = useRef<any>({
    loaded: 0,
    lastPage: false,
  });

  useEffect(() => {
    fetchListData();
  }, []);

  useEffect(() => {
    listNasmoRef.current = listVideo;
  }, [listVideo]);

  const fetchListData = async (isLoadingMore?: boolean) => {
    try {
      setLoading(true);
      if (isLoadingMore && (pageInfo.current.lastPage || pageInfo.current.loaded === 0)) return;
      const params = {
        page: pageInfo.current.loaded + 1,
        rows: pageSize.SPECIAL,
      };
      const result = await listGDR.getListNasmo(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get nasmo list failed');
      }
      const listNasmo =
        pageInfo.current.loaded === 0
          ? {
              keySort: [],
              values: {},
              length: 0,
            }
          : listNasmoRef.current;
      if (!result.entities || result.entities.length === 0) {
        pageInfo.current = {
          ...pageInfo.current,
          loaded: pageInfo.current.loaded + 1,
          lastPage: true,
        };
        setListVideo({ ...listNasmo });
        return;
      }
      if (pageInfo.current.loaded === 0) {
        fetchDataDetail(result.entities[0]?.nasmoId);
      }
      const dataGroup = reverseMonthObject(result.entities, 'statDate', listNasmo, {
        formatDate: formatDate.SIMPLE,
        dataTimezone: TUNING_TIME_TIMEZONE,
      });
      const isLast = result.totalCount <= dataGroup.length || result.entities.length === 0;
      pageInfo.current = {
        ...pageInfo.current,
        loaded: pageInfo.current.loaded + 1,
        lastPage: isLast,
      };

      setListVideo(dataGroup);
    } catch (error: any) {
      console.warn(error);
      pageInfo.current = {
        ...pageInfo.current,
        loaded: pageInfo.current.loaded + 1,
        lastPage: true,
      };
      props.addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    pageInfo.current = {
      loaded: 0,
      lastPage: false,
    };
    await fetchListData();
    setIsRefreshing(false);
  };

  const fetchDataDetail = async (id?: number) => {
    setVideoIdDefault(id);
    try {
      const params = {
        detailNo: id ? id : listVideo[0].nasmoId,
      };
      const result = await listGDR.getDetailVideo(params);
      if (result.entities) {
        setVideoDetail(result.entities);
      }
    } catch (error: any) {
      console.warn(error);
      props.addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    }
  };

  const handleDeleteVideo = async (isVideoSide?: boolean) => {
    try {
      const params: IDetailID = {
        detailNo: (isVideoSide ? Number(videoDetail?.sideVideoId!) : Number(videoDetail?.videoId!)) ?? 0,
      };
      const result = await listGDR.deleteVideo(params);
      if (result.code === EnumRequestCode.SUCCESS) {
        const [listData, id] = removeItemMonthObject(listVideo, videoDetail, 'nasmoId', 'statDate', 'YYYY-M-D');
        setListVideo({ ...listData });
        fetchDataDetail(id);
      }
      deleteFeedAfterDeletingSwingVideo(videoDetail?.regFeedNo);
    } catch (error: any) {
      console.warn(error);
      props.addNotification(translate('error.DELETE_SWING_VIDEO_FAILED'), NotificationType.DANGER);
    }
  };

  return (
    <>
      {!!videoDetail && (
        <VideoRegion
          className="sticky top-0 z-10"
          videoDetail={{ ...videoDetail, gdrBallPath: videoDetail.ballPath }}
          classSideWrapper="px-[24px] bg-gz-white"
          expandedPosition={350}
          headerHeight={isDesktop ? 0 : 64}
          haveSideVideo
          handleDelete={handleDeleteVideo}
          videoDescription={<VideoDescription videoDetail={videoDetail} />}
          onPlayVideo={() => isMenucard && firebaseInstance.trackEvent(eventName.MENUCARD_GDRSV_PLAY)}
        />
      )}
      <CustomPullToRefresh
        onRefresh={handleRefresh}
        isPullable={pageInfo.current.loaded > 0 && !isRefreshing}
        showFooter
      >
        <>
          {pageInfo.current.loaded > 0 && !listVideo.keySort?.length ? (
            <NotFoundItem
              image={noImageSwingFeedLight}
              className="pb-[80px]"
              text={translate('pages.record.message.NO_SWING_VIDEO')}
            />
          ) : (
            <InfiniteScrollWrapper loadMore={fetchListData} isLastPage={pageInfo.current.lastPage} loading={loading}>
              <>
                {listVideo.keySort?.map((month: string, idx: number) => {
                  const dateInfo = month.split('-');
                  return (
                    <div
                      key={`month-group-${idx}`}
                      className={twMerge(
                        'p-[24px] [&:not(:last-child)]:border-b-[1px] [&:not(:last-child)]:border-gray-20',
                      )}
                    >
                      <h3 className="gz-text-xl mb-[16px] font-bold">
                        {translate('date.TEXT_FULL_DATE', {
                          year: dateInfo[0],
                          month: translate('date.month.' + dateInfo[1]).substring(0, 3),
                          date: dateInfo[2],
                        })}
                      </h3>
                      <NasmoList
                        hideHole
                        absoluteClubName={false}
                        titleCenter={false}
                        classClubName="text-text"
                        classActiveClubName="text-gz-primary"
                        listNasmo={listVideo?.values[month]}
                        typeLayout={ITypeLayout.GRID}
                        chooseVideo={fetchDataDetail}
                        defaultActive={videoIdDefault}
                      />
                    </div>
                  );
                })}
              </>
            </InfiniteScrollWrapper>
          )}
        </>
      </CustomPullToRefresh>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  userInfo: state.mainInfoReducer.userInfo,
});
const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordGdrSwingVideo);
