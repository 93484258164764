import { useCallback, useLayoutEffect, useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

/**
 * Calculate full screen height because in IOS 100vh includes browser top and bottom bars
 */
export function useCalculateFullScreen() {
  const isNativeApp = useSelector((state: any) => state.webviewReducer.isNativeApp);
  const listener = useRef<boolean>(false);

  const windowHeight = useCallback(() => {
    const doc = document.documentElement;
    if (isMobile && !isNativeApp) {
      doc.style.setProperty('--window-height', `${window.innerHeight}px`);
    } else {
      doc.style.setProperty('--window-height', '100vh');
    }
  }, [isNativeApp, isIOS, isMobile]);

  useLayoutEffect(() => {
    if (!listener.current) {
      window.addEventListener('resize', windowHeight);
      listener.current = true;
    }
    windowHeight();
    setTimeout(() => windowHeight(), 500);

    return () => window.removeEventListener('resize', windowHeight);
  }, [isNativeApp]);
}
