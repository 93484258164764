import { useContext } from 'react';
import { countryCode } from 'src/constants/const';
import { LeaderBoardContext } from 'src/view/pages/leader-board';
import { TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';

const TermsAndConditions = () => {
  const context = useContext(LeaderBoardContext);
  const ContentFooterJP = () => {
    return (
      <ul className="gz-text-sm-lb lg:gz-text-base-lb ml-4 list-disc">
        <li>
          不正行為が発覚した場合、不正行為を行ったプレイヤーはランキングから
          除外した、順位をリーダーボードに公開します。18ホールを完了した場合に
          のみ授賞が可能で、18ホールラウンド未完了時には授賞から除外されます。
        </li>
        <li>1人のプレイヤーが複数のカテゴリーで当選した場合、最も高価な景品に対し てのみ授賞することになります。</li>
        <li>
          景品の内訳は以下の通りです。
          <ul>
            <li>- ゾーン統合1位 : Titleist Hybrid 14 Stand Bag</li>
            <li>- ゾーン別1-5位 : Air Jordan 1 Low G Golf Shoes</li>
            <li>- ゾーン別6-10位 : Titleist Po V1 Ball 1ダース</li>
            <li>- Hole-in-one : GOLFBUDDY距離測定器</li>
            <li>- ゾーン別ラッキードロー : GOLFZON WAVE</li>
          </ul>
        </li>
        <li>
          同点の場合
          <ul>
            <li>- 18番ホールからカウントバックしてスコアカードマッチを通じて受賞者を 決定します。</li>
            <li>
              -ホールインワン賞:5名のホールインワン対象者に対して、ホールインワン達成を
              した順（先着順）に賞品対象者とします。
            </li>
          </ul>
        </li>

        <li>GOLFZONの系列会社や店主などの関係者は、大会への参加は可能ですが、授賞からは除外されます。</li>
        <li>
          受賞者のあらゆる状況などにより賞品を受け取ることが難しい場合、次のランキング対象者が授賞することになります。
        </li>
        <li>
          受賞者が居住している国/地域の法律により、受け取る賞品に対する税金が
          発生する可能性があります。このような場合、発生する税金について
          GOLFZONが負担するのではなく、受賞者本人にご負担いただきます。
        </li>
        <li>
          GOLFZONは当選者が賞品を受け取る資格があり、指定された住所に商品を
          受け取ることを確認した後、30日以内に商品を配送します。
        </li>
        <li>当選者は商品発送のためにGOLFZONに配送される住所を提供しなければ なりません。標準送料は含まれています。</li>
        <li>
          個人情報の取り扱いについて
          <ul>
            <li>
              - お客様の個人情報は、賞品の発送のために利用いたします。
              法令による場合を除き、個人情報をお客様の同意無しに業務委託先を除く
              第三者に開示・提供することは一切ございません。
            </li>
          </ul>
        </li>
        <li>ご不明な点がございましたら、(taikai@golfzon.com)までご連絡ください。</li>
      </ul>
    );
  };

  const ContentFooterEN = () => {
    return (
      <ul className="gz-text-sm-lb lg:gz-text-base-lb ml-4 list-disc">
        <li>
          Rankings will be made public through the Leaderboard page. Rankings will be manually adjusted by excluding
          cheaters.
        </li>
        <li>Only complete 18-hole rounds will be counted. Incomplete 18-hole rounds are ineligible for the prizes. </li>
        <li>If a player wins multiple categories, only the highest-valued prize will be awarded. </li>
        <li>
          Prizes will be awarded as follows:
          <ul>
            <li>- Grand prize for the top player of all three zones: TITLEIST Hybrid 14 Stand Bag</li>
            <li>- Ranking 1 to 5 in each Zone: Air Jordan 1 Low G Golf Shoe</li>
            <li>- Ranking 6 to 10 in each Zone: 1 dozen of Titleist Pro V1</li>
            <li>- 5 Hole-in-one players: GolfBuddy Rangefinder</li>
            <li>- Lucky draw in each Zone: Golfzon Wave Launch Monitor</li>
            <li>
              - In case of being out of stock, an Amazon e-gift card with the same value as the prize will be given.
            </li>
          </ul>
        </li>
        <li>
          In the event of a tie,
          <ul>
            <li>- A scorecard match will be held beginning at No.18 and going backward.</li>
            <li>- Hole-in-One: The first 5 people to get a hole-in-one are eligible for the prize.</li>
          </ul>
        </li>

        <li>
          All GOLFZON affiliates and store owners can participate in this event but will be excluded from receiving
          prizes.
        </li>
        <li>
          If a winner is unable to receive the prize due to circumstances in his or her country, the prize will be
          awarded to the next ranking winner.
        </li>
        <li>
          In certain jurisdictions, prizes may be subject to taxes. All federal, state, and local taxes, customs duties,
          and any other costs and expenses associated with the acceptance or use of prizes are solely the winners’
          responsibility, and GOLFZON has the right to withhold any amount required by law. Winners shall complete any
          applicable tax forms as required in their local territory.
        </li>
        <li>
          GOLFOZN will attempt to ship prizes no later than 30 days following confirmation that winners are eligible and
          wish to receive the prizes at their designated address.
        </li>
        <li>
          Players must provide GOLFZON with their shipping address for prizes to be sent. Standard shipping costs are
          included. Please allow 4-6 weeks of shipping and handling time for prizes to arrive.
        </li>
        <li>
          We will individually send e-mails to winners regarding the delivery of the prizes. If the e-mail address
          registered in Golfzon is invalid or if the winner does not reply within two weeks, the prize will be
          nullified.
        </li>
        <li>For other inquiries, please email hwajingolf@golfzon.com</li>
      </ul>
    );
  };

  const textWithBoldJapan = () => {
    const fullText = TextJapan.content.text_ZoneB;
    const indexOfJapan = fullText.indexOf('日本');
    const textBeforeJapan = fullText.substring(0, indexOfJapan);
    const textAfterJapan = fullText.substring(indexOfJapan + 2);
    if (context?.lang.currentLang == countryCode.USA) {
      return TextGlobal.content.text_ZoneB;
    }
    return (
      <span>
        {textBeforeJapan}
        <strong>{fullText.substring(indexOfJapan, indexOfJapan + 2)}</strong>
        {textAfterJapan}
      </span>
    );
  };

  return (
    <div className=" bg-gray-80 px-4 py-[32px] lg:p-[40px]">
      <p className="gz-text-lg  font-bold">
        {context?.lang.currentLang == countryCode.JAPAN ? TextJapan.content.title : TextGlobal.content.title}
      </p>
      {context?.lang.currentLang == countryCode.JAPAN ? ContentFooterJP() : ContentFooterEN()}
      <p className="gz-text-base mb-2 mt-4 font-bold">
        {context?.lang.currentLang == countryCode.JAPAN
          ? TextJapan.content.title_list.toUpperCase()
          : TextGlobal.content.title_list.toUpperCase()}
      </p>
      <div className="rounded-lg  bg-blue-90 p-4">
        <div className="flex flex-col">
          <div className="flex flex-row pb-4">
            <div className="w-auto text-center ">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 font-bold text-white">Zone A</p>
            </div>
            <div className="ml-[20px] flex-1 text-left">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 text-white">
                {context?.lang.currentLang == countryCode.JAPAN
                  ? TextJapan.content.text_ZoneA
                  : TextGlobal.content.text_ZoneA}
              </p>
            </div>
          </div>
          <div className="flex flex-row border-y-[1px] border-y-white-20 py-4 ">
            <div className="w-auto  text-center ">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 font-bold text-white">Zone B</p>
            </div>
            <div className="ml-[20px] flex-1 text-left">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 text-white">{textWithBoldJapan()}</p>
            </div>
          </div>
          <div className="flex flex-row pt-4">
            <div className="w-auto  text-center ">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 font-bold text-white">Zone C</p>
            </div>
            <div className="ml-[20px] flex-1 text-left">
              <p className="gz-text-sm-lb lg:gz-text-base-lb mb-0 text-white">
                {context?.lang.currentLang == countryCode.JAPAN
                  ? TextJapan.content.text_ZoneC
                  : TextGlobal.content.text_ZoneC}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
