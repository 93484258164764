import cloneDeep from 'lodash/cloneDeep';
import { STORE_NEWS_ACTION_TYPES } from './action';
import { IStoreMapState } from 'src/store/store-map/type';
import { DEFAULT_DISTANCE_AWAY, storeMapConstants } from 'src/constants/const/store-map.constants';
import { EnumSortTypeStoreMap } from 'src/constants/enum';

const initialState: IStoreMapState = {
  searchingStores: {
    data: [],
    total: 0,
    page: 1,
    loading: true,
    loadingCurrentMap: false,
    firstLoaded: true,
  },
  filteringStores: {
    versions: [
      ...storeMapConstants.roundList.map((round) => round.value),
      ...storeMapConstants.practiceList.map((practice) => practice.value),
    ],
    facility: storeMapConstants.facilityList.map((facility) => facility.value),
    anyDistance: true,
    distance: DEFAULT_DISTANCE_AWAY,
    sortType: EnumSortTypeStoreMap.CLOSEST,
  },
  suggestionHistory: {
    data: [],
    loading: true,
    loaded: false,
  },
  autoFill: {
    searchText: '',
    data: [],
    loading: true,
    loadingMore: false,
    loaded: 0,
    hasNext: false,
  },
  mapInfo: {
    title: '',
    firstLoad: true,
    hideFocusedStore: false,
    zoom: 4,
    center: {
      lat: 0,
      lng: 0,
    },
    lockedMove: true,
    nearby: false,
    loadOnCurrentMapBtn: false,
  },
  showMap: true,
  isSearching: true,
  showPermissionAlerts: false,
  pageOffset: 0,
};

export function storeMapReducer(state: IStoreMapState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_SEARCHING_STORE_LIST: {
      return {
        ...state,
        searchingStores: { ...state.searchingStores, ...payload },
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_SET_SHOW_MAP: {
      return {
        ...state,
        ...payload,
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_SUGGESTION_HISTORY: {
      return {
        ...state,
        suggestionHistory: { ...state.suggestionHistory, ...payload },
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_FILTERING_STORES: {
      return {
        ...state,
        filteringStores: { ...state.filteringStores, ...payload },
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_AUTO_FILL: {
      return {
        ...state,
        autoFill: { ...state.autoFill, ...payload },
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_MAP_INFO: {
      return {
        ...state,
        mapInfo: {
          ...state.mapInfo,
          ...payload,
        },
      };
    }
    case STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_PAGE_OFFSET: {
      return {
        ...state,
        pageOffset: payload,
      };
    }
    case STORE_NEWS_ACTION_TYPES.RESET_MAP: {
      return cloneDeep(initialState);
    }
    case STORE_NEWS_ACTION_TYPES.SET_SHOW_PERMISSION_ALERT: {
      return {
        ...state,
        showPermissionAlerts: payload.showAlert,
      };
    }
    default:
      return state;
  }
}
