import dayjs from 'dayjs';
import i18n from 'src/locale';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { LOCALE_TIMEZONE, TUNING_TIME_TIMEZONE, formatDate } from 'src/constants/const';
import { EnumRequestCode, EnumTrainingModeType, pageSize } from 'src/constants/enum';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import {
  IGDRDrivingClubResponse,
  IGDRMiniRoundResponse,
  ITmApproachResponse,
  ITmDrivingRangeResponse,
  ITmPuttingResponse,
  ITmSwingVideoInfoResponse,
} from 'src/requests/api/record/prop-state.type';
import { listGDR } from 'src/requests/api/record/GDR';
import { reverseMonthObject } from 'src/services/gdr-and-gs';
import { GSrecord } from 'src/requests/api/record/GS';
import { IRecordScore } from 'src/models';
import { initialState, IOffsetListRecord, ITmSwingVideosValues, ITmValues } from 'src/store/records/reducer';
import { IVideoDetail } from 'src/view/pages/record/commons/VideoRegion';
import { trainingModeRecord } from 'src/requests/api/record/training-mode';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { Dayjs } from 'src/services/datetime';
import { getTmAvailableMonths, reverseSwingVideoList } from 'src/services/training-mode';
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const RECORS_OFFSET = 'RECORS_OFFSET';
export const RESET_RECORD = 'RESET_RECORD';
export const GS_ROUND = 'GS_ROUND';
export const GS_SWING_VIDEO = 'GS_SWING_VIDEO';
export const GDR_DRIVING = 'GDR_DRIVING';
export const GDR_PRACTICE = 'GDR_PRACTICE';
export const TM_DRIVING_RANGE = 'TM_DRIVING_RANGE';
export const TM_APPROACH = 'TM_APPROACH';
export const TM_PUTTING = 'TM_PUTTING';
export const TM_SWING_VIDEO = 'TM_SWING_VIDEO';

export const records = {
  setOffset: (dataOffset: IOffsetListRecord) => ({
    type: RECORS_OFFSET,
    payload: {
      offsetListRecord: dataOffset,
    },
  }),
  resetRecord: () => ({
    type: RESET_RECORD,
  }),
  gsRound: (dataObject: IObjectData<Record<string, IRecordScore[]>>) => ({
    type: GS_ROUND,
    payload: {
      dataGsRecordScore: dataObject,
    },
  }),
  gsSwingVideo: (dataObject: IObjectData<IRecordScore[]>) => ({
    type: GS_SWING_VIDEO,
    payload: {
      dataGsSwingVideo: dataObject,
    },
  }),
  gdrDriving: (dataObject: IObjectData<Record<string, IGDRDrivingClubResponse[]>>) => ({
    type: GDR_DRIVING,
    payload: {
      dataGdrDriving: dataObject,
    },
  }),
  gdrPractice: (dataObject: IObjectData<Record<string, IGDRMiniRoundResponse[]>>) => ({
    type: GDR_PRACTICE,
    payload: {
      dataGdrPractice: dataObject,
    },
  }),
  tmDrivingRange: (dataObject: ITmValues<ITmDrivingRangeResponse[]>) => ({
    type: TM_DRIVING_RANGE,
    payload: dataObject,
  }),
  tmApproach: (dataObject: ITmValues<ITmApproachResponse[]>) => ({
    type: TM_APPROACH,
    payload: dataObject,
  }),
  tmPutting: (dataObject: ITmValues<ITmPuttingResponse[]>) => ({
    type: TM_PUTTING,
    payload: dataObject,
  }),
  tmSwingVideo: (dataObject: ITmSwingVideosValues) => ({
    type: TM_SWING_VIDEO,
    payload: dataObject,
  }),
};

const getGsRound =
  ({ isLoadingMore, isRefresh }: { isLoadingMore?: boolean; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().recordsReducer.dataGsRecordScore;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    const currentPage = isRefresh ? 1 : prevStateRedux.loaded + 1;
    try {
      dispatch(
        records.gsRound({
          ...prevStateRedux,
          loading: true,
        }),
      );
      const params = {
        page: currentPage,
        rows: pageSize.NORMAL,
      };
      const result = await GSrecord.getScoreList(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get gs round failed');
      }
      if (result.entities) {
        const prevState = isRefresh ? initialState.dataGsRecordScore : prevStateRedux;
        const dataGroup = reverseMonthObject(result.entities, 'timestart', prevState);
        const isLast = result.totalCount <= dataGroup.length || result.entities.length === 0;
        const dataObject = {
          ...dataGroup,
          loaded: currentPage,
          lastPage: isLast,
          loading: false,
        };
        dispatch(records.gsRound(dataObject));
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
      dispatch(
        records.gsRound({
          ...prevStateRedux,
          loaded: currentPage,
          lastPage: true,
          loading: false,
        }),
      );
    }
  };

const getGsSwingVideo =
  ({ isLoadingMore, isRefresh }: { isLoadingMore?: boolean; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().recordsReducer.dataGsSwingVideo;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    const currentPage = isRefresh ? 1 : prevStateRedux.loaded + 1;
    try {
      dispatch(
        records.gsSwingVideo({
          ...prevStateRedux,
          loading: true,
        }),
      );
      const params = {
        page: currentPage,
        rows: pageSize.SPECIAL,
      };
      const result = await GSrecord.getNasmoClubList(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get gs swing videos failed');
      }
      if (result.entities) {
        const prevState = isRefresh ? initialState.dataGsSwingVideo : prevStateRedux;
        const dataGroup = {
          ...prevState,
          values: {
            ...prevState.values,
            data: [...prevState.values.data, ...result.entities],
          },
        };
        const isLast = result.totalCount <= dataGroup.values.data.length || result.entities.length === 0;
        const dataObject = {
          ...dataGroup,
          loaded: currentPage,
          lastPage: isLast,
          loading: false,
        };
        dispatch(records.gsSwingVideo(dataObject));
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
      dispatch(
        records.gsSwingVideo({
          ...prevStateRedux,
          loaded: currentPage,
          lastPage: true,
          loading: false,
        }),
      );
    }
  };

const updateGsSwingVideoDetail = (videoDetail?: IVideoDetail) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().recordsReducer.dataGsSwingVideo;
  dispatch(
    records.gsSwingVideo({
      ...prevStateRedux,
      values: {
        ...prevStateRedux.values,
        videoDetail,
      },
    }),
  );
};

const getGdrDrivingInformation =
  ({ isLoadingMore, isRefresh }: { isLoadingMore?: boolean; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().recordsReducer.dataGdrDriving;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    // @TODO Can Rollback
    // const countMonth = dayjs().diff(prevStateRedux.lastMonth, 'month', true);
    // if (countMonth > EnumNumber.SIX) {
    //   dispatch(
    //     records.gdrDriving({
    //       ...prevStateRedux,
    //       lastPage: true,
    //     }),
    //   );
    //   return;
    // }
    const prevState = isRefresh ? initialState.dataGdrDriving : prevStateRedux;
    try {
      dispatch(
        records.gdrDriving({
          ...prevStateRedux,
          loading: true,
        }),
      );
      const searchDate =
        isRefresh || !prevStateRedux.lastMonth ? dayjs().format(formatDate.BASIC) : prevStateRedux.lastMonth;
      const params = {
        searchDate,
        rows: pageSize.NORMAL,
      };
      const result = await listGDR.getDrivingClubRequest(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get gdr driving failed');
      }
      if (result.entities?.length < 1) {
        const lastState = {
          ...prevState,
          loaded: prevState.loaded + 1,
          lastPage: true,
          loading: false,
        };
        dispatch(records.gdrDriving(lastState));
        return;
      }
      // @TODO Can Rollback
      // const dataGroup = reverseMonthObject(result.entities, 'statDate', prevStateRedux, formatDate.SHORT, EnumNumber.SIX);
      const dataGroup = reverseMonthObject(result.entities, 'statDate', prevState, {
        dataTimezone: LOCALE_TIMEZONE,
      });
      const isLast = result.totalCount <= dataGroup.length;
      const lastMonthInfo = result.entities[result.entities.length - 1].statDate;
      const dataObject = {
        ...dataGroup,
        lastMonth: lastMonthInfo,
        loaded: prevState.loaded + 1,
        lastPage: isLast,
        loading: false,
      };
      dispatch(records.gdrDriving(dataObject));
    } catch (error: any) {
      console.warn(error);
      dispatch(
        records.gdrDriving({
          ...prevStateRedux,
          loaded: prevState.loaded + 1,
          lastPage: true,
          loading: true,
        }),
      );
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const getGdrPraticeRound =
  ({ isLoadingMore, isRefresh }: { isLoadingMore?: boolean; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().recordsReducer.dataGdrPractice;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    const currentPage = isRefresh ? 1 : prevStateRedux.loaded + 1;
    try {
      dispatch(
        records.gdrPractice({
          ...prevStateRedux,
          loading: true,
        }),
      );
      const params = {
        page: currentPage,
        rows: pageSize.NORMAL,
      };
      const result = await listGDR.getMiniRoundlistRequest(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get gdr practice round failed');
      }
      if (result.entities) {
        const prevState = isRefresh ? initialState.dataGdrPractice : prevStateRedux;
        const dataGroup = reverseMonthObject(result.entities, 'tmTimeStart', prevState, {
          dataTimezone: TUNING_TIME_TIMEZONE,
        });
        const isLast = result.totalCount <= dataGroup.length || result.entities.length === 0;
        const dataObject = {
          ...dataGroup,
          loaded: currentPage,
          lastPage: isLast,
          loading: false,
        };
        dispatch(records.gdrPractice(dataObject));
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(
        records.gdrPractice({
          ...prevStateRedux,
          loaded: currentPage,
          lastPage: true,
          loading: false,
        }),
      );
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const getTmDrivingRangeApproachPutting =
  ({ type, date, isRefresh = false }: { type: EnumTrainingModeType; date?: dayjs.Dayjs; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    let prevStateRedux = getState().recordsReducer.dataTmDrivingRange;
    let actionDispatch: any = records.tmDrivingRange;
    if (type === EnumTrainingModeType.APPROACH) {
      prevStateRedux = getState().recordsReducer.dataTmApproach;
      actionDispatch = records.tmApproach;
    } else if (type === EnumTrainingModeType.PUTTING) {
      prevStateRedux = getState().recordsReducer.dataTmPutting;
      actionDispatch = records.tmPutting;
    }
    let newStateRedux = {
      ...prevStateRedux,
      loading: true,
      date,
    };

    try {
      dispatch(actionDispatch(newStateRedux));
      newStateRedux = {
        ...newStateRedux,
        loaded: true,
      };
      let months: dayjs.Dayjs[] = [];
      if (!date || isRefresh) {
        const monthsResult = await trainingModeRecord.getAvailableMonths({
          type,
        });
        if (monthsResult.code !== EnumRequestCode.SUCCESS) {
          throw new Error('get available months failed');
        } else if (!monthsResult.entities || monthsResult.entities.length === 0) {
          dispatch(
            actionDispatch({
              ...newStateRedux,
              loading: false,
            }),
          );
          return;
        }
        months = getTmAvailableMonths(monthsResult.entities, formatDate.YEAR_AND_MONTH);
        newStateRedux = {
          ...newStateRedux,
          months,
          date: dayjs(months[0], formatDate.YEAR_AND_MONTH),
        };
      }
      const month: dayjs.Dayjs = date != null ? date : dayjs(months[0], formatDate.YEAR_AND_MONTH);
      const params = {
        type,
        startTime: Dayjs.convertDayjsToEst(month.startOf('month')).format(formatDate.DATE_TIME_NORMAL),
        endTime: Dayjs.convertDayjsToEst(month.endOf('month')).format(formatDate.DATE_TIME_NORMAL),
      };
      const result = await trainingModeRecord.getDrivingApproachPutting(params);
      if (result.code === EnumRequestCode.FAILED) {
        throw new Error('get data failed');
      }
      dispatch(
        actionDispatch({
          ...newStateRedux,
          data: result.entities,
          loading: false,
        }),
      );
    } catch (error: any) {
      console.warn(error);
      dispatch(
        actionDispatch({
          ...newStateRedux,
          loading: false,
        }),
      );
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const getTmSwingVideo =
  ({ isLoadingMore, isRefresh }: { isLoadingMore?: boolean; isRefresh?: boolean }) =>
  async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().recordsReducer.dataTmSwingVideo;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    const currentPage = isRefresh ? 1 : prevStateRedux.loaded + 1;
    try {
      dispatch(
        records.tmSwingVideo({
          ...prevStateRedux,
          loading: true,
        }),
      );
      const params = {
        page: currentPage,
        rows: pageSize.SMALL,
      };
      const result = await trainingModeRecord.getSwingVideo(params);
      if (result.code !== EnumRequestCode.SUCCESS) {
        throw new Error('get swing video failed');
      }
      const prevState = isRefresh ? initialState.dataTmSwingVideo : prevStateRedux;
      const reverseData = reverseSwingVideoList(result.entities, prevState.data);
      const dataObject = {
        ...prevStateRedux,
        data: reverseData.data,
        loaded: currentPage,
        lastPage: reverseData.isLast,
        loading: false,
      };
      dispatch(records.tmSwingVideo(dataObject));
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
      dispatch(
        records.tmSwingVideo({
          ...prevStateRedux,
          loaded: currentPage,
          lastPage: true,
          loading: false,
        }),
      );
    }
  };

const updateTmSwingVideoDetail = (videoDetail?: ITmSwingVideoInfoResponse) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().recordsReducer.dataTmSwingVideo;
  dispatch(
    records.tmSwingVideo({
      ...prevStateRedux,
      videoDetail,
    }),
  );
};

export const recordsAction = {
  getGsRound,
  getGsSwingVideo,
  getTmSwingVideo,
  updateGsSwingVideoDetail,
  getGdrDrivingInformation,
  getGdrPraticeRound,
  getTmDrivingRangeApproachPutting,
  updateTmSwingVideoDetail,
};
