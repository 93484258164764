import dayjs from 'dayjs';
import { groupBy, remove } from 'lodash';
import { API_TIMEZONE, formatDate } from 'src/constants/const';
import { EnumPlayMode, EnumTypeClub } from 'src/constants/enum';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import { DateTime } from 'src/services/datetime';

export const getClubName = (clubCode: string): string => {
  if (clubCode === '1') return 'DR';
  if (clubCode === '2') return 'W2';
  if (clubCode === '3') return 'W3';
  if (clubCode === '4') return 'W4';
  if (clubCode === '5') return 'W5';
  if (clubCode === '6') return 'W6';
  if (clubCode === '7') return 'W7';
  if (clubCode === '8') return 'I1';
  if (clubCode === '9') return 'I2';
  if (clubCode === '10') return 'I3';
  if (clubCode === '11') return 'I4';
  if (clubCode === '12') return 'I5';
  if (clubCode === '13') return 'I6';
  if (clubCode === '14') return 'I7';
  if (clubCode === '15') return 'I8';
  if (clubCode === '16') return 'I9';
  if (clubCode === '17') return 'PW';
  if (clubCode === '18') return 'AW';
  if (clubCode === '19') return 'SW';
  if (clubCode === '20') return 'PT';
  if (clubCode === '21') return 'W8';
  if (clubCode === '22') return 'W9';
  if (clubCode === '23') return 'U1';
  if (clubCode === '24') return 'U2';
  if (clubCode === '25') return 'U3';
  if (clubCode === '26') return 'U4';
  if (clubCode === '27') return 'U5';
  if (clubCode === '28') return 'U6';
  if (clubCode === '29') return 'U7';
  if (clubCode === '30') return 'LW';
  return ''; // This code should never be reached
};

export const getTypeClub = (clubNo: string): any => {
  let type;
  switch (clubNo) {
    case '1':
      type = EnumTypeClub.DRIVER;
      break;
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '21':
    case '22':
      type = EnumTypeClub.WOOD;
      break;
    case '23':
    case '24':
    case '25':
    case '26':
    case '27':
    case '28':
    case '29':
      type = EnumTypeClub.UTILITY;
      break;
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
    case '16':
      type = EnumTypeClub.IRON;
      break;
    case '17':
    case '18':
    case '19':
    case '30':
      type = EnumTypeClub.WEDGES;
      break;
    case '20':
      type = EnumTypeClub.PUTTER;
      break;
    default:
      break;
  }
  return type;
};

export const getPlayMode = (playMode: number, playModeSub?: number): EnumPlayMode => {
  const valueMode = playModeSub != null && playModeSub > 0 ? `${playMode}-${playModeSub}` : `${playMode}`;
  return valueMode as EnumPlayMode;
};

interface IReverseMonthOptions {
  formatDate?: string;
  monthDisplay?: number;
  dataTimezone?: string;
}

const initializedOptions: IReverseMonthOptions = {
  formatDate: formatDate.SHORT,
  dataTimezone: API_TIMEZONE,
};

export const reverseMonthObject = (
  respondData: any[],
  field: string,
  dataDefault: IObjectData<Record<string, any[]>>,
  defaultOptions: IReverseMonthOptions = initializedOptions,
) => {
  const dataInfo: any = [];
  const data = { ...dataDefault };
  const options = {
    ...initializedOptions,
    ...defaultOptions,
  };
  respondData.forEach((element: any, index: number) => {
    // don't collect data if it is more than N months
    const monthsAgo = dayjs().diff(element[field], 'month', true);
    if (options.monthDisplay && monthsAgo > options.monthDisplay) {
      return;
    }
    // Update Timezone
    dataInfo[index] = {
      ...respondData[index],
      [field]: DateTime.getLocaleDatetime(element[field], options.dataTimezone),
    };
  });
  // group response data follow YEAR-MONTH
  const itemGroup = groupBy(dataInfo, (item) => dayjs(item[field]).format(options.formatDate));
  const keyMonth = Object.keys(itemGroup);
  // Merge MONTH and "sort" when load more
  const listKey = Array.from(new Set([...dataDefault.keySort, ...keyMonth]));
  listKey.sort((a: string, b: string) => {
    return dayjs(b).diff(a, 'day');
  });
  // Merge data when load more
  keyMonth.forEach((key: string) => {
    if (data.values[key]) {
      data.values[key] = [...data.values[key], ...itemGroup[key]];
    } else {
      data.values = {
        ...data.values,
        [key]: [...itemGroup[key]],
      };
    }
  });
  return {
    ...data,
    keySort: listKey,
    length: data.length + respondData.length,
  };
};

export const removeItemMonthObject = (
  dataDefault: IObjectData<Record<string, any[]>>,
  videoInfo?: any,
  fieldId: string = 'nasmoId',
  field: string = 'statDate',
  formatDate: string = 'YYYY-M',
  isNextItem?: boolean,
) => {
  const dateItem = dayjs(videoInfo[field]).format(formatDate);
  // Remove Item
  remove(dataDefault?.values[dateItem], function (item: any) {
    return item[fieldId] === videoInfo[fieldId];
  });
  if (dataDefault?.values[dateItem].length === 0) {
    remove(dataDefault?.keySort, function (item: any) {
      return item === dateItem;
    });
  }
  // Update Item Active
  const newDate = !isNextItem ? dataDefault?.keySort[0] : dateItem;
  const idActive = dataDefault?.values[newDate][0][fieldId];
  return [dataDefault, idActive];
};

export const isBetterValue = (comparedValue: number, comparingValue: number, sign?: string) => {
  if (comparedValue == null || comparingValue == null || !sign) return false;
  if (sign === '>') {
    return comparedValue > comparingValue;
  } else if (sign === '<') {
    return comparedValue < comparingValue;
  }
  return false;
};
