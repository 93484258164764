import { MAX_STANDARD_HOLE_COUNT, scorecardConstants } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import { customHeader, generateTableHeaders, getMatchTextColor, getMatchValue } from 'src/services/scorecard-modes';
import { twMerge } from 'tailwind-merge';

export const matchHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [customHeader.HOLE(normalColor), customHeader.BASE_SCORE(customColor, totalHoleCnt)],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.MATCH_KEY,
    color: normalColor,
  });
};

export const matchData = (gsScoreCard: any[]) => {
  // Move par and current user score to top
  const scorecardData = [...gsScoreCard];
  const currentUserIndex = scorecardData.findIndex((data) => data.ordernum === 2);
  const currentUserScore = scorecardData[currentUserIndex][scorecardConstants.MATCH_TOTAL_KEY];
  scorecardData.unshift(scorecardData.splice(currentUserIndex, 1)[0]);
  const parIndex = scorecardData.findIndex((data) => data.usrId === scorecardConstants.PAR_ID_VALUE);
  scorecardData.unshift(scorecardData.splice(parIndex, 1)[0]);
  const parData = scorecardData[0];
  const dataList = scorecardData.map((scoreCard, index) => {
    const data = {} as any;
    data.isPar = scoreCard.usrId === scorecardConstants.PAR_ID_VALUE;
    data.isCurrentUser = scoreCard.ordernum === 2;

    data[scorecardConstants.HOLE_KEY] = {
      value: data.isPar ? scorecardConstants.PAR_NAME_VALUE : scoreCard[scorecardConstants.USER_NICKNAME_KEY],
      className: twMerge('px-[8px] !border-l-0', data.isPar ? 'bg-bg text-sub-body' : 'bg-gz-white'),
    };

    data[scorecardConstants.BASE_SCORE_KEY] = {
      value: data.isPar
        ? scoreCard[scorecardConstants.BASE_SCORE_KEY]
        : getMatchValue(scoreCard[scorecardConstants.MATCH_TOTAL_KEY], {
            isBaseScore: true,
            isCurrentUser: data.isCurrentUser,
            hasSameScore: Number(scoreCard[scorecardConstants.MATCH_TOTAL_KEY]) === 0,
            currentUserScore,
          }),
      className: twMerge(
        'text-center',
        !data.isPar
          ? `gz-text-sm font-bold ${
              scoreCard[scorecardConstants.MATCH_TOTAL_KEY] === 0
                ? 'text-sub-body'
                : scoreCard[scorecardConstants.MATCH_TOTAL_KEY] > 0
                ? 'text-gz-danger'
                : 'text-primary-75'
            }`
          : 'bg-bg',
      ),
    };

    const prefix = data.isPar ? scorecardConstants.HOLE_NO_KEY : scorecardConstants.MATCH_KEY;
    for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
      const isEmptyHole = Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === 0;
      if (isEmptyHole && !data.isPar) {
        data[`${scorecardConstants.MATCH_KEY}${i}`] = {
          value: scorecardConstants.ALTERNATIVE_HIDDEN_SCORE,
          className: 'text-center',
        };
        continue;
      }
      data[`${scorecardConstants.MATCH_KEY}${i}`] = {
        value: data.isPar ? scoreCard[`${prefix}${i}`] : getMatchValue(scoreCard[`${prefix}${i}`]),
        className: twMerge(
          'text-center',
          data.isPar ? 'bg-bg text-sub-body' : getMatchTextColor(scoreCard[`${prefix}${i}`]),
        ),
      };
    }
    return data;
  });
  const currentUserData = dataList
    .filter((data: any) => data.isPar || data.isCurrentUser)
    .map((data: any) => {
      return {
        ...data,
        [scorecardConstants.HOLE_KEY]: data.isCurrentUser
          ? {
              ...data[scorecardConstants.HOLE_KEY],
              value: scorecardConstants.LEADZONE_NAME,
            }
          : data[scorecardConstants.HOLE_KEY],
      };
    });
  const otherUsersData = dataList;
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList: [],
  };
};
