import { FLAG_COUNTRY, RATIO_KM_TO_MILES } from 'src/constants/const';
import { getCountries } from 'src/constants/const/countries.constants';
import { EnumStoreDistance, EnumValueBoolean } from 'src/constants/enum';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export function numberFormat(number?: number | string) {
  if (number == null) return '';
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(Number(number));
}

export function numberWithSign(number?: number | string) {
  if (number == null) return '';
  return `${Number(number) > 0 ? '+' : ''}${number}`;
}

export function booleanValue(value?: string) {
  if (!value) return false;
  return value?.toUpperCase() === EnumValueBoolean.TRUE;
}

export function booleanToYn(value?: boolean) {
  return value ? EnumValueBoolean.TRUE : EnumValueBoolean.FALSE;
}

export function getFlagByCountryCd(countryCd?: string) {
  if (countryCd == null) return null;
  const flagCountry = FLAG_COUNTRY.find((country) => country.countryCd === countryCd.toLocaleUpperCase());
  if (!flagCountry) return null;
  return flagCountry.flag;
}

export function getPhoneCountry(translate: any, countryCd: string) {
  const groupCountryList = getCountries(translate);
  let currentCountry;
  for (const group of groupCountryList) {
    for (const country of group.valueGroup) {
      if (country.countryCd === countryCd) {
        currentCountry = country;
        break;
      } else if (!currentCountry) {
        currentCountry = country;
      }
    }
  }

  return currentCountry;
}

export function difficultyValue(value: string) {
  return ((Number(value) + 1) / 2).toFixed(1);
}

export function calculateFloat(float: number, decimal?: number) {
  if (float == null) return 0;
  const defaultDecimal = 2;
  return parseFloat(float.toFixed(decimal ?? defaultDecimal));
}

export function removePlus(value?: string | number) {
  if (value == null) return '0';
  return value.toString().replace('+', '');
}

export const hideCharacterLast = (text: string, length: number): string => {
  if (!text) return '';
  if (text.length < length) return Array(text.length + 1).join('*');
  return text.substring(0, text.length - length) + Array(length + 1).join('*');
};

export const fixDecimal = (value?: number | string, sign = false, decimal = 2): string => {
  if (value == null) return '';
  const number = Number(fixDecimalWithoutRounding(Number(value), decimal));
  const numberStr = number.toFixed(decimal);
  if (!sign) return numberStr;
  return `${number >= 0 ? '+' : ''}${numberStr}`;
};

export const fixDecimalWithoutRounding = (number?: number, decimal = 1): string => {
  if (number == null) return '';
  const numberStr = number.toString();
  const dotIndex = numberStr.indexOf('.');
  if (dotIndex > -1) {
    return parseFloat(numberStr.slice(0, dotIndex + decimal + 1)).toString();
  }
  return numberStr;
};

export const ConvertDistance = {
  meterToYard: (value: number) => value * 1.0936,
  yardToMeter: (value: number) => value * 0.9144,
};
export const formatNumberAbbreviation = (number: number) => {
  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 });

  if (number >= 1000000) {
    const formattedNumber = formatter.format(number / 1000000);
    return formattedNumber + 'm';
  } else if (number >= 1000) {
    const formattedNumber = formatter.format(number / 1000);
    return formattedNumber + 'k';
  } else {
    return formatter.format(number);
  }
};

export function truncateString(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
}

export const isEqualArrays = (array1: any[] = [], array2: any[] = []) => {
  return isEqual(sortBy(array1), sortBy(array2));
};

export const convertKmToMiles = (km: number, decimal: number = 1) => {
  const miles = (km * RATIO_KM_TO_MILES).toFixed(decimal);
  const unitValue = `${EnumStoreDistance.MILE}${Number(miles) === 1 ? '' : 's'}`;

  return `${miles} ${unitValue}`;
};
