import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { flagGolfzon, IconLocation } from 'src/assets/svg';
import { countryCode, RUSSIA_COUNTRY_CODE, scorecardConstants } from 'src/constants/const';
import { getCountries } from 'src/constants/const/countries.constants';
import axios from 'src/requests';
import { ICountryGroup } from 'src/requests/api/account/prop-state.type';
import { scorecard } from 'src/services/scorecard-modes';
import AvatarImage from 'src/view/commons/customs/AvatarImage';
import LeaderboardRank from 'src/view/commons/customs/LeaderboardRank';
import { EnumDistance, TextGlobal, TextJapan } from 'src/view/pages/leader-board/constants';
import ScoreNotes from 'src/view/pages/record/score-card/ScoreNotes';
import ScoreTable from 'src/view/pages/record/score-card/ScoreTable';
import { twMerge } from 'tailwind-merge';

interface Props {
  item?: any;
  sameRank?: boolean;
  lang?: string;
  isCheck?: boolean;
}

const ItemTable = (props: Props) => {
  const { item, sameRank, lang, isCheck } = props;
  const translate = useTranslation().t;
  const [isOpen, setIsOpen] = useState(false);
  const countries: ICountryGroup[] = useMemo(() => getCountries(translate), [translate]);
  const [arr, setArr] = useState<any>([]);
  const [statistics, setStatistics] = useState({
    greenRate: 0,
    fairwayRate: 0,
    averageDistance: 0,
    longestDistance: 0,
    distanceUnit: 'm',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const table = () => {
    return {
      myTableHeader: scorecard.newPerioHeader(
        'bg-primary-50',
        'bg-primary-75',
        18,
        scorecard.newPerioData(arr).hiddenHoleList,
      ),
      tableData: scorecard.newPerioData(arr).currentUserData,
    };
  };

  const findCountry = (countryCode: string) => {
    if (countryCode === RUSSIA_COUNTRY_CODE) return flagGolfzon;

    let appCountryData: any;
    countries?.find((group) => {
      const value = group?.valueGroup?.find((country) => country.countryCd === countryCode);
      appCountryData = value;
      return !!value;
    });
    return appCountryData?.flag || 'JPN';
  };

  const renderRow = (colorImg: string, colorTxt: string, textBold: string) => {
    return (
      <tr
        className="cursor-pointer border-t-[1px]"
        style={{ backgroundColor: isOpen ? '#EBF5FE' : '#ffff' }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <td className="flex items-center justify-center py-3">
          <LeaderboardRank
            className="gz-text-sm flex h-[32px] w-full items-center justify-center text-center font-bold"
            rank={item.rankOfUser}
            sameRank={sameRank}
          />
        </td>
        <td className="text-left">
          <div className="flex items-center">
            <div className={twMerge(`avt-border-gradient relative flex-shrink-0 rounded-full ${colorImg}`)}>
              <AvatarImage
                classContainer={`flex-shrink-0 rounded-full h-fit`}
                classImg={`h-[30px] w-[30px] rounded-full lg:h-[38px] lg:w-[38px] ${colorImg}`}
                src={item.userNickImage}
              />
            </div>
            <div className="ml-4 mr-3 ">
              <img src={findCountry(item?.userCountryCode)} alt="" className="h-[16px] w-[16px] rounded-full" />
            </div>
            <div className={`gz-text-base min-w-0 break-all ${colorTxt} ${textBold}`}>{item?.usrNickName}</div>
          </div>
        </td>
        <td className={`text-center font-bold ${colorTxt}`}>{item?.score}</td>
      </tr>
    );
  };

  const checkRankRow = () => {
    switch (item?.rankOfUser) {
      case 1:
        return renderRow('bg-border-top1', 'text-orange-85', 'font-bold');
      case 2:
        return renderRow('bg-border-top2', 'text-blue-90', 'font-bold');
      case 3:
        return renderRow('bg-border-top3', 'text-orange-95', 'font-bold');
      default:
        return renderRow('bg-border-top', 'text-black', '');
    }
  };

  const convertToNewPerioScoreCard = (data: any) => {
    const scoreCard = [];
    //change par key, hole key
    const parInfo: any = {};
    Object.keys(data?.par).forEach((key) => {
      const newKey = key.replace('par', 'holeNo')?.replace('No0', 'No');
      parInfo[newKey] = data?.par[key]?.toString();
    });
    const holeInfo: any = {};
    Object.keys(data?.hole).forEach((key) => {
      const newKey = key.replace('hole', 'holeNo')?.replace('No0', 'No');
      holeInfo[newKey] = data?.hole[key] === -1 ? '100' : data?.hole[key]?.toString();
    });

    scoreCard[0] = {
      ...parInfo,
      baseScore: data?.basicPar,
      ordernum: 99,
      usrId: scorecardConstants.PAR_ID_VALUE,
      usrNickimg: scorecardConstants.PAR_ID_VALUE,
      usrNicknm: scorecardConstants.PAR_ID_VALUE,
      hiddentotscore: 40,
    };
    scoreCard[1] = {
      ...holeInfo,
      baseScore: data?.totalPar,
      ordernum: 1,
      usrId: data?.user?.userId,
      usrNickimg: data?.user?.userNickImage,
      usrNicknm: data?.user?.userNickName,
      hiddentotscore: 0,
      handicap: 0,
      newparioscore: 0,
    };

    return scoreCard;
  };

  const getDetailItem = async () => {
    setLoading(true);
    await axios
      .get('tournament/score-card', {
        params: {
          unit: isCheck ? EnumDistance.METERS : EnumDistance.YARDS,
          roundId: item?.roundId,
        },
      })
      .then((res) => {
        setStatistics(res.data?.data?.entities?.statistics);
        setArr(convertToNewPerioScoreCard(res.data?.data?.entities) || []);
      })
      .catch((err) => {
        setArr([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getDetailItem();
    }
  }, [isOpen, isCheck]);

  const skeleton = () => (
    <tr className="odd:bg-primary-10 even:bg-primary-10">
      <td colSpan={3}>
        <div className="rounded-[8px] pt-[8px] pb-[18px]">
          <div className="flex items-center justify-between bg-primary-50 p-[12px_18px]">
            <div className="h-[14px] w-[120px] animate-pulse rounded-[4px] bg-gz-white" />
          </div>
          <div className="mt-[16px] px-[18px]">
            <div>{lang === countryCode.JAPAN ? TextJapan.ROUND_RECORD : TextGlobal.ROUND_RECORD}</div>
            <div className="mt-[8px] flex cursor-pointer items-center">
              <div className="h-[16px] w-[180px] animate-pulse rounded-[4px] bg-disable" />
            </div>
            <div className="gz-text-xsm mt-[14px] flex items-center justify-between">
              <div className="text-light-blue-10">
                <div>{lang === countryCode.JAPAN ? TextJapan.AVERAGE_DISTANCE : TextGlobal.AVERAGE_DISTANCE}</div>
                <div className="mt-[8px]">
                  {lang === countryCode.JAPAN ? TextJapan.LONGEST_DISTANCE : TextGlobal.LONGEST_DISTANCE}
                </div>
              </div>
              <div>
                <div className="h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
                <div className="mt-[8px] h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
              </div>
              <div className="text-light-blue-10">
                <div>
                  {lang === countryCode.JAPAN ? TextJapan.FAIRWAY_LANDING_RATE : TextGlobal.FAIRWAY_LANDING_RATE}
                </div>
                <div className="mt-[8px]">
                  {lang === countryCode.JAPAN ? TextJapan.GREEN_RATE : TextGlobal.GREEN_RATE}
                </div>
              </div>
              <div>
                <div className="h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
                <div className="mt-[8px] h-[12px] w-[40px] animate-pulse rounded-[4px] bg-disable" />
              </div>
            </div>
          </div>
          <div className="mt-[16px] px-[18px]">
            <div className="h-[100px] w-full animate-pulse rounded-[4px] bg-disable" />
          </div>
          <ScoreNotes className={twMerge('px-[18px] pt-[16px]', isMobile ? 'justify-between gap-0' : 'gap-[14px]')} />
        </div>
      </td>
    </tr>
  );

  return (
    <tbody>
      {checkRankRow()}
      {!isOpen
        ? ''
        : loading
        ? skeleton()
        : arr.length > 0 && (
            <tr className="odd:bg-primary-10 even:bg-primary-10">
              <td colSpan={3}>
                <div className="cursor-pointer border-0 bg-primary-10 p-4">
                  {/*  */}
                  <div className="font-medium text-black">
                    <div>{lang === countryCode.JAPAN ? TextJapan.ROUND_RECORD : TextGlobal.ROUND_RECORD}</div>
                  </div>
                  <div className="mt-[8px] flex cursor-pointer items-center py-[6px]">
                    <IconLocation className="flex-shrink-0 stroke-text" />
                    <div className="ml-[8px] font-medium">{item?.storeName}</div>
                  </div>
                  <div className="gz-text-xsm mt-[8px] flex items-center justify-between">
                    <div className={twMerge('leading-[16px] text-light-blue-10', isMobile && 'gz-text-xtiny ')}>
                      <div className="text-light-blue-10">
                        <div>
                          {lang === countryCode.JAPAN ? TextJapan.AVERAGE_DISTANCE : TextGlobal.AVERAGE_DISTANCE}
                        </div>
                        <div className="mt-[8px]">
                          {lang === countryCode.JAPAN ? TextJapan.LONGEST_DISTANCE : TextGlobal.LONGEST_DISTANCE}
                        </div>
                      </div>
                    </div>
                    <div className="font-medium">
                      <div>
                        {statistics.averageDistance} {statistics.distanceUnit || 'yd'}
                      </div>
                      <div className="mt-[8px]">
                        {statistics.longestDistance} {statistics.distanceUnit || 'yd'}
                      </div>
                    </div>
                    <div className={twMerge('leading-[16px] text-light-blue-10', isMobile && 'gz-text-xtiny ')}>
                      {lang === countryCode.JAPAN ? TextJapan.FAIRWAY_LANDING_RATE : TextGlobal.FAIRWAY_LANDING_RATE}
                      <div className="mt-[8px]">
                        {lang === countryCode.JAPAN ? TextJapan.GREEN_RATE : TextGlobal.GREEN_RATE}
                      </div>
                    </div>
                    <div className="font-medium">
                      <div>{statistics.fairwayRate}%</div>
                      <div className="mt-[8px]">{statistics.greenRate}%</div>
                    </div>
                  </div>
                  <div className="mt-[8px]">
                    <ScoreTable
                      headers={scorecard.strokeHeader('bg-blue-95', 'bg-blue-95', 18)}
                      data={table().tableData}
                    />
                  </div>
                  <ScoreNotes className={twMerge('mt-[14px] justify-between')} />
                </div>
              </td>
            </tr>
          )}
    </tbody>
  );
};
export default ItemTable;
