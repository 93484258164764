import { MAX_STANDARD_HOLE_COUNT, scorecardConstants, scoreCardWidths } from 'src/constants/const';
import { EnumPrefixKey } from 'src/constants/enum';
import {
  customHeader,
  generateTableHeaders,
  getBgColor,
  getHoleScore,
  getTeamName,
  rearrangeTeamScorecardList,
} from 'src/services/scorecard-modes';
import { booleanValue } from 'src/services/utils';
import { twMerge } from 'tailwind-merge';

export const bestBallStrokeHeader = (normalColor: string, customColor: string, totalHoleCnt?: number) => {
  return generateTableHeaders({
    customColumns: [
      customHeader.TEAM(normalColor),
      customHeader.HOLE(normalColor, scoreCardWidths.HOLE_WITH_TEAM_WIDTH),
      customHeader.BASE_SCORE(customColor, totalHoleCnt),
    ],
    totalHoleCnt: totalHoleCnt ?? MAX_STANDARD_HOLE_COUNT,
    prefixHoleKey: EnumPrefixKey.HOLE_NO_KEY,
    color: normalColor,
  });
};

export const bestBallStrokeData = (gsScoreCard: any[]) => {
  const newGsScoreCard = rearrangeTeamScorecardList(gsScoreCard);
  const parData = newGsScoreCard[0];
  const dataList = newGsScoreCard.map((scoreCard, index) => {
    const data = {} as any;
    data.isPar = scoreCard.usrId === scorecardConstants.PAR_ID_VALUE;
    data.isCurrentUser = booleanValue(scoreCard.searchplayer);

    data[scorecardConstants.HOLE_KEY] = {
      value: data.isPar ? scorecardConstants.PAR_NAME_VALUE : scoreCard[scorecardConstants.USER_NICKNAME_KEY],
      className: twMerge('px-[8px] !border-l-0', data.isPar ? 'bg-bg text-sub-body' : 'bg-gz-white'),
    };

    const teamName = getTeamName(scoreCard[scorecardConstants.TEAM_KEY]?.toString() ?? '');
    data[scorecardConstants.TEAM_KEY] = {
      value: !data.isPar ? teamName.name : '',
    };
    let rowSpan = 1;
    if (scoreCard[scorecardConstants.TEAM_KEY]) {
      data[scorecardConstants.TEAM_KEY].className = twMerge(
        '!border-l-0 px-[8px] text-center',
        data.isPar ? 'text-sub-body' : 'text-gz-white',
        data.isPar ? 'bg-bg' : teamName.style,
      );
    }
    if (
      scoreCard[scorecardConstants.TEAM_KEY] &&
      scoreCard[scorecardConstants.TEAM_KEY] === newGsScoreCard[index - 1]?.team
    ) {
      return data;
    }
    if (scoreCard[scorecardConstants.TEAM_KEY]) {
      rowSpan = newGsScoreCard.filter(
        (el) => el[scorecardConstants.TEAM_KEY] === scoreCard[scorecardConstants.TEAM_KEY],
      ).length;
    }

    data[scorecardConstants.BASE_SCORE_KEY] = {
      value: scoreCard[scorecardConstants.BASE_SCORE_KEY],
      className: twMerge('text-center', !data.isPar ? 'gz-text-sm font-bold' : 'bg-bg'),
      rowSpan,
    };

    for (let i = 1; i <= MAX_STANDARD_HOLE_COUNT; i++) {
      const isEmptyHole = Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]) === 0;
      if (isEmptyHole && !data.isPar) {
        data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
          value: scorecardConstants.ALTERNATIVE_HIDDEN_SCORE,
          className: 'text-center',
        };
        continue;
      }
      data[`${scorecardConstants.HOLE_NO_KEY}${i}`] = {
        value: getHoleScore(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
        className: twMerge(
          'text-center',
          !data.isPar && parData
            ? getBgColor(
                Number(scoreCard[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
                Number(parData[`${scorecardConstants.HOLE_NO_KEY}${i}`]),
              )
            : '',
          data.isPar ? 'bg-bg text-sub-body' : '',
        ),
        rowSpan,
      };
    }
    return data;
  });
  const currentUserData = dataList
    .filter((data: any) => data.isPar || data.isCurrentUser)
    .map((data: any) => {
      return {
        ...data,
        [scorecardConstants.HOLE_KEY]: data.isCurrentUser
          ? {
              ...data[scorecardConstants.HOLE_KEY],
              value: scorecardConstants.SCORE_NAME,
            }
          : data[scorecardConstants.HOLE_KEY],
      };
    });
  const otherUsersData = dataList;
  return {
    currentUserData,
    otherUsersData,
    hiddenHoleList: [],
  };
};
