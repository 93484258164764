import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { firebaseInstance } from 'src/init-firebase';
import { history } from 'src/services/history';
import Header from 'src/view/layouts/global/Header';
import { twMerge } from 'tailwind-merge';

interface ITab {
  title: string | ReactNode;
  path: string;
  eventName?: string;
  startWith?: boolean;
}

interface IProps {
  classContainer?: string;
  className?: string;
  tabs: ITab[];
}

export default function HeaderTabsCustom({ classContainer, className, tabs }: IProps) {
  const { pathname } = useLocation();
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef<any>([]);

  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => tab.path === pathname || (tab.startWith && pathname.startsWith(tab.path)));
  }, [pathname]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTimeout(() => {
      setTabPosition();
    }, 1);
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTabIndex]);

  return (
    <Header
      className={className}
      heightHeader="h-[48px]"
      classHeaderContainer={twMerge('px-0', classContainer)}
      classTitle="ml-0"
      canBack={false}
      title={
        <div className="grid h-[48px]" style={{ gridTemplateColumns: `repeat(${tabs.length}, minmax(0, 1fr))` }}>
          {tabs.map((tab, index) => (
            <div
              ref={(el) => (tabsRef.current[index] = el)}
              className={twMerge(
                'gz-text-xs flex cursor-pointer items-center justify-center',
                pathname === tab.path ? 'font-medium' : 'font-normal',
              )}
              key={index}
              onClick={() => {
                if (tab.eventName) firebaseInstance.trackEvent(tab.eventName);
                history.replace(tab.path);
              }}
            >
              {tab.title}
              <span
                className="absolute bottom-0 block h-[4px] rounded-[4px] bg-primary-76 transition-all duration-300"
                style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
              />
            </div>
          ))}
        </div>
      }
    />
  );
}
