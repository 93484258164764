import axios from 'axios';
import FileSaver from 'file-saver';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IconChervonUp, IconEllipsisVertical } from 'src/assets/svg';
import { mobileAction } from 'src/constants/const';
import { EnumGdrBallPathCode, EnumTmBallPathCode } from 'src/constants/enum';
import { EnumFeedType } from 'src/constants/enum/feed.enum';
import { sendDataToApp } from 'src/hooks/webview';
import { NotificationType } from 'src/models';
import { storeFeed } from 'src/requests/api/feed/store-news';
import { fixDecimal } from 'src/services/utils';
import { notificationActions } from 'src/store/notification';
import BallDirection from 'src/view/commons/customs/BallDirection';
import ConfirmAlertDialog from 'src/view/commons/customs/ConfirmAlertDialog';
import VideoCustom from 'src/view/commons/customs/Video';
import DropdownMenu from 'src/view/commons/elements/DropdownMenu';
import { twMerge } from 'tailwind-merge';

enum EnumVideoAction {
  SWING_ANALYSIS = '1',
  SAVE = '2',
  DELETE = '3',
}

export interface IVideoDetail {
  pgCode?: number;
  nasmoFile: string;
  nasmoImgFile?: string;
  nasmoSideFile?: string;
  nasmoSideImgFile?: string;
  clubName: string;
  distance: string;
  distUnit: string;
  gdrBallPath?: EnumGdrBallPathCode;
  tmBallPath?: EnumTmBallPathCode;
  holeNo?: string;
  nasmoId: number;
  regFeedNo: number;
  videoId?: string;
  sideVideoId?: string;
  clubNm?: string;
  dist?: string;
}

interface IVideoRegionProps {
  className?: string;
  classSideWrapper?: string;
  videoDescription?: React.ReactNode;
  videoDetail: IVideoDetail;
  distanceDecimal?: number;
  haveSideVideo?: boolean;
  isNativeApp?: boolean;
  darkMode?: boolean;
  expandedPosition?: number;
  headerHeight?: number;
  hideSuffix?: boolean;
  saveErrorMessage?: string;
  handleDelete?: (isVideoSide?: boolean) => void;
  addNotification: (message: string, type: NotificationType) => void;
  onPlayVideo?: () => void;
  onSideViewClick?: () => void;
  onFrontViewClick?: () => void;
}

const VideoRegion = forwardRef(
  (
    {
      className,
      classSideWrapper,
      videoDescription,
      videoDetail,
      distanceDecimal,
      haveSideVideo,
      isNativeApp,
      darkMode = false,
      expandedPosition,
      headerHeight = 0,
      hideSuffix = false,
      saveErrorMessage,
      handleDelete,
      addNotification,
      onPlayVideo,
      onSideViewClick,
      onFrontViewClick,
    }: IVideoRegionProps,
    ref: any,
  ) => {
    const translate = useTranslation().t;
    const [isVideoSide, setIsVideoSide] = useState<boolean>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showExpandedBtn, setShowExpandedBtn] = useState(false);
    const [heightExpansion, setHeightExpansion] = useState('100vh');
    const videoRef = useRef<any>();
    const VIDEO_DETAIL_ACTIONS = [
      ...(isNativeApp
        ? [
            {
              title: translate('pages.record.type.GDR.swing_video.action_video.SWING_ANALYSIS'),
              value: EnumVideoAction.SWING_ANALYSIS,
              className: 'text-primary-75',
            },
          ]
        : []),
      {
        title: translate('pages.record.type.GDR.swing_video.action_video.SAVE'),
        value: EnumVideoAction.SAVE,
        className: darkMode ? 'text-gz-white' : '',
      },
      {
        title: translate('pages.record.type.GDR.swing_video.action_video.DELETE'),
        value: EnumVideoAction.DELETE,
        className: 'text-gz-danger',
      },
    ];

    useEffect(() => {
      if (expandedPosition == null) return;
      window.addEventListener('scroll', handleScroll, { passive: true });
      if (!videoRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
        if (videoRef.current) {
          setHeightExpansion(`calc(100vh - ${videoRef.current.offsetHeight + headerHeight}px)`);
        }
      });
      resizeObserver.observe(videoRef.current); // Watch offsetHeight changed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }, []);

    useEffect(() => {
      if (haveSideVideo && !videoDetail?.nasmoFile) {
        setIsVideoSide(true);
      }
    }, [videoDetail]);

    useImperativeHandle(ref, () => ({
      downloadVideo() {
        downloadVideo();
      },
      analysisSwing() {
        handleAction(EnumVideoAction.SWING_ANALYSIS);
      },
    }));

    const handleScroll = () => {
      if (expandedPosition == null) return;
      setShowExpandedBtn(window.scrollY > expandedPosition);
    };

    const downloadVideo = async () => {
      try {
        const videoPath =
          isVideoSide && videoDetail?.nasmoSideFile ? videoDetail?.nasmoSideFile : videoDetail?.nasmoFile;
        if (isNativeApp) {
          sendDataToApp({ action: mobileAction.DOWNLOAD_VIDEO, info: videoPath });
        } else {
          await axios
            .get(videoPath, {
              responseType: 'blob',
            })
            .then((res) => {
              FileSaver.saveAs(res.data, 'video-nasmo.mp4');
            });
          addNotification(translate('mess.SAVE_VIDEO_SUCCESS'), NotificationType.SUCCESS);
        }
      } catch (error: any) {
        console.warn(error);
        addNotification(saveErrorMessage ?? translate('mess.SAVE_VIDEO_ERROR'), NotificationType.DANGER);
      }
    };

    const handleAction = async (action: string) => {
      switch (action) {
        case EnumVideoAction.SWING_ANALYSIS: {
          try {
            const typeSwing = !videoDetail.pgCode ? EnumFeedType.GDR : EnumFeedType.NASMO; // routerState?.pathname?.includes('gdr')
            const videoId = isVideoSide ? videoDetail?.sideVideoId : videoDetail?.videoId;

            const response = await storeFeed.checkNasmoInfo({
              detailNo: videoDetail.pgCode ?? 0,
              nasmoNo: typeSwing === EnumFeedType.GDR ? Number(videoId!) : videoDetail?.nasmoId!,
              type: typeSwing,
            });
            sendDataToApp({
              action: mobileAction.EDIT_VIDEO,
              info: {
                nasmoFile: isVideoSide ? videoDetail?.nasmoSideFile : videoDetail?.nasmoFile,
                videoId: typeSwing === EnumFeedType.GDR ? videoId : undefined,
                nasmoId: videoDetail?.nasmoId,
                feedType: typeSwing,
                feedNo: response?.entities?.feedNo,
                isVideoSideView: isVideoSide,
              },
            });
          } catch (error) {
            console.warn(error);
            addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
          }
          break;
        }
        case EnumVideoAction.SAVE:
          downloadVideo();
          break;
        default:
          setOpenConfirm(true);
          break;
      }
    };

    const onHandleConfirm = async () => {
      handleDelete?.call(null, isVideoSide);
      setOpenConfirm(false);
    };

    const renderDialog = () => {
      const isRegFeed = videoDetail.regFeedNo && videoDetail.regFeedNo > 0;
      return (
        <ConfirmAlertDialog
          title={translate(
            isRegFeed
              ? 'pages.record.type.GDR.swing_video.DELETE_SWING_AND_FEED_DESCRRIPTION'
              : 'pages.record.type.GDR.swing_video.DELETE_SWING_DESCRRIPTION',
          )}
          textOK={translate('pages.record.type.GDR.swing_video.action_video.DELETE')}
          textCancel={translate('pages.record.type.GDR.swing_video.action_video.CANCEL')}
          classTitle="p-[22px_53px]"
          classCancel="text-primary-75"
          classOK="text-gz-danger"
          isOpen={openConfirm}
          onConfirm={onHandleConfirm}
          onClose={() => setOpenConfirm(false)}
          reverseAction
        />
      );
    };

    const renderActionSide = () => {
      if (!haveSideVideo) return <></>;
      return (
        <div className={twMerge('gz-text-xs grid w-full grid-cols-2 pt-[16px]', classSideWrapper)}>
          {videoDetail?.nasmoFile && (
            <button
              className={twMerge(
                'w-full rounded-[4px] py-[10px] focus:outline-none',
                !isVideoSide ? 'bg-gz-white font-medium text-gz-primary' : 'bg-gray-10 text-gray-35',
              )}
              onClick={() => {
                onFrontViewClick?.call(null);
                setIsVideoSide(false);
              }}
            >
              {translate('pages.record.type.GDR.swing_video.FRONT_VIEW')}
            </button>
          )}
          {videoDetail?.nasmoSideFile && (
            <button
              className={twMerge(
                'w-full rounded-[4px] py-[10px] focus:outline-none',
                isVideoSide ? 'bg-gz-white font-medium text-gz-primary' : 'bg-gray-10 text-gray-35',
              )}
              onClick={() => {
                onSideViewClick?.call(null);
                setIsVideoSide(true);
              }}
            >
              {translate('pages.record.type.GDR.swing_video.SIDE_VIEW')}
            </button>
          )}
        </div>
      );
    };

    return (
      <>
        <div className={className}>
          <div className="relative" ref={videoRef}>
            <VideoCustom
              videoPath={!isVideoSide ? videoDetail?.nasmoFile : videoDetail?.nasmoSideFile}
              thumbnail={!isVideoSide ? videoDetail?.nasmoImgFile : videoDetail?.nasmoSideImgFile}
              repeatPlay
              prefix={
                <>
                  <div className="align-middle text-gz-white">
                    <span className="gz-text-md mr-[4px] font-bold">{videoDetail?.clubName}</span>
                    <span className="gz-text-sm mr-[4px]">{`${fixDecimal(
                      videoDetail?.distance,
                      false,
                      distanceDecimal,
                    )} ${videoDetail?.distUnit}`}</span>
                    <span className="ml-[4px] inline-block">
                      <BallDirection
                        gdrBallPathCode={videoDetail.gdrBallPath}
                        tmBallPathCode={videoDetail.tmBallPath}
                        classIcon="h-[14px] w-auto"
                        hideText
                      />
                    </span>
                  </div>
                  {videoDetail?.holeNo != null && (
                    <div className="gz-text-xsm text-gz-white">
                      {translate('nasmo.HOLE', { number: videoDetail.holeNo })}
                    </div>
                  )}
                </>
              }
              suffix={
                !hideSuffix ? (
                  <>
                    <DropdownMenu
                      highlight={false}
                      items={VIDEO_DETAIL_ACTIONS}
                      onChange={handleAction}
                      classItem="gz-text-xs"
                      classMenuItems={twMerge(darkMode ? 'bg-gz-black border-gz-black' : '')}
                    >
                      <IconEllipsisVertical className="rounded-full fill-gz-white stroke-gz-white active:bg-disable" />
                    </DropdownMenu>
                  </>
                ) : (
                  <></>
                )
              }
              onPlayVideo={onPlayVideo}
            />
            <div
              className={twMerge(
                'absolute top-[100%] z-10 w-full',
                'overflow-hidden bg-gz-white transition-[height]',
                !expanded && 'shadow-bottom',
              )}
              style={{ height: !showExpandedBtn ? 0 : expanded ? heightExpansion : 40 }}
            >
              <div
                className={twMerge(
                  'flex h-[40px] cursor-pointer items-center justify-center',
                  expanded && 'border-b-[1px]',
                )}
                onClick={() => setExpanded(!expanded)}
              >
                <div className="gz-text-xs mr-[8px]">
                  {translate('pages.record.type.GDR.swing_video.VIEW_SHOT_ANALYSIS')}
                </div>
                <IconChervonUp
                  className={twMerge('h-[16px] w-[16px] flex-shrink-0 transition-all', !expanded && 'rotate-180')}
                />
              </div>
              {expanded && (
                <>
                  {renderActionSide()}
                  {videoDescription}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="video-description">
          {renderActionSide()}
          {videoDescription}
        </div>
        {renderDialog()}
      </>
    );
  },
);
const mapStateToProps = (state: any) => ({
  isNativeApp: state.webviewReducer.isNativeApp,
});
const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(memo(VideoRegion));
